import { TooltipProps, Typography } from 'antd';
import { useMedia } from 'react-use';

import Text from 'react-texty';
import './SmartTooltip.css';
import { useId } from 'react';

// const Text = ReactTexty

export const SmartTooltip: React.FC<
    React.PropsWithChildren<{
        style?: React.CSSProperties;
        lineClamp?: number;
        simpleMode?: boolean;
        // trigger?: TooltipProps['trigger'];
        title?: TooltipProps['title'];
    }>
> = ({ children, style, title, lineClamp, simpleMode = true }) => {
    // const id = useId(); // Unique id generated

    // Check if children is a string
    const isString = typeof children === 'string';

    const isTouchScreen = useMedia('(hover: none)');

    if (isTouchScreen) return children;

    if (simpleMode) {
        return (
            <span
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    ...style
                }}
                title={isString ? children : undefined}
            >
                {children}
            </span>
        );
    }

    // (
    // <Text
    //     // key={id}
    //     style={{
    //         whiteSpace: 'nowrap',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //         // backgroundColor: 'black',
    //         ...style
    //     }}
    //     tooltipStyle={{
    //         backgroundColor: '#1E2A38', //'rgba(0, 0, 0)', // Semi-transparent black
    //         color: 'white',
    //         padding: '0.5rem', // Slightly more padding
    //         maxWidth: '500px',
    //         borderRadius: '8px', // Rounded corners
    //         fontSize: '0.875rem',
    //         overflow: 'hidden'
    //         // transition: 'opacity 0.3s ease, transform 0.3s ease' // Smooth transitions
    //     }}
    //     showDelay={800}
    //     hideDelay={300}
    // >
    // {
    //     children;
    // }
    // </Text>
    // );

    const Wrapper = lineClamp ? Typography.Paragraph : Typography.Text;

    return (
        <Wrapper
            title={''}
            // style={{ margin: 0, backgroundColor: 'black', padding: 0, ...style }}
            style={{ margin: 0, padding: 0, ...style }}
            ellipsis={{
                rows: lineClamp,
                tooltip: {
                    title: title ?? children,
                    overlayStyle: { maxWidth: '500px' },
                    destroyTooltipOnHide: true,
                    // getPopupContainer: () => document.querySelector('.ant-table'),
                    trigger: 'hover',
                    mouseEnterDelay: 0.5
                }
                // tooltip: !isTouchScreen
                //     ? {
                //           title: title ?? children,
                //           overlayStyle: { maxWidth: '500px' },
                //           destroyTooltipOnHide: true,
                //           getPopupContainer: () => document.querySelector('.ant-table'),
                //           trigger: 'hover',
                //           mouseEnterDelay: 0.5
                //       }
                //     : false
            }}
        >
            {children}
        </Wrapper>
    );
};
