import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce, useMap, useUpdateEffect } from 'react-use';
import { IObjectWithId } from 'utils/store/MetaStore';
import { isDefined } from 'is-lite/exports';
import { ISettingsModel } from 'smart/settings';

interface DataType {
    Id: string;
    [key: string]: any;
}

interface UseDataProps {
    id: string;
    mode: 'edit' | 'view';
    data?: DataType;
    markAllFieldsAlreadyChanged?: boolean;
    parentFieldName?: string;
    executeUIHandler: Function;
    setModel: React.Dispatch<React.SetStateAction<ISettingsModel<any>>>;
}

export const useData = ({
    data,
    id,
    mode,
    parentFieldName,
    markAllFieldsAlreadyChanged = false,
    executeUIHandler,
    setModel
}: UseDataProps) => {
    const initialChangesValue = {
        Id: id,
        CreatedAt: data?.CreatedAt,
        ...(parentFieldName ? { [parentFieldName]: data?.[parentFieldName] } : undefined)
    };

    const [dataSource, setDataSource] = useState<IObjectWithId>(initialChangesValue);
    const [changes, changesMethods] = useMap<IObjectWithId>(initialChangesValue);
    const CreatedAt = dataSource.CreatedAt;

    useUpdateEffect(() => {
        changesMethods.reset();
    }, [mode]);

    useEffect(() => {
        if (!changesMethods.get('CreatedAt')) {
            changesMethods.set('CreatedAt', CreatedAt);
        }
    }, [CreatedAt]);

    useEffectOnce(() => {
        if (data) setDataSource((prevData) => ({ ...prevData, ...data }));

        if (markAllFieldsAlreadyChanged && data) {
            changesMethods.setAll({ ...initialChangesValue, ...data });
        }
    });

    // useEffect(() => {
    //     if (data) setDataSource(data);
    // }, [data]);

    // ### Функция для изменения ДАННЫХ ПО КЛЮЧУ ('Id' или 'TO_Status.Status')
    const handleDataSourceChange = useCallback(
        (key: string, value: any) => {
            setDataSource((prevData: any) => {
                return { ...prevData, [key]: value };
            });
            changesMethods.set(key, isDefined(value) ? value : null);
            executeUIHandler({
                methodName: 'onChange',
                fieldName: key,
                extraArguments: [value]
            });
        },
        [executeUIHandler]
    );

    // console.log('Changes!', changes);

    return { dataSource, setData: setDataSource, changeData: handleDataSourceChange, changes };
};
