import { MergeDeep } from '@gilbarbara/types';
import { Collapse, Divider, Flex } from 'antd';
import { Collapse as MobileCollapse } from 'antd-mobile';
import { observer } from 'mobx-react-lite';
import { isArray } from 'is-lite/exports';
import { ReactNode, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { InfoEditor } from 'smart/components';
import { fieldEditRender, fieldRender, getExcludeFieldsPattern } from 'smart/utils';
import { useBaseLanguage } from 'smart/utils/hooks';

import { SmartDetailPageChildTabContent } from '../SmartDetailPageChildTabContent/SmartDetailPageChildTabContent';
import { FieldsBox } from '../../ui';

interface SmartDetailPageMainTabContentProps {
    metaFields: MetaField[];
    data: any;
    rootData?: any;
    setData: (updater: any | ((prevValue: any) => any)) => void;
    onChange: (key: string, newValue: any) => void;
    mode: 'view' | 'edit';
    childNodeMeta: Meta[];
    exclude?: string[]; // FieldName[]
    rootMeta?: string;
    isReload?: boolean;
}

interface FieldType {
    key: string;
    label: ReactNode;
    data: any;
    metaSource: MergeDeep<MetaField, Meta>;
    dataSource: SmartDetailPageMainTabContentProps['data'];
    rootDataSource: SmartDetailPageMainTabContentProps['data'];
    setDataSource: SmartDetailPageMainTabContentProps['setData'];
    area?: string;
    hidden?: boolean; // temp
    readOnly?: boolean;
    description?: string;
}

interface ICollapseItem {
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
    disableCollapse?: boolean;
    ref?: React.RefObject<HTMLDivElement>;
}

const SIZE = 'small';

const getFieldsFilterRule =
    (exclude?: string[]) => (field: FieldType, index: number, fieldDataSource: FieldType[]) =>
        exclude &&
        !exclude.includes(field.key) &&
        getExcludeFieldsPattern(field, fieldDataSource, {
            isDetail: true,
            replaceFieldNameKey: 'key'
        });

export const SmartDetailPageMainTabContent = observer<SmartDetailPageMainTabContentProps>(
    ({
        metaFields,
        data,
        rootData = data,
        setData,
        onChange,
        exclude,
        mode,
        childNodeMeta,
        rootMeta,
        isReload
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const baseLanguage = useBaseLanguage();
        const mainRef = useRef<HTMLDivElement>(null);
        const auditRef = useRef<HTMLDivElement>(null);
        const totalRef = useRef<HTMLDivElement>(null);

        const isBigMobile = useMedia('(max-width: 480px)');

        const rootMetaCode = rootMeta || childNodeMeta?.[0].RootMeta_Code;

        // ### ФИЛДЫ (ДАННЫЕ)
        const fieldDataSource = useMemo(() => {
            const fields: FieldType[] = [];

            // ### КЛАССИЧЕСКИЕ ФИЛДЫ
            for (const field of metaFields) {
                if (!field) {
                    continue;
                }

                // if (
                //     // !isArray
                //     data[field.FieldName] &&
                //     isArray(data[field.FieldName]) &&
                //     field.FieldName !== 'DaysPattern'
                // )
                //     continue;

                const displayFieldName =
                    field.Name?.[language] ?? t(field.ColumnName || field.FieldName);

                fields.push({
                    key: field.FieldName,
                    area: field?.LayoutArea,
                    label: displayFieldName,
                    // label формируем из меты, а если там пусто то i18n
                    data: data ? data[field.FieldName] : null,
                    metaSource: field,
                    dataSource: data,
                    rootDataSource: rootData,
                    setDataSource: setData,
                    // поле НЕ редактироемое если 1) есть такой флаг; 2) если оно в Main/Totals или Main/Audit.
                    readOnly:
                        field.LayoutArea === 'Main/Audit' ||
                        field.LayoutArea === 'Main/Totals' ||
                        field.IsReadOnly,
                    hidden: field.IsHiddenOnDetail, // temp,
                    description: field.Description?.[language],
                    required: field.IsMandatory
                });
            }

            // ### МАССИВНЫЕ ФИЛДЫ (чайлды, котолрые нужно рисовать в основном табе)
            for (const node of childNodeMeta) {
                if (node.ArrayNameInRoot && !node.IsDisabled) {
                    fields.push({
                        key: node.ArrayNameInRoot,
                        area: node?.LayoutArea,
                        label:
                            isBigMobile && !node.LayoutArea?.includes('/')
                                ? node.Name[language]
                                : '', // тут лейбл будем верстать свой ниже по коду
                        data: data ? data[node.ArrayNameInRoot] || [] : [],
                        metaSource: node,
                        dataSource: data,
                        rootDataSource: rootData,
                        setDataSource: setData,
                        readOnly: node.IsReadOnly,
                        hidden: node.IsHidden // temp
                        // description: node.Description?.[language]
                    });
                }
            }

            // console.log('[SmartDetailPageMainTabContent] fields data:', fields);
            return fields;
        }, [childNodeMeta, data, isBigMobile, language, metaFields, setData, t]);

        // ### ФИЛДЫ (РАЗМЕТКА)
        const fields = useMemo(
            () =>
                fieldDataSource.filter(getFieldsFilterRule(exclude)).map((field) => {
                    const metaData = field.metaSource;
                    const valueType = metaData.ValueType;
                    const options = metaData.Options;
                    const required = mode === 'edit' && metaData.IsMandatory;
                    const dataSource = field.dataSource;
                    const rootDataSource = field.rootDataSource;
                    const area = field.area;

                    const isArrayNode = isArray(field.data) && !valueType;

                    const isInfoEditor =
                        field.key === 'Info' && field.metaSource.Meta_Code === 'InfoLayouts';

                    // const isInfoEditor = false;

                    const isInlineJson = // metaSource.FieldName === 'DaysPattern' ||
                        valueType?.includes('json') &&
                        valueType?.includes('display:inline') &&
                        !valueType?.includes('json_type') &&
                        !metaData.FieldName.includes('DefaultValue') &&
                        !valueType?.includes('multilang_text');

                    const showTitle = metaData.ShowTitle ?? true;
                    const collapsible = metaData.IsCollapsible;

                    const isHomePage = area?.includes('/');

                    return {
                        key: field.key,
                        label: !isInfoEditor && showTitle ? field.label : undefined,
                        area,
                        required,
                        hidden: field.hidden,
                        vertical:
                            (isArrayNode && isBigMobile) ||
                            isInfoEditor ||
                            isInlineJson ||
                            options?.DescAbove,
                        span: isInfoEditor || isArrayNode || options?.FullWidth ? 2 : 1,
                        description: field.description,
                        collapsible: isInfoEditor ? false : collapsible,
                        auditData: field.metaSource.IsAuditEnabled
                            ? {
                                  objectId: field.rootDataSource.Id,
                                  field: field.metaSource,
                                  meta:
                                      field.metaSource.Meta_Code ??
                                      field.metaSource.Code ??
                                      rootMeta
                              }
                            : undefined,
                        areaLabel:
                            metaData.LayoutAreaName?.[language] ??
                            t((collapsible ? area?.split('/')?.at(-1) : area) as string),
                        children: isInfoEditor ? (
                            <InfoEditor
                                meta={rootData.Meta?.Code}
                                onChange={onChange}
                                value={field.data}
                                mode={mode}
                            />
                        ) : isArrayNode ? (
                            <SmartDetailPageChildTabContent
                                data={dataSource}
                                rootData={rootDataSource}
                                setData={field.setDataSource}
                                onChange={onChange}
                                childNodeMeta={metaData as Meta}
                                mode={mode}
                                padding={!isHomePage ? 5 : 0}
                                readOnly={field.readOnly}
                                tableTitle={
                                    !isBigMobile && !isHomePage
                                        ? metaData.Name[language]
                                        : undefined
                                }
                                rootMeta={rootMetaCode}
                            />
                        ) : !field.readOnly && mode === 'edit' ? (
                            <div style={{ width: '100%' }}>
                                {fieldEditRender({
                                    data: field.data,
                                    onChange,
                                    language,
                                    metaFieldData: metaFields,
                                    fieldName: field.key,
                                    dataSource,
                                    rootDataSource,
                                    rootMeta: rootMetaCode
                                })}
                            </div>
                        ) : (
                            fieldRender({
                                data: field.data,
                                language,
                                baseLanguage,
                                metaFieldData: metaFields,
                                fieldName: field.key,
                                dataSource,
                                rootDataSource,
                                rootMeta: rootMetaCode
                            })
                        )
                    };
                }),
            [
                fieldDataSource,
                exclude,
                onChange,
                mode,
                isBigMobile,
                language,
                t,
                rootMetaCode,
                metaFields,
                baseLanguage
            ]
        );

        const notHiddenFields = useMemo(() => fields.filter((field) => !field.hidden), [fields]);

        // ### РАЗМЕТКА ГРУПП ФИЛДОВ
        const collapseItems = useMemo(() => {
            const areas = new Map<string, (typeof fields)[number]>();
            fields.forEach((field) => {
                const area = field.area;
                if (area) areas.set(area, field);
            });

            const collapseItems: ICollapseItem[] = [];

            // ### Группа ОСНОВНЫЕ ДАННЫЕ

            const mainTopFields = notHiddenFields.filter((field) => field.area === 'Main/Header');

            const mainMiddleFields = notHiddenFields.filter(
                (field) => field.area === 'Main' || !areas.has(field.area || '')
            );
            const mainBottomFields = notHiddenFields.filter(
                (field) => field.area === 'Main/Footer'
            );

            if (mainTopFields.length || mainMiddleFields.length || mainBottomFields.length)
                collapseItems.push({
                    key: 'common_info',
                    label: <strong>{t('common_info')}</strong>,
                    disableCollapse: true,
                    children: (
                        <>
                            <FieldsBox items={mainTopFields} size={SIZE} isReload={isReload} />
                            {!!mainTopFields.length && (
                                <Divider style={{ margin: '5px 1px 5px 1px' }} />
                            )}
                            <FieldsBox items={mainMiddleFields} size={SIZE} isReload={isReload} />
                            {!!mainMiddleFields.length && !!mainBottomFields.length && (
                                <Divider style={{ margin: '5px 1px 5px 1px' }} />
                            )}
                            <FieldsBox items={mainBottomFields} size={SIZE} isReload={isReload} />
                            {!!mainBottomFields.length && (
                                // <Divider style={{ margin: '8px 0 0 0', borderBlockStart: '0px' }} />
                                <Divider style={{ margin: '5px 1px 5px 1px' }} />
                            )}
                        </>
                    ),
                    ref: mainRef
                });

            // ### ДРУГИЕ группы
            for (const [area, field] of areas.entries()) {
                if (
                    area !== 'Main' &&
                    area !== 'Main/Header' &&
                    area !== 'Main/Footer' &&
                    area !== 'Main/Statuses' &&
                    area !== 'Main/Audit' &&
                    area !== 'Main/Totals'
                ) {
                    const isCollapsible = field?.collapsible;
                    const label = field.areaLabel;

                    const items = notHiddenFields.filter((field) => field.area === area);

                    if (items.length) {
                        collapseItems.push({
                            key: label,
                            label: <strong>{label}</strong>,
                            children: <FieldsBox size={SIZE} items={items} isReload={isReload} />,
                            disableCollapse: !isCollapsible
                        });
                    }
                }
            }

            // ### Группа СТАТУСЫ
            const statusFields = notHiddenFields.filter((field) => field.area === 'Main/Statuses');
            if (statusFields.length) {
                collapseItems.push({
                    key: 'statuses',
                    label: <strong>{t('statuses')}</strong>,
                    children: <FieldsBox size={SIZE} items={statusFields} isReload={isReload} />
                });
            }

            // ### Группа ИТОГИ
            const totalFields = notHiddenFields.filter((field) => field.area === 'Main/Totals');
            if (totalFields.length) {
                collapseItems.push({
                    key: 'totals',
                    label: <strong>{t('totals')}</strong>,
                    children: <FieldsBox size={SIZE} items={totalFields} isReload={isReload} />,
                    ref: totalRef
                });
            }

            // ### Группа АУДИТ
            // const auditFields = fields.filter((field) => field.area === 'Main/Audit');
            const auditFields = notHiddenFields.filter((field) => field.area === 'Main/Audit');
            if (auditFields.length) {
                collapseItems.push({
                    key: 'audit',
                    label: <strong>{t('audit')}</strong>,
                    children: <FieldsBox size={SIZE} items={auditFields} isReload={isReload} />,
                    ref: auditRef
                });
            }

            return collapseItems;
        }, [fields, notHiddenFields, t, isReload]);

        // console.log(collapseItems);

        const collapsedItems = collapseItems.filter((item) => !item.disableCollapse);
        const uncollapsedItems = collapseItems.filter((item) => item.disableCollapse);

        // ### RENDER
        return (
            <Flex vertical className="smart_detail_page__main_content">
                <Flex vertical ref={mainRef} style={{ marginBottom: 5 }}>
                    {uncollapsedItems.map((item) => item.children)}
                </Flex>
                {!!collapsedItems?.length &&
                    (isBigMobile ? (
                        <MobileCollapse>
                            {collapsedItems.map((item) => {
                                return (
                                    <MobileCollapse.Panel
                                        style={{ color: 'black' }}
                                        key={item.key}
                                        title={item.label}
                                    >
                                        {item.children}
                                    </MobileCollapse.Panel>
                                );
                            })}
                        </MobileCollapse>
                    ) : (
                        <Collapse
                            className="smart_detail_page__collapse"
                            size={SIZE}
                            defaultActiveKey={['common_info']}
                            items={collapsedItems}
                        />
                    ))}
            </Flex>
        );
    }
);
