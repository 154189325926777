import { Card, List } from 'antd-mobile';
import { AutoSizer } from 'react-virtualized';
import { List as VirtualizedList } from 'react-virtualized/dist/es/List';
import React, { memo } from 'react';
import { Divider, Flex, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Loader } from '../../../../../ui/Loader/Loader';
import { IObjectWithId } from '../../../../../utils/store/MetaStore';

import './MobileView.scss';
import { useDoubleTap } from '../../../../utils/hooks';
import { EmptyMarker } from '../../../../../ui';

interface Column extends Omit<ColumnType<IObjectWithId>, 'dataIndex' | 'title'> {
    dataIndex: string;
    title?: React.ReactNode;
    getPaperClipComponent?: (docCount: number) => React.ReactNode | null;
}

interface MobileViewProps {
    loading?: boolean;
    titleRender?: () => React.ReactNode;
    footerRender?: () => React.ReactNode;
    cardToolbarRender?: (row: IObjectWithId) => React.ReactNode;
    data: IObjectWithId[];
    columns: Column[];
    getCustomRowProps: NonNullable<TableProps['onRow']>;
    height?: number;
    // selectedRows: IObjectWithId[];
}

interface RowProps {
    row: IObjectWithId;
    rowIndex: number;
    onDoubleTap?: React.MouseEventHandler<any>;
    onTap?: React.MouseEventHandler<any>;
    statusColumn?: Column;
    headerColumns?: Column[];
    bodyColumns: Column[];
    footerColumns?: Column[];
    style?: React.CSSProperties;
    key: React.Key;
    // isSelected?: boolean;
    toolbarRender?: (row: IObjectWithId) => React.ReactNode;
}

const Row = memo<RowProps>(
    ({
        row,
        rowIndex,
        onDoubleTap,
        onTap,
        statusColumn,
        headerColumns,
        bodyColumns,
        footerColumns,
        style,
        key,
        // isSelected,
        toolbarRender
    }) => {
        const statusColor = statusColumn
            ? row[statusColumn?.dataIndex]?.Color ??
              (row.VirtualFields?.[statusColumn?.dataIndex]?.Color || '#D2D7DF')
            : 'inherit';

        const doubleTapBinder = useDoubleTap(onDoubleTap ?? (() => {}));

        return (
            <List.Item
                key={key}
                // style={{ ...style, backgroundColor: '#f7f7f7' }}
                style={{ ...style, backgroundColor: 'transparent' }}
                // clickable
                onClick={onTap}
                arrowIcon={false}
                className={`mobile_view__item`}
            >
                <Card
                    // className={`mobile_view__card ${isSelected ? 'mobile_view__card_selected' : ''}`}
                    className={`mobile_view__card`}
                    style={{
                        '--status_color': statusColor
                    }}
                    headerClassName={'mobile_view__card_header'}
                    onClick={doubleTapBinder?.onClick}
                    title={
                        headerColumns?.length ? (
                            <Flex className={'mobile_view__card_title'} justify={'space-between'}>
                                {headerColumns.map((column, columnIndex) => {
                                    const value = row[column.dataIndex];
                                    const render = column.render ?? (() => value?.toString());

                                    if (columnIndex === 0) {
                                        if (row.TotalDocumentsCount) {
                                            return (
                                                <strong
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {
                                                        render(
                                                            value,
                                                            row,
                                                            rowIndex
                                                        ) as React.ReactNode
                                                    }
                                                    {column.getPaperClipComponent?.(
                                                        row.TotalDocumentsCount
                                                    )}
                                                </strong>
                                            );
                                        }
                                        return (
                                            <strong>
                                                {render(value, row, rowIndex) as React.ReactNode}
                                            </strong>
                                        );
                                    }

                                    return (
                                        <span
                                            style={{
                                                fontWeight: 'normal',
                                                maxWidth: '70%'
                                            }}
                                        >
                                            {render(value, row, rowIndex)}
                                        </span>
                                    );
                                })}
                            </Flex>
                        ) : undefined
                    }
                >
                    <Flex vertical>
                        {bodyColumns.map((column) => {
                            const title = column.title;
                            const value = row[column.dataIndex];
                            const render = column.render ?? (() => value?.toString());

                            return (
                                <Flex gap={5}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            color: 'gray',
                                            width: 'calc(40% - 5px)'
                                        }}
                                    >
                                        {title}:
                                    </span>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '60%'
                                        }}
                                    >
                                        {render(value, row, rowIndex)}
                                    </span>
                                </Flex>
                            );
                        })}

                        {toolbarRender && (
                            <>
                                <Divider style={{ margin: '5px' }} />

                                {toolbarRender(row)}
                            </>
                        )}
                    </Flex>
                </Card>
            </List.Item>
        );
    }
);

export const MobileView = memo<MobileViewProps>(
    ({
        loading,
        titleRender,
        footerRender,
        data,
        columns,
        cardToolbarRender,
        getCustomRowProps,
        height = 480
    }) => {
        const statusColumn = columns.find((c) => c.dataIndex?.endsWith('Status'));
        const headerColumns = columns.filter((c) => c?.layoutArea === 'Page/Header');
        const bodyColumns = columns.filter(
            (c) =>
                // (c.layoutArea === 'Main' || c.layoutArea === 'Main/Header') &&
                c.layoutArea !== 'Page/Header' &&
                c.layoutArea !== 'Main/Footer' &&
                !c.dataIndex.endsWith('Status')
        );

        const rowHeight =
            (headerColumns.length ? 47 : 0) +
            bodyColumns.length * 25 +
            24 +
            12 +
            (cardToolbarRender ? 43 : 0);

        const bodyHeight = data?.length * rowHeight;

        // console.log(statusColumn);

        return (
            <Loader status={!!loading}>
                {!columns.length ? (
                    <EmptyMarker
                        noImage
                        // style={{ borderRadius: '6px', border: 'none' }}
                        size={'small'}
                        titleCentered
                        title={'set_mobile_columns'}
                    />
                ) : data?.length ? (
                    <List className="mobile_view" header={titleRender ? titleRender() : undefined}>
                        <AutoSizer disableHeight>
                            {({ width }: { width: number }) => (
                                <VirtualizedList
                                    rowCount={data.length}
                                    rowRenderer={({
                                        index,
                                        key,
                                        style
                                    }: {
                                        index: number;
                                        key: string;
                                        style: React.CSSProperties;
                                    }) => {
                                        const item = data[index];

                                        return (
                                            <Row
                                                key={key}
                                                row={item}
                                                rowIndex={index}
                                                headerColumns={headerColumns}
                                                bodyColumns={bodyColumns}
                                                statusColumn={statusColumn}
                                                // onTap={getCustomRowProps(item, index).onClick}
                                                onDoubleTap={
                                                    getCustomRowProps(item, index).onDoubleClick
                                                }
                                                style={style}
                                                // isSelected={selectedRowKeys.includes(item.Id)}
                                                toolbarRender={cardToolbarRender}
                                            />
                                        );
                                    }}
                                    width={width}
                                    height={bodyHeight > height ? height : bodyHeight}
                                    rowHeight={rowHeight}
                                    overscanRowCount={10}
                                />
                            )}
                        </AutoSizer>
                    </List>
                ) : (
                    <EmptyMarker titleCentered noImage size={'small'} />
                )}
                {/* {footerRender && <div className={'mobile_view__footer'}>{footerRender()}</div>} */}
            </Loader>
        );
    }
);
