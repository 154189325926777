import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useVersionChecker } from 'smart/utils/hooks';
import { useNotifications } from 'utils/hooks';

export const VersionControlProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { modal } = useNotifications();
    const { t } = useTranslation();
    const { isNewVersion, setIsNewVersion, reset: resetChecker } = useVersionChecker();

    useEffect(() => {
        if (isNewVersion) {
            modal.confirm?.({
                title: t('new_version_title'),
                content: t('new_version_description'),
                centered: true,
                onOk: () => {
                    window.location.reload();
                },
                // onCancel: resetChecker,
                onCancel: () => setIsNewVersion(false),
                // onClose: resetChecker,
                onClose: () => setIsNewVersion(false)
                // afterClose: () => setIsNewVersion(false)
            });

            // const reload = confirm('Доступна новая версия приложения. Обновить страницу?');
            // if (reload) {
            //     window.location.reload();
            // } else {
            //     resetChecker();
            // }
            // setIsNewVersion(false);
        }
    }, [isNewVersion]);

    return <>{children}</>;
};
