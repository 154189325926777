import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, DescriptionsProps, Input, InputNumber, Modal, Select } from 'antd';
import { isDefined, isEmpty, isNumericString, isObject, isPropertyOf } from 'is-lite/exports';
import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce, useMedia, useMountedState, useUpdateEffect } from 'react-use';

import {
    Action,
    HandlerParam,
    HandlerRun,
    MetaField
} from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';
import { camelize } from 'utils';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { Response } from 'modules/services/backend-api/generated_api';
import { ANY_DATA } from 'modules/services/backend-api/generated_types';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { IHandlerWithId } from 'smart/modules/SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';
import { getExcludeFieldsPattern, JSONSafeParse, parseValueType } from 'smart/utils';
import { useHandlerRun } from 'smart/utils/hooks';
import { toPascalCase } from 'utils/helpers/toPascalCase';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import memoizeOne from 'memoize-one';
import { emitter } from 'utils/emitter';
import {
    ArraySortingField,
    ColorField,
    FilePickerField,
    JsonField,
    PasswordField,
    SlotsPicker,
    SmartDateRangeField,
    SmartMultilanguageField,
    SmartTimeField
} from '..';
import { SmartDateField } from '../SmartDateField/SmartDateField';
import { SmartDatetimeRangeField } from '../SmartDatetimeRangeField/SmartDatetimeRangeField';
import { SmartDurationField } from '../SmartDurationField/SmartDurationField';
import { SmartMultiSelectField } from '../SmartMultiSelectField/SmartMultiSelectField';
import { SmartSelectField } from '../SmartSelectField/SmartSelectField';
import { useChooseFieldsModal } from './ui/useChooseFieldsModal';
import { FieldsBox } from '../../modules/SmartDetailPage/ui';

dayjs.extend(timezone);

export interface HandlerRunModalProps {
    open: boolean;
    onCancel: () => void;
    action: Action | null;
    ids: string[];
    metaName: string;
    onRefresh: () => void;
    onResponseCallback?: (response: (HandlerRun | undefined)[]) => void;
    setSelectedRows?: (rows: any[]) => void;
    // filters?: FilterField[] | Filter[]; // filters for navigate if exists
    filters?: FilterField[]; // filters for navigate if exists
    // navigate function if need after run
    navigateAfterRun?: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    row?: any;
    meta?: string;
    disableNotify?: boolean;

    localMode?: boolean;
}

const getParamCode = (param: HandlerParam) => {
    return param?.ParamName || camelize(param.Name?.en ?? '');
};

const DATE_FIELD_WIDTH = 170;
const NUMBER_FIELD_WIDTH = 170;
const DATE_RANGE_FIELD_WIDTH = 400;

const mergeArgs = memoizeOne((defaultArgs, args, params) => {
    // const comparedArgs = {} as ANY_DATA;
    const comparedArgs = args as ANY_DATA;

    for (const param of params) {
        const key = getParamCode(param);

        if (isPropertyOf(args, key)) {
            comparedArgs[key] = !isEqual(args[key], defaultArgs[key])
                ? args[key]
                : defaultArgs[key];
        } else {
            comparedArgs[key] = defaultArgs[key];
        }
    }

    return comparedArgs;
}, isEqual);

export const HandlerRunModal = observer<HandlerRunModalProps>(
    ({
        open,
        onCancel,
        action,
        ids,
        metaName,
        onResponseCallback,
        setSelectedRows,
        onRefresh,
        filters,
        navigateAfterRun,
        row,
        meta,
        disableNotify = false,
        localMode = false
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        const { current: NOW } = useRef(dayjs().format());

        const data = action?.Handler as IHandlerWithId;
        const handlerCode = data?.Code;
        // const handlerMethod = data?.MethodName;
        const handlerChildParams = data?.ChildParams;
        const handlerName = data?.Name?.[language];
        const actionName = action?.Name?.[language];

        // const isMassUpdate = handlerCode === 'MassUpdate' || handlerMethod === 'MassUpdate';
        const isMassUpdate = handlerCode === 'MassUpdate';
        // const isMassChildUpsert =
        //     handlerCode === 'MassChildUpsert' || handlerMethod === 'MassChildUpsert';

        const isMiddleTablet = useMedia('(max-width: 720px)');
        const isBigMobile = useMedia('(max-width: 480px)');

        const { notification } = useNotifications();
        const { run } = useHandlerRun();

        const modalTitle = actionName ?? handlerName ?? t(handlerCode || 'handler');

        const [confirmLoading, setConfirmLoading] = useState(false);

        const [childMeta, setChildMeta] = useState<string | null>(null);
        const [choosenFields, setChoosenFields] = useState<HandlerParam[]>([]);
        // console.log('CHOOSEN FIELDS', choosenFields);
        const { chooseFieldsModal, openModal } = useChooseFieldsModal({
            meta: childMeta ?? metaName,
            childParams: handlerChildParams,
            setData: setChoosenFields,
            selectedIds: ids,
            handlerCode
        });
        const params = useMemo(() => {
            // if (isMassUpdate) return choosenFields;
            if (isMassUpdate) return [...handlerChildParams, ...choosenFields];

            return handlerChildParams ?? [];
        }, [isMassUpdate, choosenFields, handlerChildParams]);

        // console.log(params);

        const [handledArgs, setArgs] = useState<ANY_DATA>({});
        const [defaultArgs, setDefaultArgs] = useState<ANY_DATA>({});
        const args = useMemo(
            () => mergeArgs(defaultArgs, handledArgs, params),
            [defaultArgs, handledArgs, params]
        );
        // const setArgs = useCallback<React.Dispatch<React.SetStateAction<ANY_DATA>>>((updater) => {
        //     setHandledArgs(updater);
        // }, []);

        // console.log(args);

        useEffect(() => {
            setChoosenFields([]);
        }, [ids]);

        useEffect(() => {
            if (args.child_node_id) {
                setChildMeta(args.child_node_id.Code);
            } else if (args.child_node_code) {
                setChildMeta(args.child_node_code);
            } else {
                setChildMeta(null);
            }
        }, [args.child_node_code, args.child_node_id]);

        useEffect(() => {
            if (data.ChildParams.find((param) => param.ParamName === 'Records')) {
                const Record = {} as ANY_DATA;

                for (const choosenField of choosenFields) {
                    const paramName = choosenField.ParamName;

                    Record[paramName] = handledArgs[paramName];
                }

                setDefaultArgs((prev) => ({ ...prev, Records: isEmpty(Record) ? null : [Record] }));
            }
        }, [handledArgs, choosenFields, data.ChildParams]);

        useUpdateEffect(() => {
            const args = {} as ANY_DATA;
            for (const choosenField of choosenFields) {
                if (choosenField.ValueType?.replace('*', '').startsWith('bool')) {
                    args[choosenField.ParamName] = false;
                } else {
                    args[choosenField.ParamName] = null;
                }
            }

            setArgs((prev) => ({ ...prev, ...args }));
        }, [choosenFields]);

        // console.log(args);
        // console.log(params);

        const metaInfo = useMemo(() => {
            if (meta) return toJS(metaStore.meta.get(meta)?.info);
            return undefined;
        }, [meta]);

        const isMounted = useMountedState();

        const getDefaultArgsFromParams = (params: HandlerParam[]): ANY_DATA => {
            const defaultArgsData: ANY_DATA = {};
            const parseDefaultErrorMsg = t('parse_default_value_fail');

            for (const param of params ?? []) {
                const defaultValue = param.DefaultValue;
                const valueType = param.ValueType;

                const paramCode = getParamCode(param);

                if (defaultValue) {
                    // для реф объектов
                    if (valueType.includes('ref:')) {
                        try {
                            defaultArgsData[paramCode] = isObject(defaultValue)
                                ? defaultValue
                                : JSON.parse(defaultValue);
                        } catch (error) {
                            notification.error({
                                description: param.ParamName,
                                message: parseDefaultErrorMsg
                            });
                        }
                    } else if (
                        valueType.includes('filters:Date.now()') &&
                        valueType.includes('datetime')
                    ) {
                        defaultArgsData[paramCode] = NOW;
                    } else {
                        // для остальных
                        defaultArgsData[paramCode] = JSONSafeParse(defaultValue);
                    }
                }
            }

            return defaultArgsData;
        };

        useEffectOnce(() => {
            const defaultArgsData = getDefaultArgsFromParams(params);
            // setArgs({ ...args, ...defaultArgsData });
            setDefaultArgs((prev) => ({
                ...prev,
                ...defaultArgsData
            }));
        });

        useEffect(() => {
            const defaultArgsData = getDefaultArgsFromParams(params);
            // const allArgs = { ...defaultArgsData, ...handledArgs };

            // console.log('args:', args);
            for (let i = 0; i < params?.length; i++) {
                const param = params[i];
                const paramCode = getParamCode(param);

                const { type: fieldType, options } = parseValueType(
                    { valueType: param.ValueType },
                    language,
                    {
                        root: { ...row, ids },
                        current: { ...args, ids },
                        info: metaInfo,
                        self: args[paramCode]
                    }
                );

                if (options?.value) {
                    if (isNumericString(options?.value)) {
                        if (fieldType.includes('int'))
                            defaultArgsData[paramCode] = parseInt(options?.value, 10);
                        else defaultArgsData[paramCode] = parseFloat(options?.value);
                    } else if (options?.value === 'undefined') {
                        defaultArgsData[paramCode] = undefined;
                    } else {
                        defaultArgsData[paramCode] = JSONSafeParse(options?.value);
                    }
                }

                // console.log(handledArgs[paramCode], defaultArgsData[paramCode]);
            }

            // console.log(defaultArgs, defaultArgsData);

            if (!isEqual(defaultArgs, defaultArgsData)) {
                // setDefaultArgs((prev) => ({ ...prev, ...defaultArgsData }));

                for (const key of Object.keys(args)) {
                    if (handledArgs[key] !== undefined) {
                        defaultArgsData[key] = undefined;
                    }
                }

                setDefaultArgs((prev) => ({ ...prev, ...defaultArgsData }));
                // setDefaultArgs((prev) => {
                //     const replacedArgs = { ...prev };

                //     for (const key of Object.keys(args)) {
                //         if (replacedArgs[key] !== undefined) {
                //             // replacedArgs[key] = undefined;
                //             defaultArgsData[key] = undefined;
                //         }
                //     }

                //     return replacedArgs;
                // });

                // переопределяем args чтобы они занулились и значения из options.value применились
                setArgs((prev) => {
                    const replacedArgs = { ...prev };

                    for (const key of Object.keys(args)) {
                        if (defaultArgsData[key] !== undefined) {
                            replacedArgs[key] = undefined;
                        }
                    }

                    return replacedArgs;
                });
            }
        }, [args, ids, isMounted, language, metaInfo, params, row]);

        const handleRun = useCallback(async () => {
            const withEmptyArgs = { ...args };

            if (localMode) {
                if (onResponseCallback) {
                    // @ts-ignore
                    onResponseCallback(withEmptyArgs);
                }
                return;
            }

            // только для массового редактирования
            if (choosenFields.length > 0) {
                choosenFields.forEach((field) => {
                    if (!withEmptyArgs[field.ParamName]) {
                        withEmptyArgs[field.ParamName] = null;
                    }
                });
            }

            setConfirmLoading(true);
            if (data) {
                const response = await run(
                    {
                        Action_Id: data.Id,
                        meta: metaName,
                        ids: ids.map((id) => id.toString()),
                        handler: data.Code,
                        args: withEmptyArgs
                    },
                    disableNotify || action?.IsLogResultHidden
                );

                if (response?.run && onResponseCallback) {
                    onResponseCallback(response.run);
                }

                setConfirmLoading(false);

                // После выполнения экшна надо сделать обязательно рефреш данных!!
                onRefresh();
                if (action?.Handler_Code === 'CatSchedules.GenerateScheduleItems') {
                    emitter.emit('graphic_tab_refresh');
                }

                if (navigateAfterRun && response && action && action.NavItem) {
                    navigateAfterRun(response, action, filters || []);
                }
                if (setSelectedRows) setSelectedRows([]);
            }

            setChoosenFields([]);
            onCancel();
        }, [
            args,
            choosenFields,
            data,
            run,
            metaName,
            ids,
            onResponseCallback,
            onRefresh,
            navigateAfterRun,
            action,
            filters,
            setSelectedRows,
            disableNotify,
            onCancel,
            localMode
        ]);

        const fields = useMemo(() => {
            const fields: DescriptionsProps['items'] = [];

            // console.log(params);

            const filteredSortedParams = params
                ?.filter((param, index, params) =>
                    getExcludeFieldsPattern(param, params, {
                        isHandlerModal: true,
                        replaceFieldNameKey: 'ParamName',
                        isSnakeCaseValue: true,
                        diableCodeSuffix: true
                    })
                )
                .sort((a, b) => (a.ChildIndex ?? 0) - (b.ChildIndex ?? 0));

            const getHandlerWithValue = (paramCode: string) => (value: any) => {
                const settableValue = value === undefined ? null : value;

                setArgs((prev) => ({
                    ...prev,
                    [paramCode]: settableValue
                }));
            };

            const getHandlerWithEvent = (paramCode: string) => (event: any) => {
                const value = event.target.value;
                const settableValue = value === undefined ? null : value;

                setArgs((prev) => ({
                    ...prev,
                    [paramCode]: settableValue
                }));
            };

            // console.log(filteredSortedParams);

            const paramRender = (param: HandlerParam, forceStopRecursion?: boolean) => {
                // TODO: вынести в отдельную рендер функцию
                const paramCode = getParamCode(param);
                const value = args[paramCode];

                const changeByValue = getHandlerWithValue(paramCode);
                const changeByEvent = getHandlerWithEvent(paramCode);

                const { type: fieldType, options } = parseValueType(
                    { valueType: param.ValueType },
                    language,
                    {
                        root: { ...row, ids },
                        current: { ...args, ids },
                        info: metaInfo,
                        self: value
                    }
                );

                // console.log(param, options, args);

                const label = param.Name ? param.Name[language] : paramCode;

                switch (fieldType) {
                    case '[]object_id':
                    case '[]code':
                    case '[]id': {
                        let metaParam = metaName;

                        if (options && options.ref) {
                            metaParam = options.ref.meta;
                        }

                        // if (metaParam.endsWith('AvailableSlots')) {
                        //     fields.push({
                        //         key: paramCode,
                        //         vertical: true,
                        //         label,
                        //         children: (
                        //             <SlotsPicker
                        //                 value={value}
                        //                 meta={metaParam}
                        //                 onChange={changeByValue}
                        //                 filters={options?.filters}
                        //             />
                        //         )
                        //     });

                        //     break;
                        // }

                        if (options && options.is_reorder) {
                            fields.push({
                                key: paramCode,
                                vertical: true,
                                label,
                                children: (
                                    <ArraySortingField
                                        meta={metaParam}
                                        filters={options.filters}
                                        value={value}
                                        onChange={changeByValue}
                                    />
                                )
                            });

                            break;
                        }

                        if (options && options.is_editable_table && options.ref?.meta) {
                            fields.push({
                                key: paramCode,
                                vertical: true,
                                label,
                                children: (
                                    <ArraySortingField
                                        meta={metaParam}
                                        filters={options.filters}
                                        value={value}
                                        onChange={changeByValue}
                                        editable
                                        isChangeChildIndex
                                    />
                                )
                            });

                            break;
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartMultiSelectField
                                    value={value}
                                    metaName={metaParam}
                                    onChange={changeByValue}
                                    filters={options?.filters}
                                    treeOptions={{
                                        groupKeys: options?.group ? [options.group] : undefined,
                                        parentField: 'Parent'
                                    }}
                                />
                            )
                        });

                        break;
                    }
                    case 'jsonb': {
                        const data =
                            !value && typeof value === 'object' ? JSON.stringify(value) : value;

                        if (options?.json_type) {
                            paramRender({
                                ...param,
                                ValueType: `${options.json_type};ref:${options.ref?.meta || ''}.${
                                    options.ref?.fieldName || ''
                                }${options.filters ? `;filters:${options.filters}` : ''}${
                                    options.group ? `;group:${options.group}` : ''
                                }${options.display ? `;display:${options.display}` : ''}${
                                    options.is_reorder ? ';is_reorder' : ''
                                }${options.is_editable_table ? ';is_editable_table' : ''}`
                            });

                            return;
                        }

                        if (paramCode?.includes('value')) {
                            const valueTypeField = params.find(
                                (metaField) =>
                                    // metaField.ParamName.includes('value_type') ||
                                    metaField.ParamName === 'parameter_code'
                                // ||
                                // metaField.ParamName === 'property_code'
                            );

                            const typeParamName = valueTypeField?.ParamName ?? '';
                            const valueType = args[typeParamName]?.ValueType;

                            if (typeParamName && valueType && !forceStopRecursion) {
                                paramRender(
                                    {
                                        ...param,
                                        ValueType: valueType
                                    },
                                    true
                                );

                                return;
                            }
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <JsonField
                                    jsonValue={typeof data === 'string' ? JSON.parse(data) : data}
                                    readOnly={false}
                                    onChange={changeByValue}
                                    displayMode={options?.display}
                                    scriptLanguage="json"
                                />
                            )
                        });

                        break;
                    }
                    case 'password': {
                        const confirmPassParamName = 'confirm_password';

                        // if (paramCode === confirmPassParamName) continue;
                        if (paramCode === confirmPassParamName) return;

                        const confirmPassParam = params.find(
                            ({ ParamName }) => ParamName === confirmPassParamName
                        );

                        const onConfirmChange = (value: string) => {
                            const settableValue = value === undefined ? null : value;

                            setArgs((prev) => ({
                                ...prev,
                                [confirmPassParamName]: settableValue
                            }));
                        };

                        fields.push({
                            key: paramCode,
                            label: undefined,
                            children: (
                                <PasswordField
                                    confirmValue={
                                        confirmPassParam ? args[confirmPassParamName] : undefined
                                    }
                                    onConfirmChange={confirmPassParam ? onConfirmChange : undefined}
                                    value={value}
                                    onChange={changeByValue}
                                    labels={{
                                        newPassword: label,
                                        confirmPassword: confirmPassParam
                                            ? confirmPassParam?.Name?.[language] ||
                                              getParamCode(confirmPassParam)
                                            : undefined
                                    }}
                                />
                            )
                        });

                        break;
                    }
                    case 'script': {
                        const data = String(value);

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <JsonField
                                    jsonValue={data}
                                    displayMode={options?.display}
                                    onChange={changeByValue}
                                    scriptLanguage={options?.language || 'text'}
                                    readOnly={false}
                                />
                            )
                        });

                        break;
                    }
                    case 'multilang_text':
                    case 'jsonb_multilang_text': {
                        const fieldName =
                            param.Name[language || 'en'] ||
                            t((param.ParamName || '')?.toLowerCase());

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartMultilanguageField
                                    fieldName={fieldName}
                                    value={value}
                                    onChange={changeByValue}
                                />
                            )
                        });

                        break;
                    }
                    case 'file': {
                        const isAttachments = options?.bucket === 'attachments';

                        const onChange = (value: {
                            file_path: string;
                            file_size?: number;
                            mime_type?: string;
                        }) => {
                            setArgs((prevArgs) => ({
                                ...prevArgs,
                                file_path: value.file_path,
                                file_size: value.file_size,
                                mime_type: value.mime_type
                            }));
                        };

                        const onChangeExternalFileName = (value: string) => {
                            const settableValue = value === undefined ? null : value;

                            setArgs((prevArgs) => ({
                                ...prevArgs,
                                file_name: settableValue
                            }));
                        };

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <FilePickerField
                                    // root_id={ids.join('_')}
                                    // meta={meta || ''}
                                    path={
                                        isAttachments
                                            ? `${meta}/${ids.join('_')}`
                                            : options?.path || ''
                                    }
                                    value={{
                                        file_path: value,
                                        file_name: args.file_name ?? options?.file_name,
                                        file_size: args.file_size,
                                        mime_type: args.mime_type
                                    }}
                                    onChange={onChange}
                                    onChangeExternalFileName={onChangeExternalFileName}
                                    bucket={options?.bucket}
                                />
                            )
                        });

                        break;
                    }
                    case '[]date': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateField
                                    multiple
                                    // style={{ width: DATE_FIELD_WIDTH }}
                                    value={value}
                                    onChange={changeByValue}
                                    utc={false}
                                />
                            )
                        });

                        break;
                    }
                    case 'date': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateField
                                    style={{ width: DATE_FIELD_WIDTH }}
                                    value={value}
                                    onChange={changeByValue}
                                    utc={false}
                                />
                            )
                        });
                        break;
                    }
                    case 'datetime': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateField
                                    style={{ width: DATE_FIELD_WIDTH + 20 }}
                                    value={value}
                                    onChange={changeByValue}
                                    utc={false}
                                    showTime
                                />
                            )
                        });
                        break;
                    }
                    case 'text':
                    case 'table_name': {
                        const values = options?.values;

                        let children = (
                            <Input
                                placeholder={t('no_value') as string}
                                value={value}
                                onChange={changeByEvent}
                            />
                        );

                        if (values) {
                            const valuesArray = values.split(',').map((value) => ({ value }));

                            children = (
                                <Select
                                    style={{ width: '100%' }}
                                    options={valuesArray}
                                    value={value}
                                    onChange={changeByValue}
                                />
                            );
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children
                        });
                        break;
                    }
                    case 'datetimerange': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDatetimeRangeField
                                    style={{ maxWidth: DATE_RANGE_FIELD_WIDTH }}
                                    value={value}
                                    onChange={changeByValue}
                                />
                            )
                        });
                        break;
                    }
                    case 'daterange': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateRangeField
                                    style={{ maxWidth: DATE_RANGE_FIELD_WIDTH }}
                                    value={value}
                                    onChange={changeByValue}
                                />
                            )
                        });
                        break;
                    }

                    case 'time': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartTimeField
                                    // style={{ maxWidth: DATE_RANGE_FIELD_WIDTH }}
                                    value={value}
                                    onChange={changeByValue}
                                />
                            )
                        });
                        break;
                    }
                    case 'color': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: <ColorField value={value} onChange={changeByValue} />
                        });

                        break;
                    }

                    case 'string': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Input
                                    placeholder={t('no_value') as string}
                                    value={value}
                                    onChange={changeByEvent}
                                />
                            )
                        });
                        break;
                    }
                    case 'decimal':
                    case 'money': {
                        let selectAfter;
                        if (Array.isArray(params)) {
                            const measureUnitParam = params.find((param) => {
                                const pascalParamName = toPascalCase(getParamCode(param)).replace(
                                    'Code',
                                    ''
                                );

                                const pascalParamCode = toPascalCase(paramCode).replace('Code', '');

                                // composite measure value
                                return (
                                    pascalParamName ===
                                        `${pascalParamCode.split('Value').join('')}MeasureUnit` ||
                                    // composite curreny value
                                    pascalParamName ===
                                        `${pascalParamCode
                                            .split('CurrencyValue')
                                            .join('')}Currency` ||
                                    pascalParamName ===
                                        `${pascalParamCode.split('Amount').join('')}Currency` ||
                                    pascalParamName ===
                                        `${pascalParamCode.split('Value').join('')}Currency`
                                );
                            });

                            if (measureUnitParam) {
                                const measureUnitParamCode = getParamCode(measureUnitParam);

                                const measureUnitValue = args?.[measureUnitParamCode];
                                const { options } = parseValueType(
                                    { valueType: measureUnitParam.ValueType },
                                    language,
                                    {
                                        root: { ...row, ids },
                                        current: { ...args, ids },
                                        self: measureUnitValue,
                                        info: metaInfo
                                    }
                                );

                                if (options && options.ref) {
                                    selectAfter = (
                                        <SmartSelectField
                                            className="measure_value__select_addon"
                                            value={measureUnitValue}
                                            meta={options?.ref?.meta}
                                            onChange={(newValue) => {
                                                setArgs((prevArgs) => ({
                                                    ...prevArgs,
                                                    [measureUnitParamCode]: newValue
                                                }));
                                            }}
                                            style={{ width: 75 }}
                                            filters={options.filters}
                                            simpleMode
                                            returnFullObject={true}
                                            treeOptions={{
                                                groupKeys: options?.group
                                                    ? [options.group]
                                                    : undefined,
                                                parentField: 'Parent'
                                            }}
                                        />
                                    );
                                }
                            }
                        }

                        // TODO: вынести в компонент
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%', maxWidth: 170 }}
                                    defaultValue={options ? options.default === 'true' : undefined}
                                    value={value}
                                    step="0.1"
                                    onChange={changeByValue}
                                    addonAfter={selectAfter}
                                />
                            )
                        });
                        break;
                    }
                    case 'int16':
                    case 'int32':
                    case 'int64': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%', maxWidth: NUMBER_FIELD_WIDTH }}
                                    defaultValue={options ? Number(options.default) : undefined}
                                    value={value}
                                    onChange={changeByValue}
                                    readOnly={isDefined(options?.value)} // если определен параметр типа "value:", то поле readOnly
                                />
                            )
                        });
                        break;
                    }
                    case 'number': {
                        const hasOptionsValue = isNumericString(options?.value);

                        if (hasOptionsValue && value !== parseFloat(options?.value as string)) {
                            setArgs((prev) => ({
                                ...prev,
                                [paramCode]: parseFloat(options?.value as string)
                            }));
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%', maxWidth: NUMBER_FIELD_WIDTH }}
                                    defaultValue={options ? Number(options.default) : undefined}
                                    value={value}
                                    step="0.05"
                                    onChange={changeByValue}
                                    readOnly={hasOptionsValue} // если определен параметр типа "value:", то поле readOnly
                                />
                            )
                        });
                        break;
                    }
                    case 'bool':
                    case 'boolean': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Checkbox
                                    defaultChecked={!!(options && options.default === 'true')}
                                    checked={value}
                                    onChange={(e) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: e.target.checked
                                        }))
                                    }
                                ></Checkbox>
                            )
                        });
                        break;
                    }
                    case 'seconds':
                    case 'duration': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: <SmartDurationField value={value} onChange={changeByValue} />
                        });
                        break;
                    }
                    case 'key':
                    case 'id':
                    case 'object_id':
                    case 'code': {
                        if (options && options.ref) {
                            const { meta } = options.ref;

                            // if (meta.endsWith('AvailableSlots')) {
                            if (options.is_time_slot) {
                                fields.push({
                                    key: paramCode,
                                    // vertical: true,
                                    label,
                                    children: (
                                        <SlotsPicker
                                            value={value}
                                            meta={meta}
                                            onChange={changeByValue}
                                            filters={options?.filters}
                                        />
                                    )
                                });

                                break;
                            }

                            let filePath;
                            let fileName;
                            let isTemplates;

                            if (value && typeof value === 'object') {
                                const templateFilePath = value.TemplateFilePath;

                                filePath = value.FilePath ?? templateFilePath;
                                fileName = value.FileName ?? value.TemplateFileName;
                                isTemplates = !!templateFilePath;
                            }

                            const showDownloadButton = !!filePath;
                            const isFetchOnMount = paramCode.startsWith('template_'); // hardcode. TODO: replace by priznak

                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartSelectField
                                        value={value}
                                        meta={meta}
                                        filters={options.filters}
                                        onChange={changeByValue}
                                        treeOptions={{
                                            groupKeys: options?.group ? [options.group] : undefined,
                                            parentField: 'Parent'
                                        }}
                                        navigateMetaOptions={{ metaName: options.ref.meta }}
                                        isFetchOnMount={isFetchOnMount}
                                        downloadOptions={
                                            showDownloadButton
                                                ? {
                                                      bucket: isTemplates ? 'templates' : 'public',
                                                      filePath,
                                                      fileName
                                                  }
                                                : undefined
                                        }
                                        selectFirstAsDefault={options?.first_as_default}
                                    />
                                )
                            });
                        } else {
                            fields.push({
                                key: paramCode,
                                label,
                                children: <Input value={value} onChange={changeByValue} />
                            });
                        }
                        break;
                    }
                    default: {
                        fields.push({
                            key: paramCode,
                            label,
                            children: <Input value={value} onChange={changeByValue} />
                        });

                        break;
                    }
                }
            };

            for (const param of filteredSortedParams ?? []) {
                if (param.IsRequested) {
                    paramRender(param);
                }
            }

            return fields;
        }, [args, ids, language, meta, metaInfo, metaName, params, row, t]);

        const fieldsWithLabel = useMemo(() => fields.filter((f) => !!f.label), [fields]);
        const fieldsWithoutLabel = useMemo(() => fields.filter((f) => !f.label), [fields]);

        return (
            <>
                <Modal
                    open={open}
                    onCancel={() => {
                        onCancel();
                        setChoosenFields([]);
                    }}
                    onOk={handleRun}
                    destroyOnClose
                    okText={navigateAfterRun && action?.NavItem ? t('follow') : t('run')}
                    confirmLoading={confirmLoading}
                    cancelText={t('cancel')}
                    title={modalTitle}
                    width={isBigMobile ? '95%' : '75%'}
                    zIndex={2000}
                    centered
                >
                    <>
                        {fieldsWithLabel?.length ? (
                            <FieldsBox
                                bordered
                                items={fields.filter((f) => !!f.label)}
                                size="small"
                                // column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
                                column={1}
                                labelStyle={{
                                    display: 'table-cell',
                                    backgroundColor: undefined,
                                    padding: undefined
                                }}
                                contentStyle={
                                    isMiddleTablet
                                        ? { width: '100%', display: 'block', paddingLeft: '-5px' }
                                        : { width: '70%', padding: '8px 16px' }
                                }
                                helpIconPlacement="end"
                                layout={isMiddleTablet ? 'vertical' : 'horizontal'}
                            />
                        ) : null}
                        {fieldsWithoutLabel.map((f) => f.children)}
                    </>

                    {isMassUpdate && (
                        <ButtonWithTooltips
                            id="all_fields"
                            type="default"
                            icon={<PlusOutlined />}
                            style={{ margin: '10px auto 10px 0' }}
                            onClick={openModal}
                        >
                            {t('all_fields')}
                        </ButtonWithTooltips>
                    )}
                </Modal>
                {chooseFieldsModal}
            </>
        );
    }
);
