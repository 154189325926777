// WebSocketService.ts
import { i18n } from 'utils/i18n/i18n';
import { v4 as uuidv4 } from 'uuid';
import { action, makeAutoObservable } from 'mobx';
import { notification, Typography } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { Request, Response } from './generated_api';

export type ProcessSubscriptionFn = (response: Response) => void;
export type UnsubscribeFn = () => void;

class WebSocketService {
    public isConnected = false;

    private url: string;

    private ws: WebSocket | null = null;

    private responseHandlers = new Map<string, (response: Response) => void>();

    private subscriptions = new Map<string, (response: Response) => void>();

    private reconnectInterval = 5000; // 5 seconds

    private isReconnecting = false;

    private returnLogMessages = false; // KT: отключил тк этим механизмом больше не пользуемся. плюс на бэке добавил ограничение 10к сообщений максимум

    private showTechnicalNotifications = false;

    constructor(url: string, options?: { showTechnicalNotifications: boolean }) {
        makeAutoObservable(this, {
            setIsConnected: action
        });

        this.showTechnicalNotifications = options?.showTechnicalNotifications ?? false;

        this.url = url;
    }

    setReturnLogMessages = (returnLogMessages: boolean) => {
        this.returnLogMessages = returnLogMessages;
    };

    connect = (): Promise<void> => {
        console.log('WebSocket: connect()');

        return new Promise((resolve, reject) => {
            this.ws = new WebSocket(this.url);

            // Event listeners for WebSocket connection
            this.ws.addEventListener('open', () => {
                console.log('WebSocket connection established');
                if (this.showTechnicalNotifications)
                    notification.open({
                        type: 'success',
                        key: 'websocket-open',
                        icon: <></>,
                        message: 'WebSocket connection established',
                        description: (
                            <>
                                <Typography.Text
                                    type="secondary"
                                    style={{
                                        fontSize: '11px',
                                        padding: 0,
                                        borderRadius: 0,
                                        whiteSpace: 'unset',
                                        overflow: 'unset',
                                        textOverflow: 'unset'
                                    }}
                                >
                                    {`${t('today')}, ${dayjs().format('HH:mm:ss')}`}
                                </Typography.Text>
                            </>
                        ),
                        duration: 10,
                        placement: 'bottomRight',
                        className: 'vectura-notifications vectura-notifications-success',
                        showProgress: true,
                        pauseOnHover: false
                    });
                this.setIsConnected(true);
                resolve();
            });

            this.ws.addEventListener('error', (err) => {
                console.error('WebSocket error:', err);
                this.setIsConnected(false);
                reject(err);
            });

            this.ws.addEventListener('message', this.handleMessage);

            this.ws.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
                if (this.showTechnicalNotifications)
                    notification.open({
                        type: 'error',
                        key: 'websocket-error',
                        icon: <></>,
                        message: 'WebSocket connection closed',
                        description: (
                            <>
                                <div style={{ marginBottom: 5 }}>
                                    <Typography.Text
                                        style={{
                                            padding: 0,
                                            paddingBottom: 9,
                                            borderRadius: 0,
                                            whiteSpace: 'unset',
                                            overflow: 'unset',
                                            textOverflow: 'unset'
                                        }}
                                    >
                                        {JSON.stringify(event)}
                                    </Typography.Text>
                                </div>
                                <Typography.Text
                                    type="secondary"
                                    style={{
                                        fontSize: '11px',
                                        padding: 0,
                                        borderRadius: 0,
                                        whiteSpace: 'unset',
                                        overflow: 'unset',
                                        textOverflow: 'unset'
                                    }}
                                >
                                    {`${t('today')}, ${dayjs().format('HH:mm:ss')}`}
                                </Typography.Text>
                            </>
                        ),
                        duration: 20,
                        placement: 'bottomRight',
                        className: 'vectura-notifications vectura-notifications-error',
                        showProgress: true,
                        pauseOnHover: true
                    });
                this.setIsConnected(false);
                this.handleReconnect();
            });
        });
    };

    getWs = (): WebSocket | null => {
        return this.ws;
    };

    waitForConnection = (callback: () => void, interval: number = 1000): void => {
        if (this.ws) {
            if (this.ws.readyState === WebSocket.OPEN) {
                callback();
            } else {
                setTimeout(() => this.waitForConnection(callback, interval), interval);
            }
        }
    };

    sendRequest = (
        data: Omit<Request, 'request_id' | 'return_log_messages'>
    ): Promise<Response> => {
        return new Promise((resolve, reject) => {
            this.waitForConnection(() => {
                if (!this.ws) {
                    reject(new Error('WebSocket is not connected.'));
                    return;
                }

                const request_id = uuidv4();
                const return_log_messages = this.returnLogMessages;

                const layoutGOSpecialDate = new Date(2006, 0, 2, 15, 4, 5, 0);
                const dateLocale =
                    navigator.language || i18n?.language
                        ? i18n.language === 'tech'
                            ? 'en'
                            : i18n.language
                        : 'en';
                const dateLayout = Intl.DateTimeFormat(dateLocale, { dateStyle: 'short' }).format(
                    layoutGOSpecialDate
                );
                const timeLayout = Intl.DateTimeFormat(dateLocale, { timeStyle: 'short' }).format(
                    layoutGOSpecialDate
                );
                const dateTimeLayout = `${dateLayout} ${timeLayout}`;

                const message: Request & { Lang: string } = {
                    ...data,
                    // auth_provider: 'UKNOWN',
                    request_id,
                    return_log_messages,
                    Lang: i18n.language || 'en',
                    UserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    UserDateLayout: dateLayout,
                    UserDatetimeLayout: dateTimeLayout,
                    UserTimeLayout: timeLayout
                };

                // console.debug('Send request:', message);
                this.responseHandlers.set(request_id, (response: Response) => {
                    resolve(response);
                    this.responseHandlers.delete(request_id);
                });
                // debugger;
                this.ws.send(JSON.stringify(message));
            }, 1000);
        });
    };

    subscribe = (
        data: Omit<Request, 'request_id' | 'return_log_messages'>,
        process: ProcessSubscriptionFn
    ): UnsubscribeFn => {
        let wasResponse = false;
        const request_id = uuidv4();

        const unsubscribe = () => {
            console.log('Unsubscribed from', request_id);

            // FIXME: на backend не реализована отписка, поэтому после отписки на фронте,
            //  будем продолжать получать сообщения
            this.subscriptions.delete(request_id);
        };

        this.waitForConnection(() => {
            if (!this.ws) {
                throw new Error('WebSocket is not connected.');
            }

            const return_log_messages = this.returnLogMessages;

            const message: Request & { Lang: string } = {
                ...data,
                request_id,
                return_log_messages,
                Lang: i18n.language || 'en',
                UserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            this.subscriptions.set(request_id, (response: Response) => {
                if (!wasResponse) {
                    console.log('Subscribed to', request_id);

                    wasResponse = true;

                    return;
                }

                process(response);
            });

            this.ws.send(JSON.stringify(message));
        }, 1000);

        return unsubscribe;
    };

    close = (): void => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            console.log('WebSocket: close()');
            this.ws.close();
            this.ws = null;
            this.responseHandlers.clear();
            this.subscriptions.clear();
        }
    };

    private handleMessage = (event: MessageEvent): void => {
        try {
            // const start = performance.now(); // Log start time
            const message = event.data;

            // Check if the message is a "ping" message
            if (message === 'ping') {
                console.log('Ping message received from server');
            } else {
                const response: Response = JSON.parse(message);

                const handler = this.responseHandlers.get(response.request_id);
                if (handler) {
                    handler(response);

                    // const end = performance.now();
                    // console.log(
                    //     `Response message for request_id=${
                    //         response.request_id
                    //     } received and processed in ${end - start} ms`
                    // );
                    return;
                }

                const subscriptionHandler = this.subscriptions.get(response.request_id);
                if (subscriptionHandler) {
                    subscriptionHandler(response);

                    return;
                }

                console.warn(`Unhandled message: ${message}`);
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    };

    private handleReconnect = (): void => {
        if (!this.isReconnecting) {
            this.isReconnecting = true;
            this.responseHandlers.clear();
            this.subscriptions.clear();

            setTimeout(() => {
                console.log('Attempting to reconnect...');
                this.connect()
                    .then(() => {
                        this.isReconnecting = false;
                        console.log('Reconnected successfully');

                        // TODO: наверное, надо перезапускать подписки
                    })
                    .catch((err) => {
                        console.error('Reconnection failed:', err);
                        this.isReconnecting = false;
                        this.handleReconnect();
                    });
            }, this.reconnectInterval);
        }
    };

    private setIsConnected = (value: boolean) => {
        this.isConnected = value;
    };
}

export default WebSocketService;
