import { Divider, Flex, Tabs, TabsProps } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { isDefined } from 'is-lite/exports';
import {
    useCallback,
    useContext,
    useDeferredValue,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useAliveController } from 'react-activation';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import * as uuid from 'uuid';
import { UniversalBoundary } from 'components';
import { CommonPageContext } from 'modules/layouts/DefaultLayout/components/CommonPage/CommonPage';
// import { useSupabaseUser } from 'modules/supabase/utils/hooks/useSupabaseUser';
// import Handlers from 'modules/form_extra';
// import { IModel } from 'modules/form_extra/types';
// import * as MetaTypes from 'modules/services/backend-api/generated_models';
import {
    fieldEditRender,
    fieldRender,
    fnv1aHash,
    getDetailPageTitle,
    reduceArrayToMap
} from 'smart/utils';
import { Loader } from 'ui/Loader/Loader';
import { toSnakeCase } from 'utils';
import { LANGUAGES } from 'utils/i18n/i18n';
import { routeStore, useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

import { Stepper } from 'smart/components';
import { emitter } from 'utils/emitter';
import { GraphicsTab } from 'smart/components/SmartGraphic/GraphicsTab';
import { useAuthUser } from 'modules/client/useAuthUser';
import { useBaseLanguage, useModel } from 'smart/utils/hooks';
import { makeDeepMerge } from 'utils/helpers/makeDeepMerge';

import { useData, useMode, useQueries, useTabs } from './utils/hooks';
import { FieldsBoxItemType } from './ui';
import { SmartTablePage } from '../SmartTablePage';
import {
    MobileView,
    SmartDetailPageChildTabContent,
    SmartDetailPageHeaderToolbar,
    SmartDetailPageMainTabContent
} from './components';

import { TopFields } from './components/TopFields/TopFields';
import './SmartDetailPage.scss';

export interface SmartDetailPageProps {
    meta: string;
    id: string;
    data?: any;
    location?: Location;
}

const SIZE = 'small';

export const SmartDetailPage = observer<SmartDetailPageProps>(
    ({ meta, id: idFromPath, data, location: locationFromProps }) => {
        const isCreate = idFromPath === 'new';
        const isCopy = idFromPath === 'copy';
        const isNoNeedFetchOnlyRead = idFromPath === 'no_fetch_only_read';

        const id = idFromPath || data?.Id;

        const {
            t,
            i18n: { language }
        } = useTranslation();
        const baseLanguage = useBaseLanguage();

        const { isPowerUser } = useAuthUser();
        const oldNavigate = useNavigate();
        const storeNavigate = useStoreNavigate();
        const { getClosePageHandler } = useContext(CommonPageContext);
        const { dropScope } = useAliveController();
        const isBigMobile = useMedia('(max-width: 480px)');

        const locationFromInside = useLocation();
        const location = locationFromProps || locationFromInside;
        const pathnameWithoutId = location.pathname.split('/').slice(0, -1).join('/');

        // const currentPath = location.pathname;
        const preFilters = location.state?.filterString;

        const [mode, setMode] = useMode({ meta, id, initialMode: location.state?.mode });
        const [activeTab, setActiveTab] = useTabs({ meta, id });

        const metaSource = metaStore.meta.get(meta);

        const info = metaSource?.info;
        // const rootMetaInfo = toJS(info);
        // const originalRootMetaInfo = metaSource?.originalInfo;
        const uiAllowView = info?.UiAllowView ?? true;
        const routesMap = metaStore.meta.get('all')?.routesMap;

        const metaLayout = useMemo(() => {
            const routesFotCurrentMeta = routesMap?.get(meta);

            const tablePagePathname =
                location.pathname === '/profile' ? '/settings/users' : pathnameWithoutId;

            return preFilters
                ? toJS(
                      routesFotCurrentMeta?.find(
                          (r) => r.path.includes(tablePagePathname) && r.path.includes(preFilters)
                      )?.layout
                  )
                : toJS(
                      routesFotCurrentMeta?.find((r) => r.path.includes(tablePagePathname))?.layout
                  );
        }, [pathnameWithoutId, meta, routesMap, preFilters]);

        const dataId = isCreate || isCopy ? uuid.v4() : id;

        const modelMetaInfo = useMemo(() => {
            if (info && metaLayout?.Info) {
                const layoutObject = metaLayout.Info;
                const layoutByPlatform = isBigMobile
                    ? layoutObject.InfoMobile
                    : layoutObject.InfoDesktop;
                const layoutDefault = layoutObject.Info;
                const layout = layoutByPlatform
                    ? layoutDefault
                        ? makeDeepMerge(layoutDefault, layoutByPlatform)
                        : layoutByPlatform
                    : layoutDefault;

                if (!layout) return toJS(info);

                return makeDeepMerge(toJS(info), layout);
            }

            return toJS(info);
        }, [info, metaLayout?.Info, isBigMobile]);

        const {
            model,
            setModel,
            executeHandler,
            loading: serverHandlerLoading
        } = useModel({
            metaCode: meta,
            meta: modelMetaInfo,
            modulePart: 'detail',
            data,
            metaLayout,
            id: dataId
        });

        const detailLayout = useMemo(() => {
            // const layout = metaLayout?.DetailPage;
            const layout = model.layout?.detail;
            const mobile = layout?.DetailPageMobile;
            const desktop = layout?.DetailPageDesktop;
            const any = layout?.DetailPage;
            const result = { any, mobile, desktop };

            // console.log('[SmartDetailPage] detail layout', result);

            return result;
        }, [model.layout?.detail]);
        // }, [metaLayout]);

        // ### CHILD NODES ###
        const metaChildNodes = useMemo(() => {
            // const metaChildNodes = [...(toJS(info)?.ChildNodes || [])];
            const metaChildNodes = [...(model.meta?.ChildNodes || [])];
            // console.log('[SmartDetailPage] meta child nodes:', metaChildNodes);

            // return metaChildNodes.filter(({ IsDisabled }) => !IsDisabled);
            return metaChildNodes
                .map((node) => {
                    const tunedLayoutByPlatform =
                        detailLayout[isBigMobile ? 'mobile' : 'desktop']?.LayoutAreas?.[
                            node?.LayoutArea ?? ''
                        ];
                    const tunedLayoutAny = detailLayout.any?.LayoutAreas?.[node?.LayoutArea ?? ''];

                    const currentTunedLayoutByPlatform = tunedLayoutByPlatform?.ChildNodes?.find(
                        (n) => n.Code === node.Code
                    );
                    const currentTunedLayoutAny = tunedLayoutAny?.ChildNodes?.find(
                        (n) => n.Code === node.Code
                    );

                    const defaultNameObject = { [language]: t(node.TableName) };

                    return {
                        ...node,
                        IsCollapsible:
                            tunedLayoutByPlatform?.Collapsible ??
                            tunedLayoutAny?.Collapsible ??
                            node.LayoutArea?.includes('/'),
                        IsShowOnTab:
                            tunedLayoutByPlatform?.ShowOnTab ??
                            tunedLayoutAny?.ShowOnTab ??
                            // !node.LayoutArea?.includes('/'),
                            !node.LayoutArea?.startsWith('Main'),
                        IsHidden:
                            currentTunedLayoutByPlatform?.IsHidden ??
                            currentTunedLayoutAny?.IsHidden ??
                            tunedLayoutByPlatform?.DefaultNode?.IsHidden ??
                            tunedLayoutAny?.DefaultNode?.IsHidden ??
                            tunedLayoutByPlatform?.IsHidden ??
                            tunedLayoutAny?.IsHidden ??
                            node.IsHidden,
                        ShowTitle:
                            // currentTunedLayoutByPlatform?.ShowTitle ??
                            // currentTunedLayoutAny?.ShowTitle ??
                            // tunedLayoutByPlatform?.DefaultNode?.ShowTitle ??
                            // tunedLayoutAny?.DefaultNode?.ShowTitle ??
                            tunedLayoutByPlatform?.ShowTitle ?? tunedLayoutAny?.ShowTitle ?? true,
                        Name:
                            language !== 'tech'
                                ? node.PluralName || defaultNameObject
                                : defaultNameObject,
                        LayoutAreaName: tunedLayoutByPlatform?.Name ?? tunedLayoutAny?.Name
                    };
                })
                .filter(({ IsHidden }) => !IsHidden);
        }, [isBigMobile, t, language, detailLayout, model.meta]);

        // ### MAIN FIELDS ###
        const mainDataFields = useMemo(() => {
            // const metaFields = [...(toJS(info?.Fields) || [])];
            const metaFields = [...(model.meta?.Fields || [])];
            // console.log('[SmartDetailPage] meta main data fields:', metaFields);

            return metaFields.map((field) => {
                const tunedLayoutByPlatform =
                    detailLayout[isBigMobile ? 'mobile' : 'desktop']?.LayoutAreas?.[
                        field?.LayoutArea ?? ''
                    ];
                const tunedLayoutAny = detailLayout.any?.LayoutAreas?.[field?.LayoutArea ?? ''];

                const currentTunedLayoutByPlatform = tunedLayoutByPlatform?.Fields?.find(
                    (f) => f.FieldName === field.FieldName
                );
                const currentTunedLayoutAny = tunedLayoutAny?.Fields?.find(
                    (f) => f.FieldName === field.FieldName
                );

                return {
                    ...field,
                    IsCollapsible:
                        tunedLayoutByPlatform?.Collapsible ?? tunedLayoutAny?.Collapsible ?? true,
                    ShowTitle:
                        currentTunedLayoutByPlatform?.ShowTitle ??
                        currentTunedLayoutAny?.ShowTitle ??
                        tunedLayoutByPlatform?.DefaultField?.ShowTitle ??
                        tunedLayoutAny?.DefaultField?.ShowTitle ??
                        // tunedLayoutByPlatform?.ShowTitle ??
                        // tunedLayoutAny?.ShowTitle ??
                        true,
                    IsHiddenOnDetail:
                        currentTunedLayoutByPlatform?.IsHidden ??
                        currentTunedLayoutAny?.IsHidden ??
                        tunedLayoutByPlatform?.DefaultField?.IsHidden ??
                        tunedLayoutAny?.DefaultField?.IsHidden ??
                        tunedLayoutByPlatform?.IsHidden ??
                        tunedLayoutAny?.IsHidden ??
                        field.IsHiddenOnDetail,
                    LayoutAreaName: tunedLayoutByPlatform?.Name ?? tunedLayoutAny?.Name
                };
            });
        }, [isBigMobile, detailLayout, model.meta]);

        const groupFieldName = useMemo(
            () => mainDataFields.find((field) => field.ValueType?.includes('is_group'))?.FieldName,
            [mainDataFields]
        );

        const { dataSource, setData, changeData, changes } = useData({
            id: dataId,
            data,
            mode,
            markAllFieldsAlreadyChanged: isCopy || isCreate,
            parentFieldName: groupFieldName ?? 'Parent',
            executeUIHandler: executeHandler,
            setModel
        });

        useEffect(() => {
            const layout = metaLayout
                ? {
                      detail: metaLayout.DetailPage,
                      table: metaLayout.TablePage,
                      info: metaLayout.Info
                  }
                : undefined;

            // const meta = toJS(originalRootMetaInfo);
            // const metaWithLayout = toJS(info);
            // const meta = toJS(info);

            setModel((prev) => {
                return {
                    ...prev,
                    data: { ...data, ...prev.data, ...dataSource },
                    layout
                };
            });
        }, [data, dataSource, metaLayout]);

        const [metaInitialized, setMetaInitialized] = useState(false);
        const initialized = metaInitialized && !serverHandlerLoading;

        const {
            fetch,
            refresh,
            acquireLock,
            releaseLock,
            loading,
            setLoading,
            updatedMeta,
            isReload,
            setIsReload
        } = useQueries({
            meta,
            id,
            currentPathForGetMetaInfo: `${pathnameWithoutId}${preFilters ? `?${preFilters}` : ''}`
        });

        // ### microframework :) ###
        useEffect(() => {
            if (initialized) {
                setLoading(true);

                const isSuccess = executeHandler({
                    methodName: 'onOpen',
                    extraArguments: [undefined, undefined, modelMetaInfo]
                });
                if (!isSuccess) {
                    setModel((prev) => ({ ...prev, meta: modelMetaInfo }));
                }

                setLoading(false);
            }
            // else if (modelMetaInfo) {
            //     // setModel((prev) => ({ ...prev, meta: toJS(info) }));
            //     setModel((prev) => ({ ...prev, meta: modelMetaInfo }));
            //     // setMetaInitialized(true);
            // }

            return () => {
                if (initialized) {
                    executeHandler({ methodName: 'onClose' });
                }
            };
        }, [modelMetaInfo?.Code, initialized]);
        // ### !!! ###

        const handleDropScope = useCallback(
            () => dropScope(`detailPage_${location.state?.cacheKey || location.pathname}`),
            [dropScope, location.state?.cacheKey, location.pathname]
        );

        const handleClosePage = getClosePageHandler(location, storeNavigate, handleDropScope);
        const [lockId, setLockId] = useState<string | null>(null);

        const [selectedLanguage, setSelectedLanguage] = useState('');

        const sortedTabIndexes: Record<string, number> = {};

        metaChildNodes.forEach((node) => {
            sortedTabIndexes[node?.LayoutArea || node.Code] = node.ChildIndex || 0;
        });

        const handleRefresh = useCallback(async () => {
            const data = await refresh();

            setData(data);
        }, [refresh, setData]);

        // ### ЗАПРОС ДАННЫХ С СЕРВЕРА (info, get)
        useEffect(() => {
            (async () => {
                const metaInfo = (await fetch(true, false)).info;
                // console.log('[SmartDetailPage] meta info:', metaInfo);

                if (isCreate) {
                    setData({
                        Id: uuid.v4(),
                        ...data,
                        [groupFieldName ?? 'Parent']: data?.[groupFieldName ?? 'Parent']
                    });
                    setMode('edit');
                }

                if (isCopy) {
                    setMode('edit');
                }

                if (isNoNeedFetchOnlyRead) {
                    setMode('view');
                }

                if (metaInfo && !isCreate && !isCopy && !isNoNeedFetchOnlyRead) {
                    const res = (await fetch(false, true)).get;
                    // console.log('[SmartDetailPage] meta main data:', data);

                    const data = (res?.object ?? {}) as IObjectWithId;

                    setData(data);
                    // setModel((prev) => ({ ...prev, data: { ...data, ...(data?.object || {}) } }));
                    setModel((prev) => ({
                        ...prev,
                        data: { ...prev.data, ...data }
                    }));
                }

                setMetaInitialized(true);
            })();
        }, [fetch, id, isCopy, isCreate, meta, setMode, isNoNeedFetchOnlyRead]);

        // ### PAGE TITLE ###
        useEffect(() => {
            const { pathname, state, search } = location;

            if (state?.pageTitle) return;

            if (
                !isCopy &&
                !isCreate &&
                ((!state?.pageTitle && String(dataSource.Id) === String(id)) ||
                    (selectedLanguage !== language && !loading))
            ) {
                const cacheKey = fnv1aHash(`${meta}_${id}_${mode}`);

                const pageTitle = getDetailPageTitle({
                    // language,
                    pathname: pathnameWithoutId,
                    state,
                    data: dataSource,
                    // t,
                    meta,
                    metaData: toJS(info) // TODO: может заменить на model.meta?
                });

                setSelectedLanguage(language);

                const oldRoute = {
                    pathname,
                    search,
                    state
                };

                const newRoute = {
                    pathname,
                    search,
                    state: state
                        ? {
                              ...state,
                              ...pageTitle,

                              cacheKey
                          }
                        : { ...pageTitle, cacheKey }
                };

                if (routeStore.findIndex(newRoute) === -1) {
                    routeStore.replace(oldRoute, newRoute);
                    oldNavigate(
                        { pathname, search },
                        {
                            state: {
                                ...state,
                                ...pageTitle,
                                cacheKey
                            }
                        }
                    );
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            mode,
            dataSource,
            id,
            isCopy,
            isCreate,
            loading,
            language,
            location,
            meta,
            oldNavigate,
            selectedLanguage,
            info
            // t
        ]);

        // ### TOP FIELDS ###
        const topFields: FieldsBoxItemType[] = useMemo(() => {
            const topFields: FieldsBoxItemType[] = [];

            for (const field of mainDataFields) {
                const key = field.FieldName;
                // const isType = key === 'Type';
                // const isDate = key.includes('Date');
                const isKey = key === 'Key';
                const isId = key === 'Id';
                const isCode = key === 'Code';

                if (
                    !isId &&
                    field.LayoutArea &&
                    field.LayoutArea === 'Page/Header' &&
                    !field.IsHiddenOnDetail
                ) {
                    const label = field.Name
                        ? field.Name[language] || t(field.ColumnName || key)
                        : t(field.ColumnName || key);

                    const children =
                        (isCode && mode === 'edit' && (isPowerUser || isCreate || isCopy)) || // for code
                        (isKey && mode === 'edit' && isPowerUser) || // for key
                        (!isCode && !isKey && !isId && !field.IsReadOnly && mode === 'edit') // default
                            ? fieldEditRender({
                                  data: dataSource[key],
                                  language,
                                  metaFieldData: field,
                                  dataSource,
                                  rootDataSource: dataSource,
                                  onChange: changeData,
                                  rootMeta: meta
                              })
                            : fieldRender({
                                  data: dataSource[key],
                                  language: language as LANGUAGES,
                                  baseLanguage,
                                  metaFieldData: field,
                                  dataSource,
                                  rootDataSource: dataSource,
                                  rootMeta: meta
                              });
                    topFields.push({
                        key,
                        label: field.ShowTitle ? label : undefined,
                        children,
                        description: field.Description?.[language]
                        // label: <HelpIcon text={field.Description?.[language]}>{label}</HelpIcon>,
                        // children: (
                        //     <HelpIcon text={field.Description?.[language]}>{children}</HelpIcon>
                        // )
                    });
                }
            }

            return topFields;
        }, [
            mainDataFields,
            language,
            t,
            mode,
            isPowerUser,
            isCreate,
            isCopy,
            dataSource,
            changeData,
            meta,
            baseLanguage
        ]);

        // ### TABS ###
        const tabs = useMemo(() => {
            const tabs: TabsProps['items'] = [];

            // const data = dataSource;
            const data = model.data;

            // ### Разделяем, какие чайлды будут в основном табе, а какие - в своих
            const childNodes = metaChildNodes.filter(
                (node) => node.IsShowOnTab && !node.LayoutArea?.includes('/')
            );
            const sampleChildNodes = childNodes.filter((node) => !node.Code.startsWith('Info'));
            const serviceChildNodes = childNodes.filter((node) => node.Code.startsWith('Info'));
            // const assocActions = toJS(info)?.Actions?.filter(
            const assocActions = model.meta?.Actions?.filter(
                (a) => isDefined(a.Association) && a.IsBuiltIn
            );
            const associations = assocActions?.map((a) => ({
                ...a.Association,
                TabName: a.Name
            }));

            const mainCollapsible =
                detailLayout?.[isBigMobile ? 'mobile' : 'desktop']?.LayoutAreas?.Main?.ShowTitle ??
                detailLayout?.any?.LayoutAreas?.Main?.ShowTitle ??
                true;

            // ### ОСНОВНОЙ ТАБ - есть всегда
            tabs.push({
                label: mainCollapsible ? t('common_info') : undefined,
                key: 'common_info',
                children: (
                    <SmartDetailPageMainTabContent
                        isReload={isReload}
                        mode={mode}
                        data={data}
                        rootData={data}
                        setData={setData}
                        onChange={changeData}
                        metaFields={mainDataFields.filter(
                            (field) => field.LayoutArea && field.LayoutArea.startsWith('Main')
                        )}
                        childNodeMeta={metaChildNodes.filter(
                            (node) => !node?.IsShowOnTab && !node?.IsHidden
                        )}
                        exclude={[...topFields.map(({ key }) => key as string), 'Id']}
                        rootMeta={meta}
                    />
                )
            });

            // ### ДЕТСКИЕ ТАБЫ
            const layoutAreaNodesMap = reduceArrayToMap(metaChildNodes, 'LayoutArea');

            for (const node of sampleChildNodes) {
                // if (node.LayoutArea?.includes('/')) continue;

                if (node.Code === 'RegScheduleSlots' && node.RootMeta_Code === 'CatSchedules')
                    continue;

                const childNodesWithCurrentArea = layoutAreaNodesMap.get(node.LayoutArea || '');

                if (
                    // node.LayoutArea === node.Code &&
                    !tabs.find((t) => t.key === node.LayoutArea) &&
                    !mainDataFields.find(
                        (field) =>
                            field.LayoutArea?.split('/')[0] === node.LayoutArea?.split('/')[0]
                    )
                ) {
                    if (node.LayoutArea === node.Code) {
                        tabs.push({
                            isCollapsible: node.IsCollapsible,
                            isChild: true,
                            key: node.LayoutArea || node.Code,
                            label: node.ShowTitle ? node.Name[language] : undefined,
                            children: (
                                <>
                                    <SmartDetailPageChildTabContent
                                        mode={mode}
                                        setData={setData}
                                        onChange={changeData}
                                        data={data}
                                        rootData={data}
                                        childNodeMeta={node}
                                        readOnly={node.IsReadOnly}
                                        rootMeta={meta}
                                    />
                                    {childNodesWithCurrentArea?.map((otherNode) => {
                                        if (otherNode.Code === node.Code) return null;

                                        return (
                                            <SmartDetailPageChildTabContent
                                                mode={mode}
                                                setData={setData}
                                                onChange={changeData}
                                                data={data}
                                                rootData={data}
                                                childNodeMeta={otherNode}
                                                readOnly={otherNode.IsReadOnly}
                                                tableTitle={otherNode.Name[language]}
                                                rootMeta={meta}
                                                // enableCollapse
                                            />
                                        );
                                    })}
                                </>
                            )
                        });
                    } else {
                        tabs.push({
                            isCollapsible: node.IsCollapsible,
                            isChild: true,
                            key: node.LayoutArea || node.Code,
                            label: node.ShowTitle
                                ? node.LayoutArea !== node.Code && childNodesWithCurrentArea?.length
                                    ? childNodesWithCurrentArea.find((n) => n.Code !== node.Code)
                                          ?.PluralName?.[language] ||
                                      t(toSnakeCase(node.LayoutArea || ''))
                                    : node.Name[language]
                                : undefined,
                            children: (
                                <>
                                    {childNodesWithCurrentArea?.map((otherNode, index) => {
                                        if (otherNode.Code === node.Code) return null;

                                        return (
                                            <SmartDetailPageChildTabContent
                                                mode={mode}
                                                setData={setData}
                                                onChange={changeData}
                                                data={data}
                                                rootData={data}
                                                childNodeMeta={otherNode}
                                                readOnly={otherNode.IsReadOnly}
                                                tableTitle={
                                                    index !== 0
                                                        ? otherNode.Name[language]
                                                        : undefined
                                                }
                                                rootMeta={meta}
                                                // enableCollapse
                                            />
                                        );
                                    })}
                                    <SmartDetailPageChildTabContent
                                        mode={mode}
                                        setData={setData}
                                        onChange={changeData}
                                        data={data}
                                        rootData={data}
                                        childNodeMeta={node}
                                        readOnly={node.IsReadOnly}
                                        tableTitle={
                                            childNodesWithCurrentArea?.length
                                                ? node.Name[language]
                                                : undefined
                                        }
                                        rootMeta={meta}
                                    />
                                </>
                            )
                        });
                    }
                }
            }

            const layoutAreaFieldsMap = reduceArrayToMap(
                mainDataFields,
                'LayoutArea',
                'IsHiddenOnDetail'
            );

            const excludeFieldNames = [...topFields.map(({ key }) => key as string), 'Id'];

            // ### Табы, которые ПОСТРОЕНЫ ИЗ ФИЛДОВ и их LayoutArea
            for (const field of mainDataFields) {
                let areaPlace = '';

                if (
                    field.LayoutArea &&
                    !field.LayoutArea.includes('Main') &&
                    !(
                        field.LayoutArea.includes('Page') &&
                        !field.LayoutArea.includes('DetailPage') &&
                        !field.LayoutArea.includes('TablePage')
                    ) &&
                    !tabs.find(({ key }) => {
                        areaPlace = field.LayoutArea?.split('/')[0] as string;
                        return areaPlace && key === areaPlace;
                    }) &&
                    (!field.IsHiddenOnDetail || layoutAreaFieldsMap.has(areaPlace)) // layoutAreaNodesMap
                    // childNodes.find((node) => node.LayoutArea?.includes(areaPlace)))
                ) {
                    const rootAreaChild = childNodes.find(
                        (node) => node.LayoutArea?.split('/')[0] === areaPlace
                    );

                    const tabMetaFieldsData = layoutAreaFieldsMap.get(areaPlace);

                    const tabChildNodeMetaData = layoutAreaNodesMap.get(areaPlace);

                    tabs.push({
                        label: field.ShowTitle
                            ? rootAreaChild?.Code === areaPlace
                                ? // ? rootAreaChild.PluralName?.[language] || t(rootAreaChild.TableName)
                                  rootAreaChild.ShowTitle
                                    ? rootAreaChild.Name[language]
                                    : undefined
                                : field.ShowTitle
                                ? field.LayoutAreaName
                                    ? field.LayoutAreaName?.[language]
                                    : t(toSnakeCase(areaPlace))
                                : undefined
                            : undefined,
                        key: areaPlace,
                        children: (
                            <SmartDetailPageMainTabContent
                                isReload={isReload}
                                mode={mode}
                                data={data}
                                rootData={data}
                                setData={setData}
                                onChange={changeData}
                                metaFields={tabMetaFieldsData || []}
                                childNodeMeta={tabChildNodeMetaData || []}
                                exclude={excludeFieldNames}
                                rootMeta={meta}
                            />
                        )
                    });
                }
            }

            const regSchedules = childNodes.find(
                (node) => node.Code === 'RegScheduleSlots' && node.RootMeta_Code === 'CatSchedules'
            );

            if (regSchedules)
                tabs.push({
                    isCollapsible: regSchedules.IsCollapsible,
                    label: regSchedules.ShowTitle ? regSchedules.Name[language] : undefined,
                    key: regSchedules.LayoutArea || regSchedules.Code,
                    children: (
                        <GraphicsTab
                            activeTab={activeTab}
                            activityFromAt={data.ActivityFromDate}
                            activityToAt={data.ActivityToDate}
                            rootId={data.Id}
                        />
                    )
                });

            for (const assoc of associations ?? []) {
                if (tabs.find((t) => t.key === assoc?.Code)) continue;

                if (assoc.TargetMeta_Code === 'InfoObjectWorkflows') {
                    tabs.push({
                        isChild: true,
                        // label: assoc?.Name?.[language],
                        label: assoc?.TabName?.[language],
                        key: assoc?.Code,
                        children: (
                            <Stepper
                                filters={`Id=assoc.${assoc?.Code}(["${id}"])`}
                                direction="vertical"
                                disableWrapper
                            />
                        )
                    });
                } else {
                    tabs.push({
                        isChild: true,
                        // label: assoc?.Name?.[language],
                        label: assoc?.TabName?.[language],
                        key: assoc?.Code,
                        children: (
                            <SmartTablePage
                                isReload={isReload}
                                meta={assoc?.TargetMeta_Code ?? ''}
                                isBuiltInAssociation
                                preFilters={`Id=assoc.${assoc?.Code}(["${id}"])`}
                                disableHeader
                                disableFooter
                            />
                        )
                    });
                }
            }

            for (const node of serviceChildNodes) {
                // if (node.LayoutArea?.includes('/')) continue;

                const childNodesWithCurrentArea = layoutAreaNodesMap.get(node.LayoutArea || '');

                if (
                    // node.LayoutArea === node.Code &&
                    !tabs.find((t) => t.key === node.LayoutArea) &&
                    !mainDataFields.find(
                        (field) =>
                            field.LayoutArea?.split('/')[0] === node.LayoutArea?.split('/')[0]
                    )
                ) {
                    if (node.LayoutArea === node.Code) {
                        tabs.push({
                            isCollapsible: node.IsCollapsible,
                            isChild: true,
                            key: node.LayoutArea || node.Code,
                            label: node.ShowTitle ? node.Name[language] : undefined,
                            children: (
                                <>
                                    <SmartDetailPageChildTabContent
                                        mode={mode}
                                        setData={setData}
                                        onChange={changeData}
                                        data={data}
                                        rootData={data}
                                        childNodeMeta={node}
                                        readOnly={node.IsReadOnly}
                                        rootMeta={meta}
                                    />
                                    {childNodesWithCurrentArea?.map((otherNode) => {
                                        if (otherNode.Code === node.Code) return null;

                                        return (
                                            <SmartDetailPageChildTabContent
                                                mode={mode}
                                                setData={setData}
                                                onChange={changeData}
                                                data={data}
                                                rootData={data}
                                                childNodeMeta={otherNode}
                                                readOnly={otherNode.IsReadOnly}
                                                tableTitle={otherNode.Name[language]}
                                                rootMeta={meta}
                                                // enableCollapse
                                            />
                                        );
                                    })}
                                </>
                            )
                        });
                    } else {
                        tabs.push({
                            isCollapsible: node.IsCollapsible,
                            isChild: true,
                            key: node.LayoutArea || node.Code,
                            label: node.ShowTitle
                                ? node.LayoutArea !== node.Code && childNodesWithCurrentArea?.length
                                    ? childNodesWithCurrentArea.find((n) => n.Code !== node.Code)
                                          ?.PluralName?.[language] ||
                                      t(toSnakeCase(node.LayoutArea || ''))
                                    : node.Name[language]
                                : undefined,
                            children: (
                                <>
                                    {childNodesWithCurrentArea?.map((otherNode, index) => {
                                        if (otherNode.Code === node.Code) return null;

                                        return (
                                            <SmartDetailPageChildTabContent
                                                mode={mode}
                                                setData={setData}
                                                onChange={changeData}
                                                data={data}
                                                rootData={data}
                                                childNodeMeta={otherNode}
                                                readOnly={otherNode.IsReadOnly}
                                                tableTitle={
                                                    index !== 0
                                                        ? otherNode.Name[language]
                                                        : undefined
                                                }
                                                rootMeta={meta}
                                                // enableCollapse
                                            />
                                        );
                                    })}
                                    <SmartDetailPageChildTabContent
                                        mode={mode}
                                        setData={setData}
                                        onChange={changeData}
                                        data={data}
                                        rootData={data}
                                        childNodeMeta={node}
                                        readOnly={node.IsReadOnly}
                                        tableTitle={
                                            childNodesWithCurrentArea?.length
                                                ? node.Name[language]
                                                : undefined
                                        }
                                        rootMeta={meta}
                                    />
                                </>
                            )
                        });
                    }
                }
            }

            // console.log('[SmartDetailPage] tabs:', tabs);

            return tabs;
        }, [
            activeTab,
            // dataSource,
            language,
            mainDataFields,
            meta,
            metaChildNodes,
            mode,
            setData,
            changeData,
            t,
            topFields,
            id,
            detailLayout,
            isReload,
            // info,
            model.meta,
            model.data,
            isBigMobile
        ]);

        const handleEdit = useCallback(async () => {
            const lockId = await acquireLock();
            // console.log('[SmartDetailPage] acquire lock id:', lockId);

            setLockId(lockId || null);
            setMode('edit');
        }, [acquireLock, setMode]);

        const handleCancel = useCallback(async () => {
            if (isCreate || isCopy) {
                setMode('view');
                handleClosePage();
                return;
            }

            const lock_id = lockId || metaStore.meta.get(meta)?.acquire_lock?.Id;

            if (lock_id) {
                const released = await releaseLock(lock_id);
                if (released) {
                    await handleRefresh();
                    setMode('view');
                }
            }
            // else {
            // }
        }, [handleClosePage, handleRefresh, isCopy, isCreate, lockId, meta, releaseLock, setMode]);

        const handleSave = useCallback(
            async (saveAndClose?: boolean) => {
                setLoading(true);
                setIsReload(true);

                try {
                    const save = await metaStore.makeSave({
                        meta,
                        objects: [changes]
                    });

                    // ### microframework :) ###
                    executeHandler({ methodName: 'onSave' });
                    // ### !!! ###

                    console.log('[SmartDetailPage] save response:', save);

                    if (save) {
                        const lock_id = lockId || metaStore.meta.get(meta)?.acquire_lock?.Id;
                        if (!isCopy && !isCreate && lock_id) {
                            const release = await releaseLock(lock_id);
                            console.log('[SmartDetailPage] released lock:', release);
                        }

                        // Установка данных с обновлением кэша
                        setData((prevDataSource) => {
                            const newDataSource = { ...prevDataSource, ...save.objects?.[0] };
                            metaStore.add(newDataSource, meta, 'select');

                            if (saveAndClose) {
                                emitter.emit(
                                    `create_${meta}_${location.state?.filterString ?? ''}`,
                                    newDataSource
                                );
                            }

                            // После сохранения при следующем открытии после закрытия не нужно хранить созданные данные
                            if (saveAndClose && isCreate) return { Id: uuid.v4() };
                            if (saveAndClose) return {};
                            return newDataSource;
                        });

                        // Восстановление режима по умолчанию
                        if (isCreate || isCopy) setMode('edit');
                        else setMode('view');

                        // закрываем страницу, если нажали Сохранить и закрыть ИЛИ если мы копировали ИЛИ создавали объект (для переоткрытия с корректными данными)
                        if (saveAndClose || isCreate || isCopy) handleClosePage();

                        // переоткрытие с корректными данными для созданных объектов, если НЕ закрываем при сохранении
                        if (isCreate && !saveAndClose) {
                            storeNavigate(
                                {
                                    pathname: location.pathname.replace(
                                        'new',
                                        save.objects?.[0].Id
                                    ),
                                    search: location.search
                                },
                                { data: save.objects?.[0] },
                                { replace: true }
                            );
                        }

                        if (isCopy && !saveAndClose) {
                            storeNavigate(
                                {
                                    pathname: location.pathname.replace(
                                        'copy',
                                        save.objects?.[0].Id
                                    ),
                                    search: location.search
                                },
                                { data: save.objects?.[0] },
                                { replace: true }
                            );
                        }
                    }
                } catch (error) {
                    setIsReload(false);
                    setLoading(false);

                    throw error;
                } finally {
                    setIsReload(false);
                    setLoading(false);
                }

                // if (!saveAndClose) setLoading(false);
            },
            [
                // dataSource,
                changes,
                handleClosePage,
                isCopy,
                isCreate,
                location.pathname,
                location.search,
                lockId,
                meta,
                releaseLock,
                setData,
                setMode,
                storeNavigate
            ]
        );

        const sortedTabs = tabs.sort((a, b) => sortedTabIndexes[a.key] - sortedTabIndexes[b.key]);

        if (!uiAllowView)
            return (
                <UniversalBoundary
                    status="403"
                    // title={t('no_access') as string}
                    title="403"
                    subTitle={t('no_access_message') as string}
                />
            );

        return (
            <Flex vertical className="smart_detail_page">
                {/* ### Top Bar */}
                <Flex
                    vertical
                    gap={SIZE === 'small' ? 8 : 12}
                    style={{
                        padding: isBigMobile ? '0 5px 5px 5px' : '0 10px 5px 10px',
                        width: '100%'
                    }}
                >
                    {/* ### Top Actions Bar */}
                    {!isNoNeedFetchOnlyRead && (
                        <Flex gap={5}>
                            <SmartDetailPageHeaderToolbar
                                meta={meta}
                                id={dataSource.Id}
                                data={dataSource}
                                mode={mode}
                                onSave={handleSave}
                                onCancel={handleCancel}
                                onEdit={handleEdit}
                                onRefresh={handleRefresh}
                                loading={loading}
                                isDataChanged={Object.keys(changes).length > 2}
                                updatedActions={updatedMeta?.Actions}
                            />
                        </Flex>
                    )}

                    {/* ### Top Fields Bar */}
                    <TopFields fields={topFields} size={SIZE} />
                </Flex>
                {/* ### Content */}
                {/* <Loader status={loading}> */}
                <Loader status={loading}>
                    {isBigMobile ? (
                        <MobileView tabs={sortedTabs} layout={detailLayout?.mobile} />
                    ) : tabs.length === 1 ? (
                        <div
                            style={{
                                padding: '0 10px',
                                maxHeight: '75vh',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}
                        >
                            <Divider style={{ margin: '5px 1px 5px 1px' }} />
                            {tabs[0].children}
                        </div>
                    ) : (
                        <Tabs
                            onChange={setActiveTab}
                            activeKey={activeTab}
                            size={SIZE}
                            style={{ width: '100%' }}
                            type="card"
                            items={sortedTabs}
                        />
                    )}
                </Loader>
            </Flex>
        );
    }
);
