import { t } from 'i18next';
import {
    Badge,
    Button,
    Divider,
    Dropdown,
    Flex,
    Popover,
    Space,
    TableColumnsType,
    Tooltip,
    Typography
} from 'antd';
import { range } from 'lodash';
import dayjs from 'dayjs';
import { isDefined } from 'is-lite/exports';
import React, { CSSProperties } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { toJS } from 'mobx';
import { calculateFreeHoursForResource } from './calculateFreeHoursForResource';
import { DayDataType, MenuItem, Months, Weeks } from './types';
import { getGetFreeHoursByDate } from './getFreeHoursByDate';
import { ColorMarker, ColorMarkerProps } from '../ui';
import { DATE_FORMAT } from '../../../../utils/helpers/dates';
import {
    DocDowntimeEvent,
    DocTrip,
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { IObjectWithId, metaStore } from '../../../../utils/store/MetaStore';
import { fnv1aHash, getNeededMetaRoutePathWithFilters } from '../../../utils';
import { StoreLink } from '../../../../ui';
import { HOURS } from '../constants';

type GetColumnsFunction = (options: {
    daysCount: number;
    selectedDate: dayjs.Dayjs;
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    fetchBookedSlots: () => void;
    language: string;
    baseLanguage: string;
    // actionsMenuItems?: MenuItem[];
    getTripActionsMenuItems: (ids: string[], row: IObjectWithId, args?: PlainObject) => MenuItem[];
    getVisitActionsMenuItems: (ids: string[], row: IObjectWithId, args?: PlainObject) => MenuItem[];
    getDowntimeEventActionsMenuItems: (
        ids: string[],
        row: IObjectWithId,
        args?: PlainObject
    ) => MenuItem[];
    getSlotActionsMenuItems: (ids: string[], row: IObjectWithId, args?: PlainObject) => MenuItem[];
    selectedTripsIds: string[];
    selectedVisitsIds: string[];
    firstColumnFieldName: string;
    meta: string;
    showTripInfoOnSlots?: boolean;
}) => TableColumnsType<DayDataType>;

export const getColumns: GetColumnsFunction = ({
    daysCount,
    selectedDate,
    freeSlots,
    bookedSlots,
    // fetchBookedSlots,
    getTripActionsMenuItems,
    getVisitActionsMenuItems,
    getSlotActionsMenuItems,
    getDowntimeEventActionsMenuItems,
    language,
    baseLanguage,
    selectedTripsIds,
    selectedVisitsIds,
    firstColumnFieldName,
    meta,
    showTripInfoOnSlots
}) => {
    const tripsMeta = 'DocTrips';
    const visitsMeta = 'DocTerminalVisits';
    const downtimeEventsMeta = 'DocDowntimeEvents';

    const metaRoutesMap = metaStore.meta.get('all')?.routesMap;

    const itemsRoute = metaRoutesMap?.get(meta)?.[0];
    // const itemsPathName = itemsRoute?.path?.split('?')[0];
    const { path: itemsPathName, preFilters: itemsPreFilters } =
        getNeededMetaRoutePathWithFilters(meta);

    const tripsRoute = metaRoutesMap?.get(tripsMeta)?.[0];
    // const tripsPathName = tripsRoute?.path?.split('?')[0];
    const { path: tripsPathName, preFilters: tripsPreFilters } =
        getNeededMetaRoutePathWithFilters(tripsMeta);

    const visitsRoute = metaRoutesMap?.get(visitsMeta)?.[0];
    // const tripsPathName = tripsRoute?.path?.split('?')[0];
    const { path: visitsPathName, preFilters: visitsPreFilters } =
        getNeededMetaRoutePathWithFilters(visitsMeta);

    const downtimeEventsRoute = metaRoutesMap?.get(downtimeEventsMeta)?.[0];
    // const downtimeEventsPathName = downtimeEventsRoute?.path?.split('?')[0];
    const { path: downtimeEventsPathName, preFilters: downtimeEventsPreFilters } =
        getNeededMetaRoutePathWithFilters(downtimeEventsMeta);

    const itemsMetaInfo = metaStore.meta.get(meta)?.info;
    const itemsUiAllowView = itemsMetaInfo?.UiAllowView ?? true;

    const tripsMetaInfo = metaStore.meta.get(tripsMeta)?.info;
    const tripsUiAllowView = tripsMetaInfo?.UiAllowView ?? true;

    const visitsMetaInfo = metaStore.meta.get(visitsMeta)?.info;
    const visitsUiAllowView = tripsMetaInfo?.UiAllowView ?? true;

    const downtimeEventsMetaInfo = metaStore.meta.get(downtimeEventsMeta)?.info;
    const downtimeEventsUiAllowView = downtimeEventsMetaInfo?.UiAllowView ?? true;

    const title = itemsMetaInfo?.PluralName?.[language] ?? t('items');
    // const titleSingular = itemsMetaInfo?.SingularName?.[language] ?? itemsMetaInfo?.Code;
    const titleSingular = itemsMetaInfo?.SingularName ?? itemsMetaInfo?.Code;
    // const tripTitleSingular = tripsMetaInfo?.SingularName?.[language] ?? tripsMetaInfo?.Code;
    const tripTitleSingular = tripsMetaInfo?.SingularName ?? tripsMetaInfo?.Code;
    const visitTitleSingular = visitsMetaInfo?.SingularName ?? visitsMetaInfo?.Code;
    const downtimeEventTitleSingular =
        // downtimeEventsMetaInfo?.SingularName?.[language] ?? downtimeEventsMetaInfo?.Code;
        downtimeEventsMetaInfo?.SingularName ?? downtimeEventsMetaInfo?.Code;

    return [
        {
            title,
            // dataIndex: 'resource',
            dataIndex: 'Name',
            // key: 'resource',
            key: 'Name',
            fixed: 'left',
            width: 300,
            // responsive,
            ellipsis: true,
            render: (title, { Id, Key, Name, Meta, ItemId, Trip, DowntimeEvent, Item }) => {
                const isTripMeta = Meta === tripsMeta;
                const isVisitMeta = Meta === visitsMeta;
                const isDowntimeEventMeta = Meta === downtimeEventsMeta;
                // console.log("[SmartTimelineTable] for slot's count data:", groupedById);
                // считаем количество свободный для букирования дней у ресурса
                const freeSlotsCount = calculateFreeHoursForResource(
                    freeSlots,
                    ItemId,
                    daysCount,
                    selectedDate,
                    firstColumnFieldName
                );

                // считаем количество уже занятых слотов ресурса
                // !CHANGED
                const bookedSlotsCount = bookedSlots.filter(
                    (bs) =>
                        bs[firstColumnFieldName]?.Id === ItemId &&
                        (!isTripMeta || bs.Trip?.Id === Id) &&
                        (!isVisitMeta || bs.Visit?.Id === Id) &&
                        (!isDowntimeEventMeta || bs.DowntimeEvent?.Id === Id)
                ).length;

                let pathname;
                let filterString;
                if (isDowntimeEventMeta && downtimeEventsRoute) {
                    pathname = `${downtimeEventsPathName}/${Id}`;
                    filterString = downtimeEventsPreFilters;
                } else if (isTripMeta && tripsRoute) {
                    pathname = `${tripsPathName}/${Id}`;
                    filterString = tripsPreFilters;
                } else if (isVisitMeta && visitsRoute) {
                    pathname = `${visitsPathName}/${Id}`;
                    filterString = visitsPreFilters;
                } else if (itemsRoute) {
                    pathname = `${itemsPathName}/${Id}`;
                    filterString = itemsPreFilters;
                }

                let singularName = titleSingular;

                if (isDowntimeEventMeta) singularName = downtimeEventTitleSingular;
                else if (isTripMeta) singularName = tripTitleSingular;
                else if (isVisitMeta) singularName = visitTitleSingular;

                const metaCode = isDowntimeEventMeta
                    ? downtimeEventsMeta
                    : isTripMeta
                    ? tripsMeta
                    : isVisitMeta
                    ? visitsMeta
                    : meta;

                const state = {
                    // pageTitle: `${Name} (${singularName})`,
                    filterString,
                    pageTitle: toJS(Name),
                    extraPageTitle: toJS(singularName),
                    data: { Id, Key },
                    cacheKey: fnv1aHash(`${metaCode}_${Id}_view`)
                };

                const link =
                    pathname && itemsUiAllowView ? (
                        <StoreLink style={{ padding: 0 }} to={pathname} state={state}>
                            {title?.[language === 'tech' ? baseLanguage : language] ??
                                title?.[baseLanguage] ??
                                title}
                        </StoreLink>
                    ) : (
                        title?.[language === 'tech' ? baseLanguage : language] ??
                        title?.[baseLanguage] ??
                        title
                    );

                return (
                    // <Dropdown
                    //     menu={
                    //         isTripMeta
                    //             ? { items: getTripActionsMenuItems([Id], Trip) }
                    //             : isDowntimeEventMeta
                    //             ? { items: getDowntimeEventActionsMenuItems([Id], DowntimeEvent) }
                    //             : undefined
                    //     }
                    //     trigger={['contextMenu']}
                    //     placement="bottomLeft"
                    //     destroyPopupOnHide
                    // >
                    <Flex
                        className={'smart_timeline_table__resource_cell'}
                        align="center"
                        justify="space-between"
                    >
                        <span className={'smart_timeline_table__resource_cell_link'}>{link}</span>

                        <Flex gap={5} justify="flex-end" style={{ width: 70 }}>
                            {!isVisitMeta && !isTripMeta && !isDowntimeEventMeta && (
                                <Flex
                                    gap={3}
                                    align="center"
                                    justify="flex-end"
                                    style={{ width: '50%' }}
                                >
                                    {freeSlotsCount}
                                    <ColorMarker status="free" />
                                </Flex>
                            )}
                            <Flex
                                gap={3}
                                align="center"
                                justify="flex-end"
                                style={{ width: '50%' }}
                            >
                                {bookedSlotsCount}
                                <ColorMarker status="filled" />
                            </Flex>
                        </Flex>
                    </Flex>
                    // </Dropdown>
                );
            }
        },
        ...range(0, daysCount).map((dayIndex) => {
            const showDate = selectedDate.local().add(dayIndex, 'days');
            const showDateString = showDate.format(DATE_FORMAT);

            return {
                title: `${showDate.date()} ${t(Months[showDate.month()])} ${showDate.year()} (${t(
                    Weeks[showDate.day()]
                )})`,
                children: HOURS.map((hour) => {
                    return {
                        title: hour % 2 === 0 ? `${hour}:00` : '',
                        dataIndex: hour,
                        key: hour,
                        width: 55,
                        align: 'center',
                        render: (
                            hour: number,
                            {
                                Id,
                                CapacityCount,
                                Meta,
                                ItemId,
                                TripId,
                                DowntimeEventId,
                                VisitId,
                                TripIndex
                            }: DayDataType
                        ) => {
                            const isTripsMeta = Meta === tripsMeta;
                            const isVisitsMeta = Meta === tripsMeta;
                            const isDowntimeEventMeta = Meta === downtimeEventsMeta;

                            const bookedSlotsData = bookedSlots.filter(
                                (slot) =>
                                    slot[firstColumnFieldName]?.Id === ItemId &&
                                    String(slot.SlotDate) === showDateString &&
                                    slot.SlotIndex === hour &&
                                    (!TripId || slot.Trip?.Id === TripId) && // если строка размернута и относится к рейсу
                                    (!DowntimeEventId ||
                                        slot.DowntimeEvent?.Id === DowntimeEventId) && // если строка размернута и относится к евенту
                                    (!VisitId || slot.Visit?.Id === VisitId) // если строка размернута и относится к евенту
                            );

                            const slotFillPercent = Math.max(
                                ...bookedSlotsData.map((s) => s.SlotPercent)
                            );
                            const isSlotStart = bookedSlotsData?.[0]?.IsSlotStart;
                            const isSlotEnd = bookedSlotsData?.[0]?.IsSlotEnd;

                            const bookedCapacity = bookedSlotsData.reduce((acc, bs) => {
                                if (isDefined(bs.CapacityCount))
                                    return acc + (bs.CapacityCount ?? 0);

                                return 1;
                            }, 0);

                            const cellColor = bookedSlotsData?.[0]?.Status?.Color; // цвет первого рейса
                            const border: CSSProperties['border'] = '2px solid red';

                            let showSelectedBorder = false;

                            let totalWeightValue = 0;

                            const tripLinks = bookedSlotsData
                                .filter((bs) => bs.Trip)
                                .map((bs) => {
                                    // console.log(bs);

                                    const trip = bs.Trip as DocTrip;
                                    const tripId = trip.Id;
                                    const tripLabel = trip.Key;

                                    const status = bs.Status;
                                    const color = status?.Color ?? 'lightgray';
                                    const statusName =
                                        status?.Name?.[
                                            language === 'tech' ? baseLanguage : language
                                        ] ?? status?.Name?.[baseLanguage];

                                    showSelectedBorder = selectedTripsIds.includes(tripId ?? ''); // выделяем рамкой если рейс выбран в отборе ИЛИ если это развернутая строка с рейсом
                                    totalWeightValue += trip.TotalWeightValue ?? 0;

                                    const to = `${tripsPathName}/${tripId}`;

                                    return (
                                        <Flex
                                            gap={8}
                                            align="center"
                                            key={tripId}
                                            style={{ padding: '5px 11px' }}
                                        >
                                            <Tooltip title={statusName}>
                                                <Badge color={color} />
                                            </Tooltip>
                                            {tripsRoute && tripsUiAllowView ? (
                                                <StoreLink
                                                    key={tripId}
                                                    style={{ padding: 0 }}
                                                    to={to}
                                                    state={{
                                                        data: trip,
                                                        filterString: tripsPreFilters
                                                    }}
                                                >
                                                    {tripLabel}
                                                </StoreLink>
                                            ) : (
                                                tripLabel
                                            )}
                                            <Dropdown
                                                menu={{
                                                    items: getTripActionsMenuItems([tripId], trip, {
                                                        service_id: bs.LocationResource?.Service,
                                                        location_resource_id: bs.LocationResource
                                                    })
                                                }}
                                                trigger={['click']}
                                                placement="bottomLeft"
                                                destroyPopupOnHide
                                            >
                                                <Button type="text" style={{ marginLeft: 'auto' }}>
                                                    <MoreOutlined />
                                                </Button>
                                            </Dropdown>
                                        </Flex>
                                    );
                                });

                            const visitLinks = bookedSlotsData
                                .filter((bs) => bs.Visit)
                                .map((bs) => {
                                    // console.log(bs);

                                    const visit = bs.Visit as DocTrip;
                                    const visitId = visit.Id;
                                    const visitLabel = visit.Key;

                                    const status = bs.Status;
                                    const color = status?.Color ?? 'lightgray';
                                    const statusName =
                                        status?.Name?.[
                                            language === 'tech' ? baseLanguage : language
                                        ] ?? status?.Name?.[baseLanguage];

                                    showSelectedBorder = selectedVisitsIds.includes(visitId ?? ''); // выделяем рамкой если рейс выбран в отборе ИЛИ если это развернутая строка с рейсом
                                    totalWeightValue += visit.TotalWeightValue ?? 0;

                                    const to = `${visitsPathName}/${visitId}`;

                                    return (
                                        <Flex
                                            gap={8}
                                            align="center"
                                            key={visitId}
                                            style={{ padding: '5px 11px' }}
                                        >
                                            <Tooltip title={statusName}>
                                                <Badge color={color} />
                                            </Tooltip>
                                            {visitsRoute && visitsUiAllowView ? (
                                                <StoreLink
                                                    key={visitId}
                                                    style={{ padding: 0 }}
                                                    to={to}
                                                    state={{
                                                        data: visit,
                                                        filterString: visitsPreFilters
                                                    }}
                                                >
                                                    {visitLabel}
                                                </StoreLink>
                                            ) : (
                                                visitLabel
                                            )}
                                            <Dropdown
                                                menu={{
                                                    items: getVisitActionsMenuItems(
                                                        [visitId],
                                                        visit
                                                        // {
                                                        //     service_id:
                                                        //         bs.LocationResource?.Service,
                                                        //     location_resource_id:
                                                        //         bs.LocationResource
                                                        // }
                                                    )
                                                }}
                                                trigger={['click']}
                                                placement="bottomLeft"
                                                destroyPopupOnHide
                                            >
                                                <Button type="text" style={{ marginLeft: 'auto' }}>
                                                    <MoreOutlined />
                                                </Button>
                                            </Dropdown>
                                        </Flex>
                                    );
                                });

                            const downtimeEventLinks = bookedSlotsData
                                .filter((bs) => bs.DowntimeEvent)
                                .map((bs) => {
                                    const downtimeEvent = bs.DowntimeEvent as DocDowntimeEvent;
                                    const downtimeEventId = downtimeEvent.Id;
                                    const downtimeEventLabel = downtimeEvent.Key;

                                    const status = bs.Status;
                                    const color = status?.Color ?? 'lightgray';
                                    const statusName =
                                        status?.Name?.[
                                            language === 'tech' ? baseLanguage : language
                                        ] ?? status?.Name?.[baseLanguage];

                                    const to = `${downtimeEventsPathName}/${downtimeEventId}`;

                                    return (
                                        <Flex
                                            gap={8}
                                            align="center"
                                            key={downtimeEventId}
                                            style={{ padding: '5px 11px' }}
                                        >
                                            <Tooltip title={statusName}>
                                                <Badge color={color} />
                                            </Tooltip>
                                            {downtimeEventsRoute && downtimeEventsUiAllowView ? (
                                                <StoreLink
                                                    key={downtimeEventId}
                                                    style={{ padding: 0 }}
                                                    to={to}
                                                    state={{
                                                        data: downtimeEvent,
                                                        filterString: downtimeEventsPreFilters
                                                    }}
                                                >
                                                    {downtimeEventLabel}
                                                </StoreLink>
                                            ) : (
                                                downtimeEventLabel
                                            )}
                                            <Dropdown
                                                menu={{
                                                    items: getDowntimeEventActionsMenuItems(
                                                        [downtimeEventId],
                                                        downtimeEvent
                                                    )
                                                }}
                                                trigger={['click']}
                                                placement="bottomLeft"
                                                destroyPopupOnHide
                                            >
                                                <Button style={{ marginLeft: 'auto' }} type="text">
                                                    <MoreOutlined />
                                                </Button>
                                            </Dropdown>
                                        </Flex>
                                    );
                                });

                            // если мы смотрим на рейс, то пропускаем вычисление всех слотов
                            let status: ColorMarkerProps['status'] = 'closed';
                            if (isVisitsMeta || isTripsMeta || isDowntimeEventMeta) {
                                status = 'closed';
                            } else if (
                                getGetFreeHoursByDate()(freeSlots, ItemId, firstColumnFieldName)
                                    .get(showDateString)
                                    ?.includes(hour)
                            ) {
                                status = 'free';
                            }

                            if (bookedCapacity > 0) status = 'filled';

                            // const capacityDiff = CapacityCount - bookedCapacity;
                            // const slotInfo = `${t('slot')} ${hour + 1} (${hour}:00-${hour + 1}:00)`;
                            const slotInfo = `${t('slot')} ${hour}:00-${hour + 1}:00`;
                            const bottomText = `${t('busy')} ${bookedCapacity}/${CapacityCount}`;
                            const hasText =
                                showTripInfoOnSlots &&
                                (bookedCapacity > 1 ||
                                    ((isVisitsMeta || isTripsMeta) && status === 'filled'));
                            const slotStartTime = showDate
                                .set('hours', hour)
                                .set('minutes', 0)
                                .set('seconds', 0)
                                .set('milliseconds', 0)
                                .format();
                            const slotEndTime = showDate
                                .set('hours', hour + 1)
                                .set('minutes', 0)
                                .set('seconds', 0)
                                .set('milliseconds', 0)
                                .format();

                            // const blockHandler = async (capacity_count: 1 | -1) => {
                            //     await metaStore.makeRun({
                            //         Action_Id: '',
                            //         meta: 'CatLocationResources',
                            //         ids: [ResourceId],
                            //         handler: 'CatLocationResources.BookSlot',
                            //         args: {
                            //             planning_date: selectedDate.format(DATE_FORMAT),
                            //             slot_index: hour,
                            //             capacity_count
                            //         }
                            //     });
                            //
                            //     fetchBookedSlots();
                            // };
                            //
                            // const menuItems: MenuProps['items'] = [
                            //     {
                            //         key: 0,
                            //         label: t('block_slot'),
                            //         onClick: () => blockHandler(1),
                            //         disabled: status === 'closed'
                            //     },
                            //     {
                            //         key: 1,
                            //         label: t('unblock_slot'),
                            //         onClick: () => blockHandler(-1),
                            //         disabled: status === 'closed' || status === 'free'
                            //     }
                            // ];

                            const driverInfos = [];

                            for (const bs of bookedSlotsData) {
                                const driver =
                                    bs.Trip?.Driver?.Name?.[
                                        language === 'tech' ? baseLanguage : language
                                    ] ?? bs.Trip?.Driver?.Name?.[baseLanguage];
                                const vehicle =
                                    bs.Trip?.FirstVehicle?.Name?.[
                                        language === 'tech' ? baseLanguage : language
                                    ] ?? bs.Trip?.FirstVehicle?.Name?.[baseLanguage];

                                const info = `${driver ?? ''}${vehicle ? ` / ${vehicle}` : ''}`;

                                if (info) driverInfos.push(info);
                            }

                            const hasDriverInfos = !!driverInfos?.length;

                            return (
                                <Dropdown
                                    dropdownRender={(menu) => {
                                        return (
                                            <div className={'smart_timeline_table__slot_context'}>
                                                <Typography.Text strong>{slotInfo}</Typography.Text>
                                                <Divider style={{ margin: '5px 0' }} />
                                                {/* {tripLink} */}
                                                {status === 'filled' ||
                                                status === 'partial_filled' ? (
                                                    <>
                                                        <Flex vertical>
                                                            {!!tripLinks.length && tripLinks}
                                                            {!!visitLinks.length && visitLinks}
                                                            {!!downtimeEventLinks.length &&
                                                                downtimeEventLinks}
                                                        </Flex>
                                                        <Divider style={{ margin: '5px 0' }} />
                                                    </>
                                                ) : null}
                                                {React.cloneElement(menu as React.ReactElement, {
                                                    style: {
                                                        backgroundColor: 'transparent',
                                                        boxShadow: 'none',
                                                        padding: 0
                                                    }
                                                })}
                                                {status !== 'closed' ? (
                                                    <>
                                                        <Divider style={{ margin: '5px 0' }} />
                                                        <Typography.Text>
                                                            {bottomText}
                                                        </Typography.Text>
                                                    </>
                                                ) : null}
                                            </div>
                                        );
                                    }}
                                    menu={{
                                        items: getSlotActionsMenuItems(
                                            [ItemId],
                                            bookedSlotsData?.[0],
                                            {
                                                start_time: slotStartTime,
                                                end_time: slotEndTime
                                            }
                                        )
                                    }}
                                    trigger={['contextMenu']}
                                    destroyPopupOnHide
                                    getPopupContainer={() =>
                                        document.querySelector(
                                            '.smart_timeline_table'
                                        ) as HTMLElement
                                    }
                                >
                                    <Popover
                                        trigger={'click'}
                                        content={
                                            hasDriverInfos ? (
                                                <Flex>{driverInfos.join(', ')}</Flex>
                                            ) : undefined
                                        }
                                    >
                                        <div>
                                            <ColorMarker
                                                border={showSelectedBorder ? border : undefined}
                                                status={status}
                                                color={cellColor}
                                                width={`${slotFillPercent}%`}
                                                text={
                                                    hasText
                                                        ? totalWeightValue > 999
                                                            ? `${Math.round(
                                                                  totalWeightValue / 1000
                                                              )} т`
                                                            : `${Math.round(totalWeightValue)} кг`
                                                        : ''
                                                }
                                                isContentStart={isSlotStart}
                                                isContentEnd={isSlotEnd}
                                                // text={hasText ? capacityDiff : ''}
                                            />
                                        </div>
                                    </Popover>
                                </Dropdown>
                            );
                        }
                    };
                })
            };
        })
    ];
};
