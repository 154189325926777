import { LockOutlined, SaveOutlined, UnlockOutlined } from '@ant-design/icons';
import { Flex, Button, Tooltip, Checkbox, Space } from 'antd';
import { MultilanguageValueType } from 'components/fields';
import { useRef, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalState, useAsync, useMedia } from 'react-use';
import { useLocation } from 'react-router-dom';

import { SmartMultilanguageField, SmartSelectField } from 'smart/components';
import { FieldsBox } from 'smart/modules/SmartDetailPage/ui';
import { fnv1aHash, JSONSafeParse } from 'smart/utils';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';

import { useUserData } from 'modules/client/useAuthUser';
import { InfoSavedQuery } from 'modules/services/backend-api/generated_models';
import { FilterField } from '../../types';

const useSelectedQueriesByLocation = createGlobalState(
    new Map<number, InfoSavedQuery | undefined>()
);

export const useSelectedQueries = (): [
    InfoSavedQuery | undefined,
    (newQuery: InfoSavedQuery | undefined) => void
] => {
    const location = useLocation();

    const [selectedQueryMap, setSelectedQueryMap] = useSelectedQueriesByLocation();

    const queryHashKey = useMemo(
        () => fnv1aHash(`${location.pathname}_${JSON.stringify(location.state ?? {})}`),
        [location.pathname, location.state]
    );

    const selectedQuery = useMemo(
        () => selectedQueryMap.get(queryHashKey),
        [queryHashKey, selectedQueryMap]
    );

    const setSelectedQuery = useCallback(
        (newQuery: InfoSavedQuery | undefined) => {
            const map = new Map(selectedQueryMap);
            map.set(queryHashKey, newQuery);

            setSelectedQueryMap(map);
        },
        [queryHashKey, selectedQueryMap]
    );

    return [selectedQuery, setSelectedQuery];
};

export const SavedQueryControl = ({
    filterString,
    onChangeFilterString,
    filters,
    onChangeFilters,
    meta
}: {
    filterString: string;
    onChangeFilterString: (newFilterString: string | undefined) => void;
    filters: FilterField[];
    onChangeFilters: (newFilters: FilterField[]) => void;
    meta: string;
}) => {
    const { pathname, state } = useLocation();
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { modal } = useNotifications();
    const { data, isPowerUser, loading } = useUserData();
    const isBigMobile = useMedia('(max-width: 480px)');
    const isMiddleTablet = useMedia('(max-width: 720px)');

    const userId = data?.Id;

    // console.log('filterString:', filterString);
    // console.log('filters:', filters);

    const navItem = useAsync(
        async () =>
            (
                await metaStore.makeSelect({
                    meta: 'InfoNavItems',
                    filters: `Path=eq.${`${pathname}${
                        state?.filterString ? `?${state?.filterString}` : ''
                    }`}`
                })
            )?.objects?.[0],
        []
    );

    const newQueryName = useRef<MultilanguageValueType>({ ru: '' });
    const newQueryIsFixed = useRef(false);

    const [selectedQuery, setSelectedQuery] = useSelectedQueries();

    const [queryLoading, setQueryLoading] = useState(false);

    // useEffect(() => {
    //     console.log('selectedQuery:', selectedQuery);
    //     debugger;
    // }, [selectedQuery]);

    const selectedQueryFilters = selectedQuery?.FilterData;
    const selectedQueryFilterString = selectedQuery?.Filter;

    const isEditedQuery =
        selectedQuery &&
        (selectedQuery?.User?.Id === userId || (selectedQuery?.IsPublic && isPowerUser)) &&
        JSON.stringify(filters) !== JSON.stringify(selectedQueryFilters);
    const isNewQuery = !selectedQuery && filterString;

    const handleSaveQuery = () => {
        modal.confirm({
            title: t('fill_for_save'),
            width: isBigMobile ? '98%' : isMiddleTablet ? '75%' : '50%',
            content: (
                <FieldsBox
                    labelWidth={160}
                    items={[
                        {
                            label: t('name'),
                            span: 2,
                            children: (
                                <SmartMultilanguageField
                                    value={undefined as unknown as MultilanguageValueType}
                                    onChange={(v) => {
                                        newQueryName.current = v;
                                    }}
                                />
                            )
                        },
                        {
                            label: t(
                                'is_user_fixed',
                                language === 'ru' ? 'Зафиксировано' : 'Is fixed'
                            ),
                            span: 2,
                            children: (
                                <Checkbox
                                    onChange={(e) => {
                                        newQueryIsFixed.current = e.target.checked;
                                    }}
                                />
                            )
                        }
                    ]}
                    size={'small'}
                />
            ),
            centered: true,
            destroyOnClose: true,
            okText: t('save'),
            cancelText: t('cancel'),
            onOk: async () => {
                setQueryLoading(true);
                const objectToSave = {
                    Filter: filterString,
                    FilterData: filters,
                    // isActive: true,
                    IsActive: true,
                    IsUserFixed: newQueryIsFixed.current,
                    Name: newQueryName.current,
                    Meta: { Code: meta },
                    NavItem: navItem.value ? { Id: navItem.value.Id } : undefined
                };

                if (data) objectToSave.User = data;

                const payload = {
                    meta: 'InfoSavedQueries',
                    objects: [objectToSave]
                };

                const save = await metaStore.makeSave(payload);
                const query = save?.objects?.[0] as InfoSavedQuery;

                setSelectedQuery(query);
                setQueryLoading(false);
            }
        });
    };

    const handleReSaveQuery = async () => {
        setQueryLoading(true);
        const objectToSave = {
            ...selectedQuery,
            Filter: filterString,
            FilterData: filters
        };

        const payload = {
            meta: 'InfoSavedQueries',
            objects: [objectToSave]
        };

        const save = await metaStore.makeSave(payload);
        const query = save?.objects?.[0] as InfoSavedQuery;

        // console.log('after save', query);

        setSelectedQuery(query);
        setQueryLoading(false);
    };

    const handleLockUnlockQuery = async () => {
        setQueryLoading(true);
        const objectToSave = {
            ...selectedQuery,
            IsUserFixed: !selectedQuery?.IsUserFixed
        };

        // console.log('before save', objectToSave);

        const payload = {
            meta: 'InfoSavedQueries',
            objects: [objectToSave]
        };

        const save = await metaStore.makeSave(payload);
        const query = save?.objects?.[0] as InfoSavedQuery;

        // console.log('after save', query);

        // setSelectedQuery(query);
        setSelectedQuery(objectToSave);
        setQueryLoading(false);
    };

    // console.log('state', selectedQuery);

    // useEffect(() => {
    //     if (selectedQuery) {
    //         onChangeFilterString(selectedQueryFilterString);
    //         onChangeFilters((JSONSafeParse(selectedQueryFilters) as FilterField[]) ?? filters);
    //     }
    // }, [selectedQuery]);

    const buttonForSaveNew = (
        <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleSaveQuery}
            loading={loading || navItem.loading || queryLoading}
        >
            {t('save_query')}
        </Button>
    );

    const buttonForEditCurrent = (
        <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleReSaveQuery}
            loading={loading || navItem.loading || queryLoading}
        >
            {t('re_save_query')}
        </Button>
    );

    const buttonForLockUnlockCurrent =
        !isNewQuery && selectedQuery ? (
            <Button
                // type="primary"
                icon={selectedQuery?.IsUserFixed ? <LockOutlined /> : <UnlockOutlined />}
                onClick={handleLockUnlockQuery}
                loading={loading || navItem.loading || queryLoading}
                className={selectedQuery?.IsUserFixed ? 'meta_nav_button' : undefined}
                // disabled={selectedQuery?.IsUserFixed}
            >
                {/* {selectedQuery?.IsUserFixed ? t('unlock') : t('lock')} */}
            </Button>
        ) : null;

    const button = isNewQuery ? buttonForSaveNew : isEditedQuery ? buttonForEditCurrent : null;

    // const inputControl = (
    //     <Flex gap={5}>
    //         <StringField readOnly value={filterString} onChange={onChangeFilterString} allowClear />
    //         {button}
    //     </Flex>
    // );

    const queryFilter = `Meta=eq.${meta}${
        navItem.value ? `&or(NavItem=eq.${navItem.value.Id},NavItem=is.null)` : '&NavItem=is.null'
    }${userId ? `&or(User=eq.${userId},IsPublic=eq.true)` : '&IsPublic=eq.true'}`;

    // console.log(userId);

    const selectControl = (
        <Flex gap={5}>
            <Space.Compact style={{ width: '100% ' }}>
                <SmartSelectField
                    value={selectedQuery}
                    meta={'InfoSavedQueries'}
                    navigateMetaOptions={{
                        metaName: 'InfoSavedQueries'
                    }}
                    filters={queryFilter}
                    loading={loading || queryLoading}
                    disabled={loading}
                    onChange={(newVal) => {
                        // console.log('newVal:', newVal);
                        setSelectedQuery(newVal);

                        if (newVal) {
                            const selectedQueryFilters = newVal?.FilterData;
                            const selectedQueryFilterString = newVal?.Filter;

                            onChangeFilterString(selectedQueryFilterString);
                            onChangeFilters(
                                (JSONSafeParse(selectedQueryFilters) as FilterField[]) ?? filters
                            );
                        }
                    }}
                    returnFullObject={true}
                />
                {buttonForLockUnlockCurrent}
            </Space.Compact>
            {button}
        </Flex>
    );

    // const content = isNewQuery ? inputControl : selectControl;
    const content = selectControl;

    return (
        <Tooltip
            title={filterString}
            getPopupContainer={() => document.getElementById('table_filter_menu') as HTMLElement}
            overlayStyle={{ maxWidth: '90%' }}
        >
            <Flex vertical gap={5}>
                <span style={{ color: 'gray' }}>{t('saved_query_id')}:</span>
                {content}
            </Flex>
        </Tooltip>
    );
};
