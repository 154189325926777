import { forwardRef, useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import * as XLSX from 'xlsx';
import { isArray } from 'is-lite/exports';
import { Flex, Typography } from 'antd';

import './ExcelField.scss';

interface ExcelCellType {
    className?: string;
    value: string | number | null | undefined;
}

interface ExcelFileProps {
    file: ArrayBuffer | Array<ExcelCellType[]>;
    uiTableTitle?: string;
    className?: string;
    hideIndicators?: boolean;
    withForceHeader?: boolean;
    centered?: boolean;
}

export const ExcelField = forwardRef<HTMLDivElement, ExcelFileProps>(
    (
        { file, uiTableTitle, className, hideIndicators, withForceHeader = false, centered = true },
        ref
    ) => {
        const [data, setData] = useState<ExcelCellType[][]>([]);
        const type = isArray(file) ? 'array' : 'buffer';

        useEffect(() => {
            if (type === 'array') {
                // console.log('sheet data', file);

                setData(file as Array<ExcelCellType[]>);
            } else {
                // console.log(file);
                const workbook = XLSX.read(file, { type });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);

                // if (withTransformation) {
                console.log('xlsx data:', data);

                const maxFields = Math.max(...data.map((obj) => Object.keys(obj).length));

                const transformedData: ExcelCellType[][] = [];

                if (withForceHeader) {
                    if (data.length > 0) {
                        const exampleRow = data.find(
                            (item) => Object.keys(item).length === maxFields
                        );

                        const headerRows = Object.keys(exampleRow as Record<string, any>).map(
                            (key) => {
                                const excelCell: ExcelCellType = {
                                    value: key,
                                    className: 'excel_header_field'
                                };
                                return excelCell;
                            }
                        ) as ExcelCellType[];

                        transformedData.push([...headerRows]);
                    }
                }
                transformedData.push(
                    ...data.map((item) => {
                        const entries = Object.entries(item);
                        const transformedEntries = entries.map(
                            ([key, value]) => ({ value }) as ExcelCellType
                        );

                        // Заполняем недостающие позиции объектами с пустым значением
                        while (transformedEntries.length < maxFields) {
                            transformedEntries.push({ value: '' });
                        }

                        return transformedEntries;
                    })
                );

                setData(transformedData);
                // } else {
                //     setData(data);
                // }

                // console.log('sheet data', transformedData);
            }
        }, [file, type]);

        return (
            <div ref={ref} className={`excel_field ${className}`}>
                <Flex vertical justify={'center'}>
                    {uiTableTitle && (
                        <Typography.Text
                            strong
                            style={{
                                padding: 5,
                                marginTop: 1,
                                display: 'block',
                                width: '100%',
                                backgroundColor: 'lightgray'
                            }}
                        >
                            {uiTableTitle}
                        </Typography.Text>
                    )}
                    <Spreadsheet
                        hideColumnIndicators={hideIndicators}
                        hideRowIndicators={hideIndicators}
                        className={centered ? 'excel_field__inner_centered' : 'excel_field__inner'}
                        data={data}
                    />
                </Flex>
            </div>
        );
    }
);
