import { createGlobalState } from 'react-use';
import { metaStore } from 'utils/store/MetaStore';
import { useEffect, useState } from 'react';
import { DctLanguage, InfoUser } from 'modules/services/backend-api/generated_models';
import { Meta } from 'modules/services/backend-api/generated_info';
import { toJS } from 'mobx';
import { TypeAuth, UseAuthUser, User } from './types';

const useGlobalUserData = createGlobalState<User['data'] | null>(null);

export const useAuthUser = (): UseAuthUser => {
    // const [user, setUser] = useState<User['data'] | null>(null);
    const [user, setUser] = useGlobalUserData();
    const [loading, setLoading] = useState(false);
    const [isPowerUser, setIsPowerUser] = useState(false);
    const [provider, setProvider] = useState<TypeAuth | null>(null);

    useEffect(() => {
        const initAuthUser = async () => {
            setLoading(true);

            // debugger;

            try {
                // Получаем пользователя и провайдера из metaStore.api.client

                if (!user) {
                    const { data: clientUser, error } = await metaStore?.api?.client?.getUser();
                    if (error) throw error;
                    setUser(clientUser);

                    const authProvider = metaStore?.api?.client?.authProvider;
                    setProvider(authProvider);

                    // Если провайдер `supabase`, проверяем роль "Admin"
                    if (clientUser?.id) {
                        const isAdmin = await isRoleExists(clientUser.id, 'Admin');
                        setIsPowerUser(isAdmin);
                    }
                } else if (user?.id) {
                    const isAdmin = await isRoleExists(user.id, 'Admin');
                    setIsPowerUser(isAdmin);
                }
            } catch (error) {
                // console.error('Ошибка при получении пользователя:', error);
            } finally {
                setLoading(false);
            }
        };

        initAuthUser();
    }, [metaStore?.api?.client?.authProvider, user]); // перезапускается при изменении провайдера

    return { user, loading, isPowerUser, provider, setUser };
};

export const useUserData = () => {
    const [data, setData] = useState<InfoUser | null>(null);
    const [info, setInfo] = useState<Meta | null>(null);
    const [loading, setLoading] = useState(false);
    const { user, isPowerUser, loading: loadingSup } = useAuthUser();

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            const info =
                toJS(metaStore.meta.get('InfoUsers')?.info) ??
                (await metaStore.getInfo('InfoUsers'));

            const dataFromStore = toJS(metaStore.meta.get('InfoUsers')?.select);
            // console.log(dataFromStore);

            const filters = `Id=eq.${user?.id}`;

            const data =
                dataFromStore?.filters === filters
                    ? dataFromStore
                    : await metaStore.makeSelect({
                        //   meta: 'InfoProfiles',
                          meta: 'InfoUsers',
                          filters
                      });

            // console.log(data);

            if (info) setInfo(info);

            if (data && data?.objects.length > 0) {
                const userData = data?.objects[0] as InfoUser;

                if (!userData.Language) {
                    let defaultLanguage = metaStore.meta.get('all')?.params?.DEFAULT_LANGUAGE;
                    if (!defaultLanguage) {
                        defaultLanguage = await metaStore.getParam({
                            param_name: 'DEFAULT_LANGUAGE'
                        });
                    }
                    userData.Language = defaultLanguage as unknown as DctLanguage;
                }

                setData(userData);
            }

            setLoading(false);
        };

        if (user && user.id) init();
    }, [user, user?.id]);

    return { data: data || user, info, isPowerUser, loading: loading || loadingSup };
};

export const isRoleExists = async (userUuid: string, role = 'Admin') => {
    let userId = '';

    const infoUsersFilter = `Id=eq.${userUuid}`;

    const cachedInfoUsers = metaStore.meta.get('InfoUsers')?.select;

    // let response = await window.api.select({
    let response =
        cachedInfoUsers?.filters === infoUsersFilter
            ? cachedInfoUsers
            : await metaStore.makeSelect({
                  meta: 'InfoUsers',
                  filters: infoUsersFilter
              });

    if (response && response.objects.length > 0) {
        userId = response.objects[0].Id;
    }

    if (!userId) return false;

    const infoUserRolesFilter = `Role=eq.${role}&User=eq.${userId}`;

    const cachedInfoUserRoles = metaStore.meta.get('InfoUserRoles')?.select;

    // response = await window.api.select({
    response =
        cachedInfoUserRoles?.filters === infoUserRolesFilter
            ? cachedInfoUserRoles
            : await metaStore.makeSelect({
                  meta: 'InfoUserRoles',
                  filters: infoUserRolesFilter
              });

    return response && response.objects.length > 0;
};
