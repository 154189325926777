import { i18n } from 'utils/i18n/i18n';
import { metaStore } from 'utils/store/MetaStore';
import { t } from 'i18next';
import { camelize } from 'utils';
import { fnv1aHash } from './fnv1aHash';
import { getDetailPageTitle } from './getDetailPageTitle';

export const getNeededMetaRoutePathWithFilters = (metaCode: string | undefined) => {
    let path;
    let preFilters;
    let cacheKey;
    let pageTitle;

    if (metaCode) {
        const metaRoutesMap = metaStore.meta.get('all')?.routesMap;
        const metaRoutes = metaRoutesMap?.get(metaCode);
        const metaRoutesCount = metaRoutes?.length ?? 0;

        if (!metaRoutes || metaRoutesCount === 0) {
            path = `/other/${metaCode}`;
        } else if (metaRoutesCount > 1) {
            path = `/other/${metaCode}`;
        } else {
            const route = metaRoutes[0];
            const menu = metaStore.meta.get('all')?.menuMap?.get(route.id) ?? [];
            if (menu.length === 1) {
                const item = menu[0];
                pageTitle = item.name ?? t(camelize(item?.name?.en || ''));
                cacheKey = fnv1aHash(`${item.id}_${item.name?.[i18n.language]}`);
            }
            const [zeroPath, filterString] = route.path.split('?');
            path = `${zeroPath}`;
            preFilters = filterString;
        }
    }

    return {
        path: path?.endsWith('/') ? path.slice(-1) : path,
        preFilters,
        cacheKey,
        pageTitle,
        getDetailPageState: (row) => {
            const { pageTitle, extraPageTitle } = getDetailPageTitle({
                pathname: path,
                state: { filterString: preFilters },
                data: row,
                meta: metaCode
            });

            const state = {
                ...window.history.state,
                cacheKey: fnv1aHash(`${metaCode}_${row.Id}_view`),
                data: row,
                pageTitle,
                extraPageTitle
            };

            return state;
        }
    };
};
