import { Flex, Input } from 'antd';
import { isUndefined, isObject, isEmpty, isDefined } from 'is-lite/exports';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { ColumnOptionsType } from 'smart/utils';
import { IObjectWithId } from 'utils/store/MetaStore';

import { SmartSelectField } from '../SmartSelectField/SmartSelectField';
import { StringField } from '../StringField/StringField';

interface RefFieldProps {
    meta: string;
    value: IObjectWithId | undefined;
    optionsValue?: IObjectWithId | string;
    onChange: (newValue: IObjectWithId | string | undefined) => void;
    groupFieldName: string | undefined;
    filters?: ColumnOptionsType['filters'];
    addon?: React.ReactNode;
    selectFirstAsDefault?: boolean;
}

export const RefField = memo<RefFieldProps>(
    ({
        value,
        onChange,
        meta,
        filters,
        addon,
        groupFieldName,
        optionsValue,
        selectFirstAsDefault
    }) => {
        const isValueUndefined = isUndefined(value);
        const isValueObject = isObject(value);

        useEffectOnce(() => {
            if (isObject(optionsValue)) {
                onChange(optionsValue);
            }
        });

        const { i18n } = useTranslation();

        if (isValueUndefined || isValueObject) {
            if (!isEmpty(meta)) {
                return (
                    <Flex style={{ width: '100%' }} gap={5}>
                        {addon && <div style={{ minWidth: '40%' }}>{addon}</div>}
                        <SmartSelectField
                            value={value}
                            onChange={onChange}
                            meta={meta}
                            filters={filters}
                            navigateMetaOptions={{ metaName: meta }}
                            treeOptions={{
                                groupKeys: groupFieldName ? [groupFieldName] : undefined,
                                parentField: 'Parent'
                            }}
                            selectFirstAsDefault={selectFirstAsDefault}
                            // style={{ width: addon ? '60%' : '100%' }}
                        />
                    </Flex>
                );
            }

            const multilangValue = value?.Name || value?.ShortTitle || value?.PluralName;

            const displayValue = isValueObject ? (
                multilangValue?.[i18n.language]
            ) : (
                <StringField
                    value={value}
                    onChange={onChange}
                    // style={{ maxWidth: '250px' }}
                    addonAfter={addon}
                />
            );

            return displayValue;
        }

        return (
            <StringField
                value={value}
                onChange={onChange}
                // style={{ maxWidth: '250px' }}
                addonAfter={addon}
            />
        );
    }
);
