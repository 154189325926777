import { useEffect, useState } from 'react';
import { PatternMonth, PatternWeek } from './components';

interface DaysPatternFieldProps {
    value: boolean[] | undefined;
    onChange: (newValue: boolean[]) => void;
    mode: 'edit' | 'view';
    dataSource?: any;
    centered?: boolean;
}

export const DaysPatternField: React.FC<DaysPatternFieldProps> = ({
    value,
    onChange,
    dataSource,
    mode,
    centered
}) => {
    useEffect(() => {
        const cat = dataSource.ScheduleItemCategory?.Code;
        const inv = dataSource.Interval?.Code;

        if (cat !== 'PER_DATES' && inv !== 'PER_DATES') onChange(undefined);
    }, [dataSource.Interval?.Code, dataSource.ScheduleItemCategory?.Code]); // DO NOT CHANGE !!!

    return (
        <div
            style={{
                marginTop: '5px',
                marginLeft: centered ? 'auto' : undefined,
                marginRight: centered ? 'auto' : undefined,
                marginBottom: '0',
                // border: mode === 'edit' ? '1px solid #d9d9d9' : undefined,
                border: '1px solid #d9d9d9',
                borderRadius: '6px',
                width: 'fit-content',
                // padding: mode === 'edit' ? 5 : 6
                padding: 5
            }}
        >
            {(dataSource.ScheduleItemCategory?.Code === 'PER_DAY' ||
                dataSource.Interval?.Code === 'PER_DAY') && (
                <PatternWeek mode={mode} value={value} onChange={onChange} />
            )}
            {(dataSource.ScheduleItemCategory?.Code === 'PER_WEEK' ||
                dataSource.Interval?.Code === 'PER_WEEK') && (
                <PatternWeek mode={mode} value={value} onChange={onChange} />
            )}
            {(dataSource.ScheduleItemCategory?.Code === 'PER_MONTH' ||
                dataSource.Interval?.Code === 'PER_MONTH') && (
                <PatternMonth mode={mode} value={value} onChange={onChange} />
            )}
        </div>
    );
};
