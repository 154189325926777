import * as AntdIcons from '@ant-design/icons';
import {
    CaretDownOutlined,
    CloseCircleOutlined,
    DeliveredProcedureOutlined,
    EditOutlined,
    FilePdfOutlined,
    InfoCircleOutlined,
    MenuOutlined,
    ReloadOutlined,
    SaveOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Dropdown, Flex, List, MenuProps, Space } from 'antd';
import { toJS } from 'mobx';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAsync, useMedia, useWindowSize } from 'react-use';

import { Action, Handler, MetaField } from 'modules/services/backend-api/generated_info';
import { Response } from 'modules/services/backend-api/generated_api';
// import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { MobileCardToolbar } from 'smart/modules/SmartTablePage/components/SmartTablePageHeaderToolbar/components';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { HelpIcon, LazyIcon } from 'smart/ui';
import { fnv1aHash, getNeededMetaRoutePathWithFilters } from 'smart/utils';
import { useBaseLanguage, useHandlerRun } from 'smart/utils/hooks';
import { ButtonWithTooltips, StoreLink } from 'ui';
// import { useNotifications } from 'utils/hooks';
import { LANGUAGES } from 'utils/i18n/i18n';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { useNotifications } from 'utils/hooks';
import { isDefined } from 'is-lite/exports';
import { Toast } from 'antd-mobile';
import { makeDeepMerge } from 'utils/helpers/makeDeepMerge';
import { v4 } from 'uuid';
// import { useUserData } from 'modules/client/useAuthUser';
import { emitter } from 'utils/emitter';
import { HandlerRunModal } from '../../../../components';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

type AntdIconsType = Omit<
    typeof AntdIcons,
    'IconProvider' | 'setTwoToneColor' | 'getTwoToneColor' | 'createFromIconfontCN'
>;

interface SmartDetailPageHeaderToolbarProps {
    meta: string;
    id: string;
    data: IObjectWithId;
    mode: string;
    loading?: boolean;
    onSave: (saveAndClose?: boolean) => Promise<any>;
    onCancel: () => any;
    onRefresh: () => any;
    onEdit: () => any;
    isDataChanged?: boolean;
    updatedActions?: Action[];
}

export interface IHandlerWithId extends Handler {
    Id: string;
}

// TODO: вынести в components/SmartToolbar для переиспользования
export const SmartDetailPageHeaderToolbar = memo<SmartDetailPageHeaderToolbarProps>(
    ({
        meta,
        id,
        data,
        mode,
        onSave,
        onCancel,
        onEdit,
        onRefresh,
        loading,
        isDataChanged,
        updatedActions
    }) => {
        const location = useLocation();
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const storeNavigate = useStoreNavigate();
        const isBigMobile = useMedia('(max-width: 480px)');
        const isMobile = isBigMobile;
        const { modal, notification } = useNotifications();
        // const { isPowerUser } = useUserData();
        const baseLanguage = useBaseLanguage();

        const metaData = metaStore.meta.get(meta);
        const info = metaData?.info;
        const isMetaReadOnly = info?.IsReadOnly || false;
        const uiAllowSave = info?.UiAllowSave;
        const uiAllowEdit = info?.UiAllowEdit;
        const uiAllowDelete = info?.UiAllowDelete;
        const uiAllowSelect = info?.UiAllowSelect;
        const metaDescription = info?.Description?.[language];
        const fields = info?.Fields;

        const actions = useMemo(() => {
            let actions = toJS(info?.Actions);

            // console.log(actions, updatedActions);

            if (updatedActions) {
                actions = makeDeepMerge({ actions }, { actions: updatedActions }).actions;
            }

            const sortRule = (action: Action) => (action.Type_Code === 'FOLDER' ? -1 : 1);

            return actions?.sort(sortRule);
        }, [info?.Actions, updatedActions]);

        // console.log(actions);

        const currentNavItem = useMemo(
            () =>
                toJS(metaStore.meta.get('all')?.routes)?.find(
                    (route) =>
                        route.path ===
                        `${location.pathname}${
                            location.state?.filterString ? `?${location.state?.filterString}` : ''
                        }`
                ),
            [location.pathname, location.state?.filterString]
        );

        const mandatoryFields = useMemo(
            () =>
                fields?.reduce((acc, f) => {
                    const obj = acc;
                    if (f.IsMandatory) {
                        obj[f.FieldName] = f;
                    }
                    return obj;
                }, {} as PlainObject<MetaField>) ?? {},
            [fields]
        );
        // console.log(mandatoryFields);

        // REESPONSIVE
        const { width } = useWindowSize();
        const isShowActionButtons = width > 660;
        const isShowActionButtonsLabel = width > 980;
        const isSmallTablet = useMedia('(max-width: 620px)');
        const isMiddleTablet = useMedia('(max-width: 720px)');

        const { run } = useHandlerRun();

        const { value: isShowFramedAssocInFollowsMenu } = useAsync(
            async () =>
                metaStore.getParam({
                    param_name: 'SHOW_FRAMED_ASSOC_IN_FOLLOWS_MENU',
                    default_value: false
                }),
            []
        );

        const [handlerModal, setHandlerModal] = useState(false);
        const [handlerModalData, setHandlerModalData] = useState<Action | null>(null);
        const [handlerModalHardIds, setHandlerModalHardIds] = useState<string[] | undefined>();

        const handleNavigateBeforeAction = useCallback(
            ({
                action,
                filters,
                metaFields,
                hardIds,
                hardArgs
            }: {
                action: Action;
                filters: FilterField[] | Filter[];
                metaFields?: MetaField[];
                hardIds?: string[];
                hardArgs?: PlainObject;
            }) => {
                const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                let pageTitle = location.state?.pageTitle || pathnameLastElem;
                if (action.Name) {
                    // pageTitle = action.Name[language];
                    pageTitle = action.Name;
                }

                let pathname = location.pathname;
                const cacheKeyBodyPermanent = hardIds ?? id ?? action?.Id ?? {};
                const cacheKeyBody = JSON.stringify(
                    filters?.length ? filters : cacheKeyBodyPermanent
                );
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${cacheKeyBody}`);
                let filterString;

                const navItem = action.NavItem;
                const navPath = navItem?.Path;

                if (navPath) {
                    pathname = navPath.includes('?') ? navPath.split('?')[0] : navPath;
                    // используем просутю hash функцию по методу fnv1
                    filterString = navPath.includes('?') ? navPath.split('?')[1] : undefined;
                    cacheKey = fnv1aHash(`${navItem.Id}_${cacheKeyBody}`);
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            requestData: {
                                action,
                                ids: hardIds ?? [id],
                                args: hardArgs ?? {}
                            },
                            metaFields,
                            data,
                            filterString
                        }
                    }
                );
            },
            [location.pathname, location.state?.pageTitle, storeNavigate, id, data]
        );

        const actionClickHandler = useCallback(
            async (
                action: Action,
                hardIds?: string[],
                hardArgs?: PlainObject
            ): Promise<Response | undefined> => {
                const handlerCode = action.Handler_Code;
                const handler = action.Handler;
                const params = handler?.ChildParams;

                if (action.IsNavigationFirst) {
                    handleNavigateBeforeAction({
                        action,
                        hardArgs,
                        hardIds,
                        filters: []
                    });
                } else if (handlerCode) {
                    const isShowModal =
                        action.Handler &&
                        ((params?.length && !params?.every((param) => !param.IsRequested)) ||
                            handlerCode === 'MassUpdate' ||
                            handlerCode === 'MassChildUpsert');
                    if (isShowModal) {
                        setHandlerModal(true);
                        setHandlerModalData({
                            ...action,
                            Handler: { ...handler, Id: action.Id! } as IHandlerWithId
                        });
                        setHandlerModalHardIds(hardIds);
                    } else {
                        const response = await run(
                            {
                                Action_Id: action.Id!,
                                meta: action.Meta_Code,
                                ids: hardIds || [id],
                                handler: handlerCode,
                                args: hardArgs || {}
                            },
                            action.IsLogResultHidden
                        );

                        onRefresh();
                        if (action.Handler_Code === 'CatSchedules.GenerateScheduleItems') {
                            emitter.emit('graphic_tab_refresh');
                        }

                        return response;
                    }
                }

                return undefined;
            },
            [handleNavigateBeforeAction, run, id, onRefresh]
        );

        const handleNavigateAfterAction = useCallback(
            (
                actionResponse: Response,
                action: Action,
                filters: FilterField[] | Filter[],
                metaFields?: MetaField[]
            ) => {
                if (!uiAllowSelect) return;

                const pathnameLastElem = location.pathname.split('/').at(-2)?.trim();

                let pageTitle = location.state?.pageTitle || pathnameLastElem;
                if (action.Name) {
                    // pageTitle = action.Name[language];
                    pageTitle = action.Name;
                }

                let pathname = location.pathname.split('/').slice(0, -1).join('/');

                const cacheKeyBodyPermanent = id ?? action?.Id ?? {};
                const cacheKeyBody = JSON.stringify(
                    filters?.length ? filters : cacheKeyBodyPermanent
                );
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${cacheKeyBody}`);
                let filterString;

                const navItem = action.NavItem;
                const navPath = navItem?.Path;

                if (navPath) {
                    pathname = navPath.includes('?') ? navPath.split('?')[0] : navPath;
                    // используем просутю hash функцию по методу fnv1
                    filterString = navPath.includes('?') ? navPath.split('?')[1] : undefined;
                    cacheKey = fnv1aHash(
                        `${navItem.Id}_${JSON.stringify(filters ?? [id] ?? action?.Id ?? {})}`
                    );
                } else if (action.Association?.TargetMeta_Code) {
                    pathname = `/other/${action.Association?.TargetMeta_Code}`;
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(`${pathname}_filtered_${cacheKeyBody}`);
                }

                if (action?.Handler?.MethodName === 'CreateBasedOn') {
                    pathname = `${pathname}/copy`;
                    cacheKey = fnv1aHash(
                        `${pathnameLastElem}_filtered_${JSON.stringify(filters)}_from_${id}`
                    );
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            responseData: { ...actionResponse.run?.[0]?.Result, ids: [id] },
                            metaFields,
                            data,
                            filterString
                        }
                    }
                );
            },
            [data, id, location.pathname, location.state?.pageTitle, storeNavigate, uiAllowSelect]
        );

        const actionButtons = useMemo(() => {
            if (actions) {
                return actions
                    .filter((action) => {
                        const cond =
                            action.IsButton &&
                            action.IsVisibleInForm &&
                            action.IsActive &&
                            (!action.AllowedForNavItems?.length ||
                                action.AllowedForNavItems?.includes(currentNavItem?.id as string));

                        if (!uiAllowDelete) {
                            return (
                                cond &&
                                action.Handler_Code !== 'Delete' &&
                                action.Handler_Code !== 'SoftDelete'
                            );
                        }

                        return cond; // ID есть всегда - это я подразумеваю
                    })
                    .map((action) => {
                        return (
                            <ButtonWithTooltips
                                type={'default'}
                                id={action.Id || ''}
                                onClick={async () => {
                                    if (action.Association) {
                                        const association = await metaStore.getAssociation({
                                            association: action.Association.Code,
                                            ids: [`${id}`]
                                        });

                                        let actionResponse = {
                                            session_id: '',
                                            request_id: '',
                                            status_code: 200
                                        };

                                        if (action.Handler) {
                                            if (action.IsNavigationFirst) return;

                                            const response = await actionClickHandler(
                                                action,
                                                association?.ids ?? undefined
                                            );

                                            if (response) {
                                                actionResponse = response;
                                            }
                                        }

                                        const filters = [
                                            {
                                                column: 'id',
                                                operator: 'in_list',
                                                value: association.ids ?? []
                                            }
                                        ];

                                        const metaField = fields?.find(
                                            (field) => field.FieldName === 'Id'
                                        );

                                        handleNavigateAfterAction(
                                            actionResponse,
                                            action,
                                            filters,
                                            metaField ? [toJS(metaField)] : []
                                        );
                                    } else {
                                        actionClickHandler(action);
                                    }
                                }}
                                icon={<LazyIcon icon={action.Icon} />}
                                tooltipTitle={
                                    !isShowActionButtonsLabel ? action.Name?.[language] : undefined
                                }
                            >
                                {isShowActionButtonsLabel ? action.Name?.[language] : null}
                            </ButtonWithTooltips>
                        );
                    });
            }

            return null;
        }, [
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            isShowActionButtonsLabel,
            language,
            id,
            fields,
            handleNavigateAfterAction,
            actionClickHandler
        ]);

        const actionsMenuItems = useMemo(() => {
            const folders: MenuItem[] = [];
            const folderless: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    (action.IsFooterButton && !isMobile) ||
                    !action.IsVisibleInForm ||
                    (isShowActionButtons && action.IsButton) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                if ((action.IsFolder || action.Type_Code === 'FOLDER') && !isMobile) {
                    folders.push({
                        key: action.Id!,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        children: []
                    });
                }

                if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                    const parentId = action.Parent_Id;

                    const folderIndex = !isMobile
                        ? folders.findIndex((folder) => folder && folder.key === parentId)
                        : -1;

                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        // icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            const response = await actionClickHandler(action);

                            if (response && action.NavItem_Id && !action.IsNavigationFirst) {
                                if (response) handleNavigateAfterAction(response, action, []);
                            }
                        }
                    };

                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }

                if (!action.IsFolder && action.Type_Code === 'EVENT') {
                    const parentId = action.Parent_Id;

                    const folderIndex = !isMobile
                        ? folders.findIndex((folder) => folder && folder.key === parentId)
                        : -1;

                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        // icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: () =>
                            actionClickHandler(
                                {
                                    ...action,
                                    Handler_Code: 'RaiseEvent',
                                    Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                },
                                undefined,
                                {
                                    event_type: action.EventType_Code
                                }
                            )
                    };

                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }
            }

            return [
                ...folders
                    .filter((folder) => folder.children?.length)
                    .sort((a, b) => Number(a?.index) - Number(b?.index)),
                ...folderless.sort((a, b) => Number(a?.index) - Number(b?.index))
            ];
        }, [
            actionClickHandler,
            actions,
            currentNavItem?.id,
            isShowActionButtons,
            language,
            uiAllowDelete,
            isMobile
        ]);

        const reportsMenuItems = useMemo(() => {
            const reports: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    (action.IsFooterButton && !isMobile) ||
                    !action.IsVisibleInForm ||
                    (isShowActionButtons && action.IsButton) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'REPORT') {
                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            const response = await actionClickHandler(action);
                        }
                    };

                    reports.push(item);
                }

                if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            const response = await actionClickHandler(action);

                            if (response) {
                                handleNavigateAfterAction(
                                    {
                                        session_id: '',
                                        request_id: '',
                                        status_code: 200,
                                        run: response.run
                                    },
                                    action,
                                    []
                                );
                            }
                        }
                    };

                    reports.push(item);
                }
            }

            return reports.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            actionClickHandler,
            actions,
            currentNavItem?.id,
            handleNavigateAfterAction,
            language,
            uiAllowDelete,
            isShowActionButtons
        ]);

        const followsMenuItems = useMemo(() => {
            const follows: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    (action.IsFooterButton && !isMobile) ||
                    action.IsBuiltIn ||
                    !action.IsVisibleInForm ||
                    (isShowActionButtons && action.IsButton) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete) ||
                    (!isShowFramedAssocInFollowsMenu && // пропускаем фреймы, если задан флаг это разрешающий
                        action.IsVisibleInFrame)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
                    const item = {
                        isButton: action.IsButton,
                        key: action.Id as string,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            const metaField = fields?.find((field) => field.FieldName === 'Id');

                            let filters: Filter[] = [
                                {
                                    column: 'id',
                                    operator: 'in_list',
                                    value: [`${id}`]
                                }
                            ];
                            let association;
                            let actionResponse = {
                                session_id: '',
                                request_id: '',
                                status_code: 200
                            };

                            if (action.Association) {
                                association = await metaStore.getAssociation({
                                    association: action.Association.Code,
                                    ids: [`${id}`]
                                });

                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: association?.ids
                                    }
                                ];
                            }
                            if (action.Handler) {
                                const response = await actionClickHandler(
                                    action,
                                    association ? association.ids : [`${id}`]
                                );

                                if (action.IsNavigationFirst) return;

                                if (response) {
                                    actionResponse = response;
                                }
                            }

                            if (actionResponse) {
                                handleNavigateAfterAction(
                                    actionResponse,
                                    action,
                                    filters,
                                    metaField ? [toJS(metaField)] : []
                                );
                            }
                        }
                    };

                    follows.push(item);
                }
            }

            return follows.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            actionClickHandler,
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            fields,
            handleNavigateAfterAction,
            id,
            isShowActionButtons,
            isShowFramedAssocInFollowsMenu
        ]);

        const validate = useCallback(
            async (onSave: (saveAndClose?: boolean) => Promise<any>, saveAndClose?: boolean) => {
                let validated = true;
                const fieldsToFill: MetaField[] = [];
                const mandatoryFieldNames = Object.keys(mandatoryFields);

                // console.log(data, mandatoryFieldNames);

                for (const key of mandatoryFieldNames) {
                    if (!data || !data[key]) {
                        validated = false;
                        fieldsToFill.push(mandatoryFields[key]);
                        continue;
                    }

                    const condition = Object.hasOwn(data[key], baseLanguage)
                        ? !(isDefined(data[key]) && data[key][baseLanguage])
                        : !isDefined(data[key]);

                    // if (!isDefined(data[key])) {
                    if (condition) {
                        validated = false;
                        fieldsToFill.push(mandatoryFields[key]);
                    }
                }

                if (validated) {
                    await onSave(saveAndClose);
                } else {
                    modal.error({
                        centered: true,
                        title: t('is_mandatory_error'),
                        content: (
                            <List
                                bordered={false}
                                dataSource={fieldsToFill}
                                renderItem={(item) => (
                                    <List.Item
                                        style={{ borderBlockEnd: '1px solid rgba(0,0,0,.05)' }}
                                    >
                                        {item.Name?.[language] ?? item.FieldName}
                                    </List.Item>
                                )}
                            />
                        )
                    });
                }
            },
            [data, language, mandatoryFields, modal, t]
        );

        const handleError = useCallback(
            (errorObject: { error: Error; result: Response }) => {
                const requestMeta = 'InfoRequests';
                const store = metaStore.meta;
                // const requestsRoute = store.get('all')?.routesMap?.get(requestMeta)?.[0];
                const { path, preFilters: filterString } =
                    getNeededMetaRoutePathWithFilters(requestMeta);
                const uiAllowView = store.get(requestMeta)?.info?.UiAllowView ?? true;

                const { error, result } = errorObject;
                // console.log(error, result);

                const key = v4();
                const close = () => notification.destroy(key);

                let link: React.ReactNode = null;

                if (path && uiAllowView) {
                    const requestId = result.request_id;
                    // const [pathname, filterString] = requestsRoute.path.split('?');

                    const to = `${path}/${requestId}`;

                    const linkText = t('show_details');

                    link = (
                        <StoreLink
                            style={{ padding: 0 }}
                            to={to}
                            state={{ filterString }}
                            onClick={close}
                        >
                            {linkText}...
                        </StoreLink>
                    );
                }

                const description = (
                    <div>
                        <span>{result?.error_text}</span>
                        {link}
                    </div>
                );

                notification.error({
                    key,
                    message: t('error'),
                    description,
                    onClose: close
                });
            },
            [notification, t]
        );

        const handleSave = useCallback(async () => {
            try {
                await validate(onSave);
            } catch (errorObject) {
                handleError(errorObject as { error: Error; result: Response });
            }
        }, [validate, onSave, handleError]);

        const handleSaveAndClose = useCallback(async () => {
            try {
                await validate(onSave, true);
            } catch (errorObject) {
                handleError(errorObject as { error: Error; result: Response });
            }
        }, [validate, onSave, handleError]);

        const handleEdit = useCallback(() => {
            try {
                onEdit();
            } catch (errorObject) {
                handleError(errorObject as { error: Error; result: Response });
            }
        }, [handleError, onEdit]);

        const ids = useMemo(() => [id], [id]);

        return (
            <Flex justify="space-between" className="smart_table__header">
                {isMobile ? (
                    <MobileCardToolbar
                        actionsMenuItems={actionsMenuItems}
                        reportsMenuItems={reportsMenuItems}
                        followsMenuItems={followsMenuItems}
                        selectedRows={[data]}
                        setSelectedRows={() => {}}
                        actionButtonsColor="#3185d2"
                        actionButtonsTextColor="#ffffff"
                        // buttonActions={actionsMenuItems}
                    />
                ) : (
                    <>
                        <Space.Compact className="smart_table__header_left">
                            {mode === 'edit' ? (
                                <>
                                    {uiAllowSave && (
                                        <ButtonWithTooltips
                                            onClick={handleSaveAndClose}
                                            id="save_and_close"
                                            disabled={loading || !isDataChanged}
                                        >
                                            {isMiddleTablet ? (
                                                <DeliveredProcedureOutlined />
                                            ) : (
                                                t('save_and_close')
                                            )}
                                        </ButtonWithTooltips>
                                    )}
                                    {uiAllowSave && (
                                        <ButtonWithTooltips
                                            onClick={handleSave}
                                            type="default"
                                            id="save"
                                            disabled={loading || !isDataChanged}
                                        >
                                            {isMiddleTablet ? <SaveOutlined /> : t('save')}
                                        </ButtonWithTooltips>
                                    )}
                                    <ButtonWithTooltips
                                        onClick={onCancel}
                                        type="default"
                                        id="cancel"
                                        disabled={loading}
                                    >
                                        {isMiddleTablet ? <CloseCircleOutlined /> : t('cancel')}
                                    </ButtonWithTooltips>
                                </>
                            ) : (
                                <>
                                    {uiAllowEdit && !isMetaReadOnly ? (
                                        <ButtonWithTooltips
                                            onClick={handleEdit}
                                            id="edit"
                                            disabled={loading}
                                        >
                                            {isMiddleTablet ? <EditOutlined /> : t('edit')}
                                        </ButtonWithTooltips>
                                    ) : null}
                                </>
                            )}
                            {/* ### Дейсвия (мета) ### */}
                            {actionsMenuItems?.length ? (
                                <Dropdown
                                    menu={{
                                        items: actionsMenuItems,
                                        style: { maxHeight: '400px', overflow: 'auto' },
                                        triggerSubMenuAction: 'click'
                                    }}
                                    trigger={['click']}
                                    placement="bottomLeft"
                                    disabled={mode === 'edit'}
                                    destroyPopupOnHide
                                >
                                    <ButtonWithTooltips type={'default'} id="actions">
                                        {isSmallTablet ? <MenuOutlined /> : t('actions')}
                                        {/* {!isSmallMobile && <CaretDownOutlined />} */}
                                        <CaretDownOutlined />
                                    </ButtonWithTooltips>
                                </Dropdown>
                            ) : null}

                            {/* ### Отчеты (мета) ### */}
                            {reportsMenuItems?.length ? (
                                <Dropdown
                                    menu={{
                                        items: reportsMenuItems,
                                        style: { maxHeight: '400px', overflow: 'auto' },
                                        triggerSubMenuAction: 'click'
                                    }}
                                    trigger={['click']}
                                    placement="bottomLeft"
                                    destroyPopupOnHide
                                >
                                    <ButtonWithTooltips type={'default'} id="reports">
                                        {isSmallTablet ? <FilePdfOutlined /> : t('reports')}
                                        {/* {!isSmallMobile && <CaretDownOutlined />} */}
                                        <CaretDownOutlined />
                                    </ButtonWithTooltips>
                                </Dropdown>
                            ) : null}

                            {/* ### Переходы (мета) ### */}
                            {followsMenuItems?.length ? (
                                <Dropdown
                                    menu={{
                                        items: followsMenuItems,
                                        style: { maxHeight: '400px', overflow: 'auto' },
                                        triggerSubMenuAction: 'click'
                                    }}
                                    trigger={['click']}
                                    placement="bottomLeft"
                                    destroyPopupOnHide
                                >
                                    <ButtonWithTooltips type={'default'} id="follows">
                                        {isSmallTablet ? <ShareAltOutlined /> : t('follows')}
                                        {/* {!isSmallMobile && <CaretDownOutlined />} */}
                                        <CaretDownOutlined />
                                    </ButtonWithTooltips>
                                </Dropdown>
                            ) : null}

                            {/* ### Обновить ### */}
                            <ButtonWithTooltips
                                id="reload"
                                tooltipTitle={t('reload')}
                                tooltipPlacement="top"
                                onClick={onRefresh}
                                icon={<ReloadOutlined />}
                                type={'default'}
                                disabled={mode === 'edit'}
                            />

                            {actionButtons}
                        </Space.Compact>

                        <Flex className="smart_table__header_center">
                            {/* TODO: center part of toolbar */}
                            <></>
                        </Flex>

                        <Space.Compact className="smart_table__header_right">
                            <ButtonWithTooltips
                                tooltipTitle={t('copy_link')}
                                className={''}
                                tooltipPlacement={'bottomLeft'}
                                type={'default'}
                                icon={<LazyIcon icon={'mdiLinkVariant'} />}
                                onClick={() => {
                                    navigator.clipboard.writeText(`${window.location.href}`);
                                    Toast.show({
                                        content: t('copied')
                                    });
                                }}
                            />

                            {/* ### Описание ### */}
                            {
                                // isPowerUser || (!isPowerUser && metaDescription) ? (
                                metaDescription ? (
                                    <ButtonWithTooltips
                                        icon={<InfoCircleOutlined />}
                                        tooltipTitle={t('notes')}
                                        type="default"
                                        onClick={() => {
                                            storeNavigate(
                                                { pathname: `/notes/${meta}` },
                                                {
                                                    state: {
                                                        // pageTitle: `${t('notes')} (${
                                                        //     info?.PluralName?.[language]
                                                        // })`
                                                        pageTitle: 'notes',
                                                        extraPageTitle: toJS(info?.PluralName)
                                                    }
                                                }
                                            );
                                        }}
                                    />
                                ) : // <HelpIcon
                                //     text={metaDescription}
                                //     style={{ width: 32 }}
                                //     // iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                                //     trigger="click"
                                //     meta={meta}
                                //     isButton
                                // />
                                null
                            }
                        </Space.Compact>
                    </>
                )}

                {handlerModal && (
                    <HandlerRunModal
                        open={handlerModal}
                        onCancel={() => {
                            setHandlerModal(false);
                            setHandlerModalData(null);
                            setHandlerModalHardIds(undefined);
                        }}
                        action={handlerModalData}
                        ids={handlerModalHardIds || ids}
                        onRefresh={onRefresh}
                        metaName={meta}
                        navigateAfterRun={handleNavigateAfterAction}
                        meta={meta}
                        row={data}
                    />
                )}
            </Flex>
        );
    }
);
