import { App as AntdApp, Typography } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface UseNotificationsProps {
    message: React.ReactNode | string;
    description?: string | React.ReactNode;
    smallInfo?: string;
    placement?: NotificationPlacement;
    type?: NotificationType;
    duration?: number | null;
    descriptionContent?: React.ReactNode;
    key?: string;
    onClose?: () => void;
}

export const useNotifications = (): ReturnType<typeof AntdApp.useApp> => {
    const antdApp = AntdApp.useApp();
    const { t } = useTranslation();

    const openNotify = useCallback(
        (options?: UseNotificationsProps) => {
            antdApp.notification.open({
                message: options?.message,
                icon: null,
                description: (
                    <>
                        <div style={{ marginBottom: 5 }}>
                            {options?.descriptionContent ? (
                                options?.descriptionContent
                            ) : (
                                <Typography.Text
                                    style={{
                                        padding: 0,
                                        paddingBottom: 9,
                                        borderRadius: 0,
                                        whiteSpace: 'unset',
                                        overflow: 'unset',
                                        textOverflow: 'unset'
                                    }}
                                >
                                    {options?.description}
                                </Typography.Text>
                            )}
                        </div>
                        <Typography.Text
                            type="secondary"
                            style={{
                                fontSize: '11px',
                                padding: 0,
                                borderRadius: 0,
                                whiteSpace: 'unset',
                                overflow: 'unset',
                                textOverflow: 'unset'
                            }}
                        >
                            {options?.smallInfo || `${t('today')}, ${dayjs().format('HH:mm:ss')}`}
                        </Typography.Text>
                    </>
                ),
                placement: options?.placement ?? 'bottomRight',
                className: `vectura-notifications vectura-notifications-${options?.type}`,
                duration: options?.duration ?? 0,
                key: options?.key,
                // onClick: options?.onClick
                onClose: options?.onClose
            });
        },
        [antdApp.notification, t]
    );

    const error = useCallback(
        (options?: Omit<UseNotificationsProps, 'type'>) =>
            openNotify({ ...options, type: 'error' } as UseNotificationsProps),
        [openNotify]
    );

    const info = useCallback(
        (options?: Omit<UseNotificationsProps, 'type'>) =>
            openNotify({ ...options, type: 'info' } as UseNotificationsProps),
        [openNotify]
    );

    const success = useCallback(
        (options?: Omit<UseNotificationsProps, 'type'>) =>
            openNotify({ ...options, type: 'success' } as UseNotificationsProps),
        [openNotify]
    );

    const warning = useCallback(
        (options?: Omit<UseNotificationsProps, 'type'>) =>
            openNotify({ ...options, type: 'warning' } as UseNotificationsProps),
        [openNotify]
    );

    return {
        message: antdApp.message,
        notification: { ...antdApp.notification, error, info, open: openNotify, success, warning },
        modal: antdApp.modal
    };
};
