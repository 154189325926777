import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Row } from 'antd';
import { TableRow } from 'modules/supabase/types/Dataset';
import { Database } from 'modules/supabase/types/database.types';

import { WeekDay } from '../../ui';

import './PatternMonth.scss';

type ScheduleItemType = TableRow & Database['public']['Views']['vdocs_schedule_items']['Row'];

interface PatternMonthPropsType {
    value: boolean[] | undefined;
    onChange: Function;
    mode: 'edit' | 'view';
}

export const PatternMonth: React.FC<PatternMonthPropsType> = ({ value, onChange, mode }) => {
    const { t } = useTranslation();

    const getInitialMonth = useCallback(() => {
        const month = [];

        for (let i = 0; i <= 30; i++) {
            month.push(false);
        }

        return month;
    }, []);

    const getData = useCallback(() => {
        return !value ? getInitialMonth() : value;
    }, [value, getInitialMonth]);

    const daySelectHandle = useCallback(
        (index: number) => {
            const DaysPattern = getData();

            DaysPattern[index] = !DaysPattern[index];

            onChange(DaysPattern);
        },
        [getData, onChange]
    );

    const buildMonthRows = () => {
        const DaysPattern = !value ? getInitialMonth() : value;

        return [
            DaysPattern.slice(0, 7),
            DaysPattern.slice(7, 14),
            DaysPattern.slice(14, 21),
            DaysPattern.slice(21, 28),
            DaysPattern.slice(28, 31)
        ];
    };

    const setDaysByIndex = useCallback(
        (indexArray: number[], value: boolean) => {
            const DaysPattern = getData();

            indexArray.forEach((index: number) => {
                DaysPattern[index] = value;
            });

            onChange(DaysPattern);
        },
        [getData, onChange]
    );

    const getMonthDayIndexes = useCallback(() => {
        const indexArray = [];

        for (let i = 0; i <= 30; i++) {
            indexArray.push(i);
        }

        return indexArray;
    }, []);

    return (
        <>
            <Row className="month justify-content-center">
                <Col style={{ width: '100%' }} sm="auto">
                    {buildMonthRows().map((week, weekIndex) => {
                        return (
                            <Row gutter={5} style={{ marginBottom: 5 }}>
                                {week.map((day, dayIndex) => {
                                    return (
                                        <Col sm="auto">
                                            <WeekDay
                                                mode={mode}
                                                weekDay={(weekIndex * 7 + dayIndex + 1)
                                                    .toString()
                                                    .padStart(2, '0')}
                                                selected={
                                                    value ? value[weekIndex * 7 + dayIndex] : false
                                                }
                                                onDaySelect={() => {
                                                    daySelectHandle(weekIndex * 7 + dayIndex);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        );
                    })}
                </Col>
            </Row>

            <Row className="m-2">
                <Col>
                    <Checkbox
                        disabled={mode !== 'edit'}
                        onChange={(e) => {
                            setDaysByIndex(
                                getMonthDayIndexes().filter((index) => index % 2 !== 0),
                                e.target.checked
                            );
                        }}
                    >
                        {t('scheduleItemDayPatternEven')}
                    </Checkbox>
                </Col>

                <Col>
                    <Checkbox
                        disabled={mode !== 'edit'}
                        onChange={(e) => {
                            setDaysByIndex(
                                getMonthDayIndexes().filter((index) => index % 2 === 0),
                                e.target.checked
                            );
                        }}
                    >
                        {t('scheduleItemDayPatternOdd')}
                    </Checkbox>
                </Col>
            </Row>
        </>
    );
};
