import { useEffect, useState } from 'react';

export const useConditionalScroll = (containerSelector: string) => {
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        const container = document.querySelector(`${containerSelector}`);
        if (!container) return;

        const checkScroll = () => {
            const containerEl = container as HTMLElement;
            const contentHeight = containerEl.scrollHeight;
            const containerHeight = containerEl.clientHeight;

            setShouldScroll(contentHeight - containerHeight >= 3);
        };

        const observer = new ResizeObserver(checkScroll);
        observer.observe(container);

        return () => observer.disconnect();
    }, [containerSelector]);

    useEffect(() => {
        const container = document.querySelector(`${containerSelector}`);
        if (container) {
            container.classList.toggle('enable-scroll', shouldScroll);
        }
    }, [shouldScroll, containerSelector]);

    return shouldScroll;
};
