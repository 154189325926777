import { Input, InputProps } from 'antd';
import { type InputHTMLAttributes, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useUpdateEffect } from 'react-use';
import { isDefined } from 'is-lite/exports';

interface StringFieldProps extends Omit<InputProps, 'onChange'> {
    onChange?: (value: InputHTMLAttributes<HTMLInputElement>['value'] | bigint) => void;
}

export const StringField = memo<StringFieldProps>(({ value, onChange, readOnly, ...props }) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);

    useUpdateEffect(() => {
        if (onChange && value === undefined) setInnerValue(undefined);
        else if (onChange && value !== innerValue && value && innerValue) setInnerValue(value);
    }, [value]);

    useDebounce(
        () => {
            if (onChange && isDefined(innerValue)) {
                onChange(innerValue);
            }
        },
        400,
        [innerValue]
    );

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            const newValue = e.target.value;
            if (newValue === '') {
                setInnerValue(undefined);
                if (onChange) onChange(undefined);
            } else {
                setInnerValue(newValue);
            }
        },
        [onChange]
    );

    const v = innerValue ?? value;
    const readOnlyV = value;

    return (
        <Input
            {...props}
            placeholder={t('no_value') as string}
            value={readOnly ? readOnlyV : v}
            onChange={handleChange}
        />
    );
});
