import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useAsync, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isArray, isEmpty, isPlainObject, isPropertyOf, isString } from 'is-lite/exports';
import { v4 } from 'uuid';
import { SmartTooltip } from 'smart/ui';
import { StoreLink } from 'ui';

import { IObjectWithId, metaStore } from '../../../utils/store/MetaStore';
import { RefField } from '../RefField/RefField';
import { getNeededMetaRoutePathWithFilters, JSONSafeParse, parseValueType } from '../../utils';
import { SmartMultiSelectField } from '../SmartMultiSelectField/SmartMultiSelectField';

interface DynamicTypeFieldProps {
    objectMeta: string;
    onChange?: (newValue: string | IObjectWithId | undefined) => void;
    value: IObjectWithId | undefined;
    readOnly?: boolean;
    multi?: boolean;
}

export const DynamicTypeField = observer<DynamicTypeFieldProps>(
    ({ objectMeta, onChange = () => {}, value, readOnly, multi }) => {
        const {
            i18n: { language }
        } = useTranslation();

        const [refValue, setRefValue] = useState<IObjectWithId | IObjectWithId[] | string>();

        const valueType = useAsync(async () => {
            if (objectMeta && objectMeta !== '') {
                const meta =
                    metaStore.meta.get(objectMeta)?.info ?? (await metaStore.getInfo(objectMeta));

                const valueType =
                    meta?.Fields.find((f) => f.FieldName === 'Type')?.ValueType ?? '*code';

                return parseValueType({ valueType }, language);
            }
        }, [objectMeta]);

        // обнуляем значение селекта, если перевыбрали мету
        useUpdateEffect(() => {
            setRefValue(undefined);
        }, [objectMeta]);

        // console.log(valueType, refValue);

        // если Строка на вход пришла пустая - защищаемся и делаем ее undefined для отправки на сервер
        useEffect(() => {
            if (isEmpty(value)) onChange(undefined);
        }, [onChange, value]);

        // делаем динамический лукап по Коду (строка), прилетевшему с сервера, при условии что ДО этого выбранное значение не было разлукаплено И мы не выбирали значение из списка
        useEffect(() => {
            const typesMeta = valueType.value?.options?.ref?.meta ?? '';

            const isEmptyValue = isEmpty(value);

            if (
                multi &&
                value &&
                !isEmptyValue &&
                isString(value[0]) &&
                typesMeta &&
                (!refValue || isEmpty(refValue))
            ) {
                const lookup = [];

                if (typesMeta) {
                    (async () => {
                        const neadableValue =
                            metaStore.meta
                                .get(typesMeta)
                                ?.select?.objects?.filter((o) => o.Code === value)?.[0] ??
                            (
                                await metaStore.makeSelect({
                                    meta: typesMeta,
                                    filters: `Code=in.${value.join(',')}`
                                })
                            )?.objects ??
                            undefined;

                        // console.log(toJS(neadableValue));

                        if (neadableValue) setRefValue(toJS(neadableValue));
                    })();
                } else {
                    setRefValue(lookup);
                }
            } else if (
                isString(value) &&
                !isEmptyValue &&
                typesMeta &&
                !isString(refValue) &&
                !isPropertyOf(refValue ?? {}, 'Name')
            ) {
                // console.log('effect');

                const lookup = { Code: value, Id: v4() };

                if (typesMeta) {
                    (async () => {
                        const neadableValue =
                            metaStore.meta
                                .get(typesMeta)
                                ?.select?.objects?.filter((o) => o.Code === value)?.[0] ??
                            (
                                await metaStore.makeSelect({
                                    meta: typesMeta,
                                    filters: `Code=eq.${value}`
                                })
                            )?.objects[0] ??
                            undefined;

                        // console.log(toJS(neadableValue));

                        if (neadableValue) setRefValue(toJS(neadableValue));
                    })();
                } else {
                    setRefValue(lookup);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, valueType.value?.options?.ref?.meta]);

        // достаем из выбранного объекта КОд для отправки на сервер текстом
        useUpdateEffect(() => {
            if (isArray(refValue)) {
                onChange(refValue.map((ref) => ref.Code));
            } else if (isPlainObject(refValue)) {
                onChange(refValue.Code);
            } else {
                onChange(refValue);
            }
        }, [refValue]);

        if (readOnly) {
            if (refValue && typeof refValue !== 'string' && valueType.value?.options?.ref?.meta) {
                // const toMetaRoute = metaStore.meta
                //     ?.get('all')
                //     ?.routesMap?.get(valueType.value.options.ref.meta)?.[0];

                const { path, preFilters } = getNeededMetaRoutePathWithFilters(
                    valueType.value.options.ref.meta
                );

                if (multi && isArray(refValue)) {
                    return (
                        <SmartTooltip simpleMode={false}>
                            {(refValue as any[]).map((data, index, array) => {
                                const displayValue =
                                    data.Name ??
                                    data.PluralName ??
                                    data.DisplayName ??
                                    data.PropertyValue ??
                                    data.Code;

                                return (
                                    <div style={{ display: 'inline-flex' }}>
                                        <StoreLink
                                            style={{ padding: 0 }}
                                            to={{
                                                pathname: `${path}/${data.Id}`,
                                                search: ''
                                            }}
                                            state={{ data, filterString: preFilters }}
                                        >
                                            <SmartTooltip>
                                                {displayValue?.[language] ?? displayValue}
                                            </SmartTooltip>
                                        </StoreLink>
                                        {array.length !== index + 1 ? ',' : null}&nbsp;
                                    </div>
                                );
                            })}
                        </SmartTooltip>
                    );
                }

                const storeProps = {
                    style: { padding: 0 },
                    to: {
                        // pathname: `${toMetaRoute?.path?.split('?')[0]}/${refValue.Id}`,
                        pathname: `${path}/${refValue.Id}`,
                        search: ''
                    },
                    // state: { data: refValue }
                    state: { data: refValue, filterString: preFilters }
                };

                const displayValue =
                    refValue.Name ??
                    refValue.PluralName ??
                    refValue.DisplayName ??
                    refValue.PropertyValue ??
                    refValue.Code;

                return (
                    <StoreLink {...storeProps}>
                        <SmartTooltip>{displayValue?.[language] ?? displayValue}</SmartTooltip>
                    </StoreLink>
                );
            }

            return <SmartTooltip>{refValue?.toString()}</SmartTooltip>;
        }

        if (multi) {
            return (
                <SmartMultiSelectField
                    metaName={valueType.value?.options?.ref?.meta ?? ''}
                    value={isArray(refValue) || !refValue ? refValue : [refValue]}
                    onChange={setRefValue}
                    filters={valueType.value?.options?.filters}
                    treeOptions={{
                        groupKeys: valueType.value?.options?.group
                            ? [valueType.value?.options.group]
                            : undefined,
                        parentField: 'Parent'
                    }}
                />
            );
        }

        return (
            <RefField
                meta={valueType.value?.options?.ref?.meta ?? ''}
                onChange={setRefValue}
                parentFieldName={valueType.value?.options?.group}
                value={refValue as IObjectWithId}
                optionsValue={
                    JSONSafeParse(valueType.value?.options?.value || '') as IObjectWithId | string
                }
                filters={valueType.value?.options?.filters}
            />
        );
    }
);
