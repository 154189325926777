import { EventSourceInput } from '@fullcalendar/core';
import { ViewName } from 'modules/supabase/types/Dataset';
import React, { useEffect, useState } from 'react';
import { metaStore } from 'utils/store/MetaStore';
import { ScheduleSlotType } from './ScheduleSlotsHelper';
import { useAsyncFn } from 'react-use';

interface useGenerateEventsProps {
    slots_view_name: ViewName;
    calendar_ref: React.MutableRefObject<any>;
}

export const useGenerateEvents = (
    rootId: string
): {
    events: EventSourceInput[];
    retry: () => void;
    loading: boolean;
} => {
    const [events, setEvents] = useState<EventSourceInput[]>([]);
    const [slots, setSlots] = useState([]);

    const [{ loading }, retry] = useAsyncFn(async () => {
        const response = await metaStore.makeSelect({
            meta: 'RegScheduleSlots',
            filters: `Schedule=eq.${rootId}`
        });

        if (response.objects.length > 0) {
            setSlots(response.objects);
        }
    }, [rootId]);

    useEffect(() => {
        retry();
    }, [retry]);

    useEffect(() => {
        const eventSources: EventSourceInput[] = [];

        slots.forEach((slot) => {
            const slotData = slot as ScheduleSlotType;

            const eventSource: EventSourceInput = {
                events: [
                    {
                        title: slotData.schedule_item_name ? slotData.schedule_item_name : '',
                        start: slotData.SlotFromAt as string, // ? dayjs(slotData.datetime_range.from_datetime as string).toISOString() : '',
                        end: slotData.SlotToAt as string, // ? dayjs(slotData.datetime_range.to_datetime as string).toISOString() : '',
                        display: 'block'
                    }
                ],
                backgroundColor: slotData.IsOverrided
                    ? 'rgba(255, 77, 79, .9)' // TODO: подвязать к App теме
                    : 'rgba(55, 136, 216, .9)', // TODO: подвязать к App теме
                borderColor: 'rgba(0,0,0,0)'
            };

            eventSources.push(eventSource);
        });

        // console.log('EVENTS:', eventSources);

        setEvents(eventSources);
    }, [slots]);

    return {
        events,
        loading,
        retry
    };
};
