import { isEmpty } from 'is-lite/exports';
import { IObjectWithId } from '../../../../utils/store/MetaStore';

export type GetUniqItemsFromSlots = (options: {
    firstColumnFieldName: string;
    slots: IObjectWithId[];
    bookedSlots: IObjectWithId[];
    selectedTripIds: string[];
    selectedVisitIds: string[];
}) => IObjectWithId[];

export const getUniqItemsFromSlots: GetUniqItemsFromSlots = ({
    firstColumnFieldName,
    slots,
    bookedSlots,
    selectedTripIds,
    selectedVisitIds
}) => {
    const uniqItems: IObjectWithId[] = [];

    for (const slot of slots) {
        const itemFromSlot = slot[firstColumnFieldName];

        if (!itemFromSlot) continue;

        if (uniqItems.find((d) => d.Id === itemFromSlot?.Id)) continue; // не будем дублировать ресурсы

        if (
            !isEmpty(selectedTripIds) &&
            !bookedSlots.find(
                (bs) =>
                    bs[firstColumnFieldName]?.Id === itemFromSlot?.Id &&
                    selectedTripIds.includes(bs?.Trip?.Id ?? '')
            )
        )
            continue;

        if (
            !isEmpty(selectedTripIds) &&
            !bookedSlots.find(
                (bs) =>
                    bs[firstColumnFieldName]?.Id === itemFromSlot?.Id &&
                    selectedVisitIds.includes(bs?.Visit?.Id ?? '')
            )
        )
            continue;

        uniqItems.push(itemFromSlot);
    }

    return uniqItems;
};
