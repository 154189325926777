import { PlainObject } from '@gilbarbara/types';
import { message } from 'antd';
import modal from 'antd/es/modal';
import _ from 'lodash';
import { Handler, Meta, Object as ModelObject } from 'modules/services/backend-api/generated_info';
import { useCallback, useState } from 'react';
import { useAsync, useMedia, useUpdateEffect } from 'react-use';
import settings, { ISettingsModel } from 'smart/settings';
import { makeDeepMerge } from 'utils/helpers/makeDeepMerge';
import { toPascalCase } from 'utils/helpers/toPascalCase';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';

interface UseModelProps<T> {
    data: T;
    meta: Meta;
    metaCode: string;
    modulePart: 'detail' | 'table';
    metaLayout?: { TablePage?: any; DetailPage?: any };
    dataArray?: T[];
    id: string;
}

interface IModelHandlersObject {
    [methodNameOrFieldName: string]:
        | { [fieldsMethodName: string]: string | undefined }
        | string
        | undefined;
}

const keyBy = (array: any[], key: string) =>
    array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc;
    }, {});

export const useModel = <T = ModelObject>({
    data,
    dataArray,
    meta,
    metaLayout,
    metaCode,
    modulePart,
    id
}: UseModelProps<T>) => {
    const { notification } = useNotifications();
    const isBigMobile = useMedia('(max-width: 480px)');

    const initialChangesValue = {
        Id: id,
        CreatedAt: data?.CreatedAt
    };

    const [modelState, setModel] = useState<ISettingsModel<T>>({
        // data: { ...data },
        data: data ?? initialChangesValue,
        dataArray,
        // meta,
        meta: undefined,
        // metaWithLayout: rootMetaInfo,
        layout: metaLayout
            ? {
                  detail: metaLayout.DetailPage,
                  table: metaLayout.TablePage
                  // info: metaLayout.Info
              }
            : undefined
    });

    const { value: layouts } = useAsync(async () => {
        if (metaCode) {
            const res = await metaStore.makeSelect({
                meta: 'InfoLayouts',
                filters: `Meta=eq.${metaCode}`
            });

            const layoutArray = res?.objects?.reduce((acc, item) => {
                const code = item.Code ?? item.Key;
                acc[code] = item;

                return acc;
            }, {} as PlainObject<any>);

            return layoutArray;
        }

        return null;
    }, [metaCode]);

    const applyLayout = (model: any) => (layoutCode: string) => {
        const layout = layouts?.[layoutCode];
        if (layout) {
            let newMeta = model.meta;

            const layoutObject = layout.Info;
            const layoutByPlatform = isBigMobile
                ? layoutObject.InfoMobile
                : layoutObject.InfoDesktop;
            const layoutDefault = layoutObject.Info;
            const newModelLayout = layoutByPlatform
                ? layoutDefault
                    ? makeDeepMerge(layoutDefault, layoutByPlatform)
                    : layoutByPlatform
                : layoutDefault;

            if (newModelLayout) newMeta = makeDeepMerge(newMeta, newModelLayout);

            return {
                ...model,
                meta: newMeta,
                layout: { ...layout }
            };
        }

        return model;
    };

    const callFunction = useCallback(
        (fn: Function | string, extraArguments?: any[]) => {
            if (typeof fn === 'string') {
                try {
                    const allowedGlobals = {
                        _,
                        $: { keyBy, applyLayout },
                        console,
                        Math,
                        Date,
                        JSON,
                        notification,
                        modal,
                        message
                    };

                    // eslint-disable-next-line no-new-func
                    const func = new Function(
                        'RGbOzaHwjxaUjfcO',
                        `return function (HUuxJCivlvSeGHfZ, hgRrxjtUVfXhbUaF) {
                            "use strict";

                            const window = undefined;
                            const globalThis = undefined;
                            const document = undefined;
                            const process = undefined;
                            const require = undefined;

                            const {
                                _,
                                $: { keyBy, applyLayout },
                                console,
                                Math,
                                Date,
                                JSON,
                                notification,
                                modal,
                                message
                            } = RGbOzaHwjxaUjfcO;

                            let model = JSON.parse(JSON.stringify(HUuxJCivlvSeGHfZ));
                            model.handlers = HUuxJCivlvSeGHfZ.handlers;
                            if (hgRrxjtUVfXhbUaF?.[1]) {
                                model = hgRrxjtUVfXhbUaF?.[1];
                            }
                            if (hgRrxjtUVfXhbUaF?.[2]) {
                                model.meta = hgRrxjtUVfXhbUaF?.[2];
                            }
                            if (hgRrxjtUVfXhbUaF?.[3]) {
                                model.handlers = hgRrxjtUVfXhbUaF?.[3];
                            }


                            let item;
                            const cXcHXdDNGLQxntwX = hgRrxjtUVfXhbUaF?.[0];

                            // if (cXcHXdDNGLQxntwX) {
                                if (cXcHXdDNGLQxntwX && typeof cXcHXdDNGLQxntwX === 'object') {
                                    item = JSON.parse(JSON.stringify(cXcHXdDNGLQxntwX));
                                } else {
                                    item = cXcHXdDNGLQxntwX;
                                }
                            // }
                            
                            ${fn
                                .replaceAll('model.handlers.', 'model = model.handlers.')
                                .replaceAll('applyLayout', 'model = applyLayout(model)')}
                                
                            return model;
                        }`
                    )?.(allowedGlobals);
                    // console.log(func);

                    if (func) {
                        const model = func(modelState, extraArguments);

                        setModel((prev) => {
                            return { ...prev, ...model };
                        });

                        // возврат true, который говорит о том что обработчик был УСПЕШНО вызван
                        return true;
                    }

                    // возврат false, который говорит о том что обработчик не был вызван (не существует или ошибка)
                    return false;
                } catch (error) {
                    console.error('Error then SERVER function init or then they call:', error);
                    // возврат false, который говорит о том что обработчик не был вызван (не существует или ошибка)
                    return false;
                }
            } else {
                try {
                    const model = JSON.parse(JSON.stringify(modelState));
                    fn(model);

                    setModel((prev) => ({ ...prev, ...model }));

                    // возврат true, который говорит о том что обработчик был УСПЕШНО вызван
                    return true;
                } catch (error) {
                    console.error('Error then CLIENT function call:', error);

                    // возврат false, который говорит о том что обработчик не был вызван (не существует или ошибка)
                    return false;
                }
            }
        },
        [modelState, notification]
    );

    const flattenObject = (obj: IModelHandlersObject, prefix = ''): IModelHandlersObject => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            const newKey = prefix ? `${prefix}_${key}` : key;

            if (typeof value === 'object' && value !== null) {
                Object.assign(acc, flattenObject(value, newKey));
            } else {
                acc[newKey] = ({ model, item, meta, handlers }) =>
                    callFunction(value, [item, model, meta, handlers]);
            }

            return acc;
        }, {} as IModelHandlersObject);
    };

    const { value: serverHandlerMap, loading: serverHandlersLoading } = useAsync(async () => {
        if (meta?.Actions) {
            const res = [];
            try {
                for (const action of meta.Actions) {
                    if (action.Type_Code === 'CALLBACK') {
                        res.push(action.Handler);
                    }
                }
            } catch (error) {
                console.error('Server JavaScript handlers fetching error:', error);

                notification.error({
                    message: 'Server JavaScript handlers fetching error',
                    description: (error as Error)?.message,
                    duration: 20
                });
            }

            if (res.length) {
                const map = (res as Handler[]).reduce(
                    (acc, handler) => {
                        const method = handler.MethodName;

                        if (method) {
                            if (method.startsWith('DetailPage_')) {
                                const [methodNameOrFieldName, methodNameForField] = method
                                    .replace('DetailPage_', '')
                                    .split('_');
                                // console.log(methodNameOrFieldName, methodNameForField);
                                if (methodNameForField) {
                                    acc.detail[methodNameOrFieldName] = {};
                                    acc.detail[methodNameOrFieldName][methodNameForField] =
                                        handler.ScriptText;
                                } else {
                                    acc.detail[methodNameOrFieldName] = handler.ScriptText;
                                }
                            } else if (method.startsWith('TablePage_')) {
                                const [methodNameOrFieldName, methodNameForField] = method
                                    .replace('TablePage_', '')
                                    .split('_');

                                if (methodNameForField) {
                                    acc.table[methodNameOrFieldName] = {};
                                    acc.table[methodNameOrFieldName][methodNameForField] =
                                        handler.ScriptText;
                                } else {
                                    acc.table[methodNameOrFieldName] = handler.ScriptText;
                                }
                                acc.table[methodNameOrFieldName] = handler.ScriptText;
                            }
                        }

                        return acc;
                    },
                    { detail: {}, table: {} } as {
                        detail: IModelHandlersObject;
                        table: IModelHandlersObject;
                    }
                );

                setModel((prev) => ({
                    ...prev,
                    handlers: flattenObject(map[modulePart])
                }));

                return map;
            }
        }

        return { detail: {}, table: {} };
    }, [metaCode, meta?.Actions]);

    const executeHandler = useCallback(
        ({
            methodName,
            fieldName,
            extraArguments
        }: {
            methodName: string;
            fieldName?: string;
            extraArguments?: any[];
        }) => {
            const module = serverHandlerMap?.[modulePart];
            const pascalMethodName = toPascalCase(methodName);
            let serverMethod = module?.[pascalMethodName] as string | undefined;

            if (fieldName && module?.[fieldName] && typeof module?.[fieldName] === 'object') {
                serverMethod = module?.[fieldName]?.[pascalMethodName] as string | undefined;
            }

            if (serverMethod) {
                // console.log('EXECUTE SERVER HANDLER', methodName, fieldName, serverHandlerMap);
                return callFunction(
                    serverMethod,
                    serverHandlerMap && extraArguments
                        ? [...extraArguments, flattenObject(serverHandlerMap[modulePart])]
                        : extraArguments
                );
            }

            const method = fieldName
                ? `${methodName}_${fieldName}_${metaCode}`
                : `${methodName}_${metaCode}`;
            const fn = settings[modulePart][method];
            if (fn) {
                // const newModel = fn(model);
                // console.log('EXECUTE CLIENT HANDLER', methodName, fieldName, serverHandlerMap);
                return callFunction(fn, extraArguments);
                // console.log(newModel);
                // if (newModel) {
                //     setModel((prev) => ({ ...prev, ...newModel }));
                // }
            }

            // возврат false, который говорит о том что обработчик не был вызван (не существует или ошибка)
            return false;
        },
        [callFunction, metaCode, modulePart, serverHandlerMap]
    );

    // useUpdateEffect(() => {
    //     if (serverHandlerMap) {
    //         setModel((prev) => ({
    //             ...prev,
    //             handlers: flattenObject(serverHandlerMap[modulePart])
    //         }));
    //     }
    // }, [serverHandlerMap]);

    return { model: modelState, setModel, executeHandler, loading: serverHandlersLoading };
};
